/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { GenderSummary } from 'app/dto';
import { StudentService } from 'app/services/student/student.service';
import { UserModel, UserProfile } from 'app/services/user/user.model';
import { UserService } from 'app/services/user/user.service';
import { EChartsOption, PieSeriesOption } from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, mergeMap, Observable } from 'rxjs';

@Component({
  selector: 'app-gender',
  standalone: true,
  imports: [CommonModule, NgxEchartsModule],
  templateUrl: './gender.component.html',
  styleUrl: './gender.component.scss'
})
export class GenderComponent {
  private readonly userService = inject(UserService);

  @Input({required: true})
  public set year(value: number) {
    this._yearSubject$.next(value);
  }

  private _yearSubject$ = new BehaviorSubject<number | undefined>(undefined);

  private _colorPalette = ['#FF0000','#002d72','#cecf25','#4594ce','#00FFFF','#ff8b0f']; 

  private _chartOptions: EChartsOption = {
    title: {
      text: 'Gender',
      left: 'left',
      top: 5,
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)',

    },
    legend: {
      orient: 'horizontal',
      bottom: 10,
    },
  };

  private _pieSeriesOption: PieSeriesOption = {
    name: 'Gender',
    type: 'pie',
    radius: ['35%', '50%'],
    center: ['50%','40%'],
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: 'center'
    },
    labelLine: {
      show: false
    },
  };

  protected viewModel$: Observable<any>  =
    combineLatest([
      this.userService.userProfile$,
      this._yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
    ]).pipe(
      debounceTime(300),
      map(([userProfile, year]) => ({userProfile, year})),
      mergeMap(data => this.getStudentGenderSummary(data.year, data.userProfile).pipe(
        map(genderSummaries => ({
          chartOptions: {
            ...this._chartOptions,
            legend: {
              ... this._chartOptions.legend,
              formatter: (name: string): string => {
                return this.getLegendLabel(name, genderSummaries);
              },
              data: genderSummaries
                // sorts the legend by count ascending. 
                .sort((a, b) => (b.count as number) - (a.count as number) )
                .map(genderSummary => genderSummary.gender)
            },
            series: this.getPieSeries(genderSummaries)
          } 
        }))
      ))
    );

    public constructor(private _studentService: StudentService) {
      //
    }

    public getStudentGenderSummary(year: number, userProfile?: UserProfile) : Observable<GenderSummary[]> {
      if (userProfile?.isStateUser) {
        return this._studentService.getStateGenderSummary(year);
      } else if ((userProfile?.allowedDistricts()?.length ?? 0) > 0) {
        return this._studentService.getDistrictGenderSummary(year, userProfile?.getScopeIds('district')!);
      } else {
        return this._studentService.getSchoolGenderSummary(year, userProfile?.getScopeIds('school')!);
      }
    }

    private getPieSeries(genderSummaries: GenderSummary[]): PieSeriesOption {
      this._pieSeriesOption.data = genderSummaries
        .map((genderSummary, index)=> ({
          value: genderSummary.count,
          name: genderSummary.gender,
          itemStyle: (index < this._colorPalette.length) ? {
            color: this._colorPalette[index]
          } : undefined
        }));

      return this._pieSeriesOption;
    }

    private getLegendLabel(name: string, genderSummaries: GenderSummary[]): string {
      const data = genderSummaries.find((item) => item.gender === name);
      const total = genderSummaries.reduce((acc, item) => acc + (item.count as number), 0);
      const percentage = ((data?.count ?? 0) / total) * 100;
      return `${name}: ${percentage.toFixed(2)}%`;
    }
}


