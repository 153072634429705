import { Injectable } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  NativeDateAdapter,
} from '@angular/material/core';

export class CustomNativeDateAdapter extends NativeDateAdapter {
  public override format(date: Date, displayFormat: any): string {
    if (displayFormat === 'YYYY-MM-DD') {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    }
    return super.format(date, displayFormat);
  }
}

// Custom date format configuration
export const MY_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD', // How the date will be parsed from input
  },
  display: {
    dateInput: 'YYYY-MM-DD', // How the date is displayed on the input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// Provider array to configure the CustomNativeDateAdapter with the custom format
export const DATE_FORMAT_PROVIDERS = [
  {
    provide: DateAdapter,
    useClass: CustomNativeDateAdapter,
    deps: [MAT_DATE_LOCALE],
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
];

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  public get formats(): MatDateFormats {
    return MY_DATE_FORMAT;
  }
}
