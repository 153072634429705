/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StaffMemberAdditionalInfo } from './staffMemberAdditionalInfo';

/**
 * Staff member
 */
export interface StaffMember { 
    addedToFile?: string;
    additionalInfo?: StaffMemberAdditionalInfo;
    assignmentCode?: string;
    /**
     * Assignment begin date
     */
    assignmentBeginDate?: string;
    /**
     * Assignment end date
     */
    assignmentEndDate?: string;
    conferralDate?: string;
    dateOfBirth?: string;
    /**
     * District number and name concatenation
     */
    readonly district?: string;
    /**
     * District ID
     */
    districtId?: string;
    /**
     * District name
     */
    districtName?: string;
    /**
     * Dostrict number
     */
    districtNumber?: number;
    educationLevel?: string;
    email?: string;
    /**
     * Employment Status
     */
    employmentStatus?: string;
    experienceInYears?: number;
    /**
     * Gender
     */
    gender?: string;
    id?: string;
    /**
     * Single Character Alpha
     */
    licensureCheck?: string;
    /**
     * Last, First, Middle Name
     */
    readonly name?: string;
    /**
     * First Name
     */
    nameFirst?: string;
    /**
     * Last Name
     */
    nameLast?: string;
    /**
     * Middle Name
     */
    nameMiddle?: string;
    /**
     * School number and name concatenation
     */
    readonly school?: string;
    /**
     * School ID
     */
    schoolId?: string;
    /**
     * School name
     */
    schoolName?: string;
    /**
     * School number
     */
    schoolNumber?: number;
    /**
     * School year
     */
    schoolYear?: number;
    /**
     * Teaching License Number
     */
    teacherLicenseNumber?: string;
    /**
     * Teacher of record
     */
    teacherOfRecord?: string;
}
