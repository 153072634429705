/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SchoolDirectoryInfo } from './schoolDirectoryInfo';
import { StaffMember } from './staffMember';
import { Student } from './student';
import { ClassSummary } from './classSummary';

/**
 * Detailed school information
 */
export interface SchoolAdditionalInformation { 
    /**
     * Calendar number
     */
    calendarNumber?: Array<string>;
    directoryInfo?: SchoolDirectoryInfo;
    /**
     * List of staff members
     */
    staffList?: Array<StaffMember>;
    /**
     * List of students
     */
    studentList?: Array<Student>;
    /**
     * List of classes
     */
    classList?: Array<ClassSummary>;
}
