import { AfterContentInit, Component, inject, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ClassFilterComponent } from '../shared/class-filter/class-filter.component';
import { TableDataModel } from 'app/models';
import { UserModel } from 'app/services/user/user.model';
import { ClassDetail, ClassQuery, ClassSummary, ColumnDefinition, Pagination, Sorting } from 'app/dto';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, filter, firstValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { ClassService } from 'app/services/class/class.service';
import { UserService } from 'app/services/user/user.service';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { LoggingService } from '@tdoe/design-system';
import { ClassTableExcelExportConfig } from '../shared/class-table/class-table.excel-export.config';
import { DataLookupTableComponent } from '../shared/data-lookup-table/data-lookup-table.component';
import { ClassTableConfig } from '../shared/class-table/class-table.config';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { downloadExcelDocument } from 'app/utilities/excel-export-utilities/excel-export-utilities';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-data-lookup-class',
  templateUrl: './data-lookup-class.component.html',
  styleUrl: './data-lookup-class.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ClassFilterComponent,
    ScopeContextFilterComponent,
    DataLookupTableComponent
  ]
})
export class DataLookupClassComponent implements AfterContentInit {

  public readonly tablePagination = signal({ pageIndex: 0, pageSize: 50} as Pagination);
  public readonly tableSort = signal({} as Sorting);

  private readonly classService = inject(ClassService);
  private readonly userService = inject(UserService);
  private readonly logger = inject(LoggingService);
  private readonly router = inject(Router);

  public ClassTableExcelExportConfig = ClassTableExcelExportConfig;
  public tableConfig = ClassTableConfig;

  public displayNameFormatter(item: ClassSummary): string {
    return StringUtilities.FormatStringForUrl(item.courseName ?? '');
  }

  public excelExport(query: ClassQuery, columns: ColumnDefinition[], format: 'csv' | 'xlsx'): Observable<File> {
    this.logger.debug('excelExport', {
      data: {
        query,
        columns,
        _: this
      }
    });
    return this.classService.getClassesExcelExportBySearchTerms(query, columns, format);
  }

  public readonly staticColumns = [
    { key: 'district', label: 'District' },
    { key: 'school', label: 'School' },
    { key: 'courseType', label: 'Course Type' },
    { key: 'courseCode', label: 'Course Code' },
    { key: 'localClassNumber', label: 'Local Class Number' },
    { key: 'sectionIdentifier', label: 'Section Identifier' },
    { key: 'classStartDate', label: 'Class Begin Date' },
    { key: 'classEndDate', label: 'Class End Date' },
    { key: 'classType', label: 'Class Type' },
    { key: 'teachingMethod', label: 'Teaching Method' },
    { key: 'testWindow', label: 'Test Window' },
  ];
    
  public state: { [k: string]: any; } | undefined;

  public constructor() {
    this.state = this.router.getCurrentNavigation()?.extras.state;

    this.userProfile = toSignal<UserModel.UserProfile>(this.userService.userProfile$.pipe(
      filter(userProfile => !!userProfile),
      tap(userProfile => { 
          this.query.update(query => ({
            ...query,
            schools: userProfile?.scopeDetails.schoolIds,
            districts: userProfile?.scopeDetails.schoolIds
          }));
          this.isLoading = false;
        })
      ));
  }

  public userProfile: Signal<UserModel.UserProfile | undefined>;

  protected isLoading = true;

  public isExporting = false;

  public query = signal<ClassQuery>({});
    
  public readonly pagedResponse = toSignal(
    combineLatest([
      toObservable(this.query),
      toObservable(this.tablePagination),
      toObservable(this.tableSort)
    ]).pipe(
      debounceTime(300),
      map(([query, pagination, sorting]) => ({ query, pagination, sorting })),
      switchMap(data => {
        const pro = this.userService.userProfile$.getValue();
        
        if ((pro?.scopeDetails.districtIds?.length ?? 0) > 0) {
          data.query.districtIds = [...new Set([
            ...pro?.scopeDetails.districtIds!,
            ...data.query.districtIds ?? []
          ])];
        }
        
        if ((pro?.scopeDetails.schoolIds?.length ?? 0) > 0) {
          data.query.schoolIds = [...new Set([
            ...pro?.scopeDetails.schoolIds!,
            ...data.query.schoolIds ?? []
          ])];
        }
        
        if ((pro?.scopeDetails.years?.length ?? 0) > 0) {
          data.query.years = [...new Set([
            ...pro?.scopeDetails.years!,
            ...data.query.years ?? []
          ])];
        }
        
        return this.classService.getClassesBySearchTerms(data.query, data.pagination, data.sorting).pipe(
          map((pagedResponse: TableDataModel.PagedResponse<ClassSummary>) => {
            if (pagedResponse.data) {
              pagedResponse.data = pagedResponse.data.map(row => ({
                ...row,
                school: `${row.schoolNumber} - ${row.schoolName}`
              }));
            }
            return pagedResponse;
          }),
          tap(_ => {
            this.logger.debug('DataLookupClassComponent -> getClassesBySearchTerms', { data: { _ } });
          })
        );
      })
    )
  );

  public onPageChanged(pagination: Pagination): void {
    this.tablePagination.set(pagination);
  }

  public async onExportClicked(e: { query: ClassSummary; columns: ColumnDefinition[], format: 'csv' | 'xlsx' }): Promise<void> {
    this.logger.debug('onExportClicked', {
      data: {
        e
      }
    });
    this.isExporting = true;

    let file = new File([], '', {});
    
    try {
      file = await firstValueFrom(this.classService.getClassesExcelExportBySearchTerms(e.query, e.columns, e.format));
    } catch (err) {
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 413:
          this.logger.error('Export is limited to 500,000 records. Please apply filters to reduce the number of results and try again.', {
            data: {
              err
            },
            notify: true,
            title: 'Too Much Data'
          });
          break;
        }
      } else {
        this.logger.error('student export error', { data: {
          err
        }});
      }
    }

    if (file.size > 0) {
      downloadExcelDocument(file);
    }

    this.isExporting = false;
  }

  public onResetQuery(query: ClassQuery): void {
    this.query.set(query);
  }

  public onSortClicked(sorting: Sorting): void {
    this.tableSort.set(sorting);
  }

  public ngAfterContentInit(): void {
    this.isLoading = !this.userProfile();
        
    if (this.state !== null && this.state!['query']) {
      this.query.set(this.state!['query'])
    }

  }

  public onTablePageChanged(pagination: Pagination): void {
    this.tablePagination.set(pagination);
  }

  public onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    this.resetPagination();
    this.logger.debug('DataLookupClassComponent -> onContextFilterSearchClicked', { data: { selectedScopeContextFilterData } });
    this.query.update(query => ({
      ...query,
      ...selectedScopeContextFilterData
    }));
  }

  public onQuickSearch(classQuery: ClassQuery): void {
    this.resetPagination();
    this.query.set(classQuery);
  }

  private resetPagination(): void {
    this.logger.debug('DataLookupClassComponent -> resetPagination');
    this.tablePagination.update(p => ({
      pageIndex: 0,
      pageSize: 50
    }));
  }
}
