import { Category } from 'app/services/additional-info/additional-info.model';

export interface StaffCategoryConfig extends Category {
  alwaysShow?: boolean;
  isArray?: boolean;
  dataPath?: string;
}

export const StaffViewConfig = {
  categories: <StaffCategoryConfig[]>[
    {
      name: 'Staff Profile',
      expanded: true,
      alwaysShow: true,
      isArray: false,
      fields: [
        {
          name: 'Last Name',
          key: 'nameLast',
          selected: true,
          isStatic: true,
          exportColumn: {
            jsonPath: '$.NameLast'
          }
        },
        {
          name: 'First Name',
          key: 'nameFirst',
          selected: true,
          isStatic: true,
          exportColumn: {
            jsonPath: '$.NameFirst'
          }
        },
        {
          name: 'Middle Name',
          key: 'nameMiddle',
          selected: true,
          isStatic: true,
          exportColumn: {
            jsonPath: '$.NameMiddle'
          }
        },
        {
          name: 'Date of Birth',
          key: 'dateOfBirth',
          selected: true,
          isStatic: true,
          exportColumn: {
            jsonPath: '$.DateOfBirth'
          }
        },
        {
          name: 'Gender',
          key: 'gender',
          selected: true,
          isStatic: true,
          exportColumn: {
            jsonPath: '$.Gender'
          }
        },
        {
          name: 'District',
          key: 'district',
          selected: true,
          isStatic: true,
          exportColumn: {
            jsonPath: '$.District'
          }
        },
        {
          name: 'School',
          key: 'school',
          selected: true,
          isStatic: true,
          exportColumn: {
            jsonPath: '$.School'
          }
        },
        {
          name: 'Email',
          key: 'email',
          selected: true,
          isStatic: true,
          exportColumn: {
            jsonPath: '$.email'
          }
        },
      ],
    },
    {
      name: 'Staff Current Assignments',
      expanded: false,
      isArray: true,
      dataPath: 'additionalInfo.currentAssignments',
      valuePath: '$.additionalInfo.currentAssignments',
      fields: [
        {
          name: 'Assignment Code',
          key: 'assignmentCode',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentCode'
          }
        },
        {
          name: 'Current Assignment Begin Date',
          key: 'assignmentBeginDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentBeginDate'
          }
        },
        {
          name: 'Current Assignment End Date',
          key: 'assignmentEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentEndDate'
          }
        },
      ],
    },
    {
      name: 'Staff Class Assignments',
      expanded: false,
      isArray: true,
      dataPath: 'additionalInfo.staffAssignments',
      valuePath: '$.additionalInfo.StaffAssignments',
      fields: [
        {
          name: 'Staff Class Assignment Begin Date',
          key: 'assignmentStart',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentStart'
          }
        },
        {
          name: 'Staff Class Assignment End Date',
          key: 'assignmentEnd',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentEnd'
          }
        },
        {
          name: 'Class Begin Date',
          key: 'classStart',
          selected: false,
          exportColumn: {
            jsonPath: '$.classStart'
          }
        },
        {
          name: 'Class End End',
          key: 'classEnd',
          selected: false,
          exportColumn: {
            jsonPath: '$.classEnd'
          }
        },
        {
          name: 'Course Code',
          key: 'courseCode',
          selected: false,
          exportColumn: {
            jsonPath: '$.courseCode'
          }
        },
        {
          name: 'Local Class Number',
          key: 'localClassNumber',
          selected: false,
          exportColumn: {
            jsonPath: '$.localClassNumber'
          }
        },
        {
          name: 'Teacher of Record',
          key: 'teacherOfRecord',
          selected: false,
          exportColumn: {
            jsonPath: '$.teacherOfRecord'
          }
        },
        {
          name: 'Period Duration',
          key: 'periodDuration',
          selected: false,
          exportColumn: {
            jsonPath: '$.periodDuration'
          }
        },
      ],
    },
    {
      name: 'Staff Licensure & Endorsement Details',
      expanded: false,
      isArray: false,
      // Omit dataPath if fields are mixed paths
      fields: [
        {
          name: 'License Number',
          key: 'teacherLicenseNumber',
          selected: false,
          exportColumn: {
            jsonPath: '$.TeacherLicenseNumber'
          }
        },
        {
          name: 'Experience',
          key: 'experienceInYears',
          selected: false,
          exportColumn: {
            jsonPath: '$.experienceInYears'
          }
        },
        {
          name: 'Education Level',
          key: 'educationLevel',
          selected: false,
          exportColumn: {
            jsonPath: '$.educationLevel'
          }
        },
        {
          name: 'Conferral Date',
          key: 'conferralDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.conferralDate'
          }
        },
        {
          name: 'Date Added to File',
          key: 'addedToFile',
          selected: false,
          exportColumn: {
            jsonPath: '$.addedToFile'
          }
        },
        {
          name: 'Date Issued',
          key: 'additionalInfo.staffMemberLicensureDetails.dateIssued',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.StaffMemberLicensureDetails.dateIssued'
          }
        },
        {
          name: 'Type',
          key: 'additionalInfo.staffMemberLicensureDetails.type',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.StaffMemberLicensureDetails.type'
          }
        },
        {
          name: 'Expiration Date',
          key: 'additionalInfo.staffMemberLicensureDetails.expirationDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.StaffMemberLicensureDetails.expirationDate'
          }
        },
        {
          name: 'Renewed Date',
          key: 'additionalInfo.staffMemberLicensureDetails.renewedDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.StaffMemberLicensureDetails.renewedDate'
          }
        },
        {
          name: 'Number of Renewals',
          key: 'additionalInfo.staffMemberLicensureDetails.numberOfRenewals',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.StaffMemberLicensureDetails.numberOfRenewals'
          }
        },
      ],
    },
  ],
};