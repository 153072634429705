/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DataType = 'Text' | 'Number' | 'Date' | 'Boolean' | 'Currency' | 'DayOfWeek';

export const DataType = {
  Text: 'Text' as DataType,
  Number: 'Number' as DataType,
  Date: 'Date' as DataType,
  Boolean: 'Boolean' as DataType,
  Currency: 'Currency' as DataType,
  DayOfWeek: 'DayOfWeek' as DataType,
};