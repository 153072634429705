import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import {
  ColumnDefinition,
  Pagination,
  Sorting,
  StaffMember,
  StaffSearchTerms,
  StaffSearchTermsExcelExportRequest
} from '../../dto';
import { PagedResponse } from 'app/models';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import dayjs from 'dayjs';
import { HttpParamsUtilities } from 'app/utilities/http-params-utilities/http-params-utilities';
import { LoggingService } from '@tdoe/design-system';
import { Category } from '../additional-info/additional-info.model';
import { CategoryConfig } from 'app/dto/categoryConfig';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  private static _staffUrl = `${environment.apiBaseUrl}Staff`;
  private static _staffMemberExportUrl = `${environment.apiBaseUrl}Staff/export/excel`;
  private static _staffMemberPDFExportUrl = `${environment.apiBaseUrl}Staff/export/pdf`;
  
  private logger = inject(LoggingService);

  public constructor(private http: HttpClient) {}

  public getStaffBySearchTerms(
    staffSearchTerms: StaffSearchTerms,
    pagination: Pagination,
    sorting: Sorting
  ): Observable<PagedResponse<StaffMember>> {
    const flattened = ObjectUtilities.flattenObject(staffSearchTerms);
    let params = new HttpParams();
    for (const key in flattened) {
      if (Object.prototype.hasOwnProperty.call(flattened, key)) {
        const value = flattened[key];
        if (value) {
          if (Array.isArray(value)) {
            value.forEach(val => {
              params = params.append(key, val.toString());
            });
          } else {
            params = params.set(key, value.toString());
          }
        }
      }
    }
    params = params.set('pageIndex', pagination.pageIndex!.toString());
    params = params.set('pageSize', pagination.pageSize!.toString());
    if (sorting.sortColumn) {
      params = params.set('sortColumn', sorting.sortColumn);
    }
    if (sorting.sortDirection) {
      params = params.set('sortDirection', sorting.sortDirection);
    }
    return this.http.get<PagedResponse<StaffMember>>(
      `${StaffService._staffUrl}/StaffMembers`,
      { params }
    );
  }

  public getStaffMembersExcelExportBySearchTerms(
    query: StaffSearchTerms,
    columnDefinitions: ColumnDefinition[], format: 'csv' | 'xlsx'
  ): Observable<File> {
    if (!query.years) {
      query.years = [dayjs().year()];
    }

    query.years = HttpParamsUtilities.ensureNumeric(query.years, true) as number[];
    const requestBody: StaffSearchTermsExcelExportRequest = {
      searchTerms: query,
      columnDefinitions,
      format
    };
    
    return this.http
      .post<Blob>(StaffService._staffMemberExportUrl, requestBody, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      })
      .pipe(
        map(response =>
          new File(
            [response.body!],
            `staffMembers-export-${new Date().toISOString().slice(0, 10)}.${format}`
          )
        )
      );
  }

  public getStaffMember(staffMemberId: string): Observable<StaffMember> {
    return this.http.get<StaffMember>(`${StaffService._staffUrl}/${staffMemberId}`);
  }
  
  public exportPdf(id: string, additionalInfo?: Category[]): Observable<File> {

    const url = `${environment.apiBaseUrl}Staff/export/pdf/${id}`;

    const dataCategories = additionalInfo?.map(_ => ({
      name: _.name,
      jsonPath: _.valuePath,
      fields: _.fields.map(field => ({
        displayName: field.name,
        jsonPath: field.exportColumn?.jsonPath
      } as ColumnDefinition))
    } as CategoryConfig));

    return this.http.post<Blob>(url, dataCategories,
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        observe: 'response'
      }
    ).pipe(
      map(response => new File([response.body!], `staff-export-${dayjs().format('YYYY-MM-DD')}.pdf`)) // When we udpate this is the winner ${dayjs().format('YYYYMMDDHHmmss')}.pdf`))
    );
  }
}

