import { Pipe, PipeTransform } from '@angular/core';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';

@Pipe({
  name: 'nestedProperty',
  standalone: true
})
export class NestedPropertyPipe implements PipeTransform {
  public transform(value: unknown, path: string | undefined): unknown {
    if (!value || !path) {
      return '';
    }
    const realPath = path.replace(/_/g, '.');
    const result = ObjectUtilities.getNestedProperty(value, realPath);
    if (result === undefined) {
      console.warn(`NestedPropertyPipe warning: Could not find property "${realPath}" on the given object:`, value);
    }
    return result;
  }
}