<div class="container header-title">
    <div class="container">
        <h1>Class Details</h1>
    </div>
</div>
<div class="container" *ngIf="viewModel$ | async as viewData">
    <div class="container" *ngIf="viewData.classDetail as classDetail">
        <div class="header">
            <div class="back-link">
                <a [routerLink]="['/data-lookup', 'class']" [state]="state"><mat-icon>arrow_back</mat-icon> Back to Class List</a>
            </div>
            <div class="buttons">
                <app-additional-info 
                    [additionalInfoFields]="additionalInfoFields" 
                    [contextKey]="AdditionalInfoContextKey"
                    (fieldSelected)="onFieldSelected($event)" />
                <ng-container *ngIf="!isExporting; else loadingExport">
                  <button tdoe-button size="large" (click)="export(viewData.classDetail.id!, viewData.selectedCategories)">Export</button>
                </ng-container>
                <ng-template #loadingExport>
                  <div class="progress-bar">
                    <div class="progress-text">Exporting...</div>
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </div>
                </ng-template>
            </div>
        </div>
        <div mat-accordion class="mat-accordion">
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Class Section Profile
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="data-list">
                    <div class="field">
                        <span class="name">Primary District</span>
                        <span class="value">{{classDetail.primaryDistrict}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Primary School</span>
                        <span class="value">{{classDetail.primarySchool}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Course Type</span>
                        <span class="value">{{classDetail.courseType}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Course Code</span>
                        <span class="value">{{classDetail.courseCode}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Local Class Number</span>
                        <span class="value">{{classDetail.localClassNumber}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Section Identifier</span>
                        <span class="value">{{classDetail.sectionIdentifier}}</span>
                    </div>
                    <div class="field course-desc">
                        <span class="name">Course Description</span>
                        <span class="value">{{classDetail.courseDescription}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div mat-accordion class="mat-accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Class Section Detail
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="data-list">
                    <div class="field">
                        <span class="name">Class Begin Date</span>
                        <span class="value">{{classDetail.classStartDate | date}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Class End Date</span>
                        <span class="value">{{classDetail.classEndDate | date}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Class Period Name</span>
                        <span class="value">{{classDetail.classPeriod?.periodName}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Class Period Duration</span>
                        <span class="value">{{classDetail.classPeriod?.periodDuration}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Class Period Meeting Days</span>
                        <mat-button-toggle-group class="value" [hideMultipleSelectionIndicator]="true" multiple disabled>
                            <mat-button-toggle [checked]="classDetail.classPeriod?.meetingDays?.includes(daysOfWeek.Sunday)">S</mat-button-toggle>
                            <mat-button-toggle [checked]="classDetail.classPeriod?.meetingDays?.includes(daysOfWeek.Monday)">M</mat-button-toggle>
                            <mat-button-toggle [checked]="classDetail.classPeriod?.meetingDays?.includes(daysOfWeek.Tuesday)">T</mat-button-toggle>
                            <mat-button-toggle [checked]="classDetail.classPeriod?.meetingDays?.includes(daysOfWeek.Wednesday)">W</mat-button-toggle>
                            <mat-button-toggle [checked]="classDetail.classPeriod?.meetingDays?.includes(daysOfWeek.Thursday)">T</mat-button-toggle>
                            <mat-button-toggle [checked]="classDetail.classPeriod?.meetingDays?.includes(daysOfWeek.Friday)">F</mat-button-toggle>
                            <mat-button-toggle [checked]="classDetail.classPeriod?.meetingDays?.includes(daysOfWeek.Saturday)">S</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="field course-desc">
                        <span class="name">Class type</span>
                        <span class="value">{{classDetail.classType}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div mat-accordion class="mat-accordion">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Course Attributes
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="data-list">
                    <div class="field">
                        <span class="name">Honor Flag</span>
                        <span class="value">{{classDetail.honorFlag}}</span>
                    </div>
                    <div class="field">
                        <span class="name">State Dual Credit</span>
                        <span class="value">{{classDetail.stateDualCredit ?? false | boolDisplay: 'Yes' : 'No' }}</span>
                    </div>
                    <div class="field">
                        <span class="name">Local Dual Credit</span>
                        <span class="value">{{classDetail.localDualCredit ?? false | boolDisplay: 'Yes' : 'No'}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Dual Enrollment</span>
                        <span class="value">{{classDetail.dualEnrollment ?? false | boolDisplay: 'Yes' : 'No'}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Post Secondary Institution</span>
                        <span class="value">{{classDetail.postSecondaryInstitution}}</span>
                    </div>
                    <div class="field">
                        <span class="name">CTE Program Service</span>
                        <span class="value">{{classDetail.cteProgramService}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Classification of Course</span>
                        <span class="value">{{classDetail.classification}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Teaching Method</span>
                        <span class="value">{{classDetail.teachingMethod}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Test Window</span>
                        <span class="value">{{classDetail.testWindow}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div mat-accordion class="mat-accordion" *ngIf="viewData.staffCategory as staffCategory">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Staff
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="sub-section">
                    <div class="scrollinator">
                        <mat-table [dataSource]="classDetail.additionalInfo?.staff ?? []">
                        
                            @for (column of viewData.staffCategory.fields;track column) {
                                <ng-container [matColumnDef]="strip(column.key)">
                                    <mat-header-cell *matHeaderCellDef>{{ column.name }}</mat-header-cell>
                                    <mat-cell *matCellDef="let staffMember">{{resolver(staffMember, strip(column.key))}}</mat-cell>
                                </ng-container>
                            }
    
                            <mat-header-row *matHeaderRowDef="viewData.staffColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: viewData.staffColumns"></mat-row>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No staff members for '{{ classDetail.courseCode }}'</td>
                            </tr>
                        </mat-table>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div mat-accordion class="mat-accordion" *ngIf="viewData.studentCategory as studentCategory">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Student
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="sub-section">
                    <div class="scrollinator">
                        <mat-table [dataSource]="classDetail.additionalInfo?.students ?? []">
                        
                            @for (column of studentCategory.fields;track column) {
                                <ng-container [matColumnDef]="strip(column.key)">
                                    <mat-header-cell *matHeaderCellDef>{{ column.name }}</mat-header-cell>
                                    <mat-cell *matCellDef="let student">{{resolver(student, strip(column.key))}}</mat-cell>
                                </ng-container>
                            }
    
                            <mat-header-row *matHeaderRowDef="viewData.studentColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: viewData.studentColumns"></mat-row>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No students for '{{ classDetail.courseCode }}'</td>
                            </tr>
                        </mat-table>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>