@if (!isLoading) {

    <div class="container page-title">
        <div class="container">
            <h1>School List</h1>
        </div>
    </div>

    <div class="container scope-context">
        <div class="container">
            <app-scope-context-filter (searchClick)="onContextFilterSearchClicked($event)" />
        </div>
    </div>

    <!-- <div class="container school-filter">
        <div class="container">
            <app-school-filter [schoolQuery]="query()" (searchClicked)="onQuickSearch($event)"/>
        </div>
    </div> -->

    <div class="container table">
        <div class="container">
            <app-data-lookup-table additionalInfoContextKey="SchoolTableComponent"
                clickRoute="/data-lookup/school"
                identifierPath="schoolId"
                tableHeading="School Section List"
                [chipIgnorables]="['schoolIds','districtIds','year','years','pageScope','schools','districts']"
                [pagedData]="pagedResponse()!"
                [staticColumns]="staticColumns"
                [tableConfig]="tableConfig"
                [displayNameFormatter]="displayNameFormatter"
                [query]="query()"
                [isExporting]="isExporting"
                (exportClicked)="onExportClicked($event)"
                (pageChanged)="onPageChanged($event)"
                (resetQuery)="onResetQuery($event)"
                (sortClicked)="onSortClicked($event)" />
        </div>
    </div>
}