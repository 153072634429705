import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { MembershipByGradeSummary, MembershipSummary } from 'app/dto';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {
  private readonly controllerUri = `${environment.apiBaseUrl}Membership`;

  public constructor(private http: HttpClient) { }

  public getMembershipSummary(years: number, districtIds?: string[], schoolIds?: string[]): Observable<MembershipSummary[]> {
    return this.http.get<MembershipSummary[]>(`${this.controllerUri}/Summary`, { params: this.getParameters(districtIds, schoolIds, years) });
  }

  public getMembershipByGradeSummary(districtIds?: string[], schoolIds?: string[], years?: number[]): Observable<MembershipByGradeSummary[]> {
    return this.http.get<MembershipByGradeSummary[]>(`${this.controllerUri}/SummaryByGrade`, { params: this.getParameters(districtIds, schoolIds, years) });
  }

  private getParameters(districtIds?: string[], schoolIds?: string[], years?: number[] | number): HttpParams {
    let params = new HttpParams();
    
    schoolIds?.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    districtIds?.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    if (Array.isArray(years)) {
      years?.forEach(year => {
        params = params.append('years', year);
      });
    } else {
      params = params.append('years', years as number);
    }

    return params;
  }
}
