/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StudentEnrollment } from './studentEnrollment';
import { StudentEnrollmentClassification } from './studentEnrollmentClassification';
import { StudentVocational } from './studentVocational';
import { StudentAdditionalInformation } from './studentAdditionalInformation';
import { StudentDemographics } from './studentDemographics';
import { StudentIneligibilityFundingStatus } from './studentIneligibilityFundingStatus';
import { StudentEnrollmentEndOfService } from './studentEnrollmentEndOfService';

/**
 * erg45gtrg
 */
export interface Student { 
    enrollmentStartDate?: string;
    tos?: string;
    withdrawalEndDate?: string;
    withdrawalCode?: string;
    assignmentEndDate?: string;
    assignmentStartDate?: string;
    /**
     * Class end date
     */
    classEndDate?: string;
    classStartDate?: string;
    classType?: string;
    courseCode?: string;
    localClassNumber?: string;
    alphaGrade?: string;
    creditsEarned?: number;
    numericGrade?: number;
    optionLevel?: string;
    optionNumber?: number;
    specEdBeginDate?: string;
    specEdEndDate?: string;
    specEdDisabilityBeginDate?: string;
    specEdDisabilityEndDate?: string;
    specEdDisabilityLevel?: string;
    specEdDisabilityType?: string;
    standardReportPeriod?: string;
    standardStudentAda?: string;
    standardStudentAdm?: string;
    disciplinaryEnrollmentPeriodStart?: string;
    disciplinaryEnrollmentPeriodEnd?: string;
    disciplinaryPeriodBegin?: string;
    disciplinaryPeriodEnd?: string;
    disciplinaryType?: string;
    disciplinaryReason?: string;
    zeroTolerance?: boolean;
    additionalInfo?: StudentAdditionalInformation;
    assignmentEnrollmentPeriodEndDate?: string;
    assignmentEnrollmentPeriodStartDate?: string;
    classification?: StudentEnrollmentClassification;
    /**
     * Single Character Alpha. probably enrollmentCode.
     */
    code?: string;
    dateOfBirth?: string;
    demographics?: StudentDemographics;
    displayName?: string;
    /**
     * District number and name concatenation
     */
    readonly district?: string;
    /**
     * District Identifier (LEA)
     */
    districtId?: string;
    /**
     * District name
     */
    districtName?: string;
    /**
     * District number
     */
    districtNumber?: number;
    endOfService?: StudentEnrollmentEndOfService;
    enrollment?: StudentEnrollment;
    enrollmentDuration?: number;
    readonly enrollmentEndDate?: string;
    ethnicity?: string;
    gender?: string;
    /**
     * [K-12]
     */
    grade?: string;
    id?: string;
    ineligibilityFundingStatus?: StudentIneligibilityFundingStatus;
    label?: string;
    language?: string;
    /**
     * Last, First, Middle Gender
     */
    nameFirst?: string;
    /**
     * Last, First, Middle Gender
     */
    nameLast?: string;
    /**
     * Last, First, Middle Gender
     */
    nameMiddle?: string;
    /**
     * School number and name contcatenation
     */
    readonly school?: string;
    /**
     * School name
     */
    schoolName?: string;
    /**
     * School Number
     */
    schoolNumber?: number;
    /**
     * Would like string identifier k_school
     */
    schoolId?: string;
    /**
     * School year
     */
    schoolYear?: number;
    /**
     * ServiceDistrict
     */
    serviceDistrict?: string;
    /**
     * ServiceSchool
     */
    serviceSchool?: string;
    /**
     * Single Character Alpha
     */
    serviceType?: string;
    sped?: string;
    /**
     * U.S. Social Security Number
     */
    ssid?: string;
    standardDayMinutes?: number;
    vocationalInfo?: StudentVocational;
    vocOutsideIP?: string;
}
