import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, input, Output } from '@angular/core';
import { ScopeContextFilterModel } from './scope-context-filter.model';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, firstValueFrom, map, tap } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UserService } from 'app/services/user/user.service';
import { UserProfile } from 'app/services/user/user.model';
import { LoggingService, TdoeButtonComponent, TdoeSelectItem } from '@tdoe/design-system';
import { CacheService } from 'app/services/cache-service/cache.service';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { SchoolService } from 'app/services/school/school.service';
import { SessionsService } from 'app/services/sessions/sessions.service';
import dayjs from 'dayjs';
import { SchoolQuery } from 'app/dto';
import { compare } from 'app/utilities/object-utilities/object-utilities';

interface viewModel {
    formlyForm: FormGroup,
    formlyModel: any,
    formlyFields: FormlyFieldConfig[],
    multipleYear: boolean,
    selectedYears?: number[],
    selectedDistrictIds?: string[],
    selectedSchoolIds?: string[],
    userProfile?: UserProfile
}

@Component({
  selector: 'app-scope-context-filter',
  standalone: true,
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    FormlyModule,
    ReactiveFormsModule,
    TdoeButtonComponent
  ],
  templateUrl: './scope-context-filter.component.html',
  styleUrl: './scope-context-filter.component.scss'
})
export class ScopeContextFilterComponent {
  private readonly cacheService = inject(CacheService);
  private readonly logger = inject(LoggingService);
  private readonly schoolService = inject(SchoolService);
  private readonly sessionsService = inject(SessionsService);
  private readonly userService = inject(UserService);

  public readonly multipleYear = input<boolean>(false);

  @Output()
  public selectedDistrictIdsChange = new EventEmitter<string[] | undefined>();

  @Output()
  public selectedSchoolIdsChange = new EventEmitter<string[] | undefined>();

  @Output()
  public searchClick = new EventEmitter<ScopeContextFilterModel.SelectedData>();

  public constructor() {
    this.loadYears();
    this.loadDistricts();
  }

  private districtOptions$ = new BehaviorSubject<TdoeSelectItem[]>([]);
  private schoolOptions$ = new BehaviorSubject<TdoeSelectItem[]>([]);
  private yearOptions$ = new BehaviorSubject<TdoeSelectItem[]>([]);

  protected searchTerms?: ScopeContextFilterModel.SelectedData;
  public loadingSchools = false;

  private selectedYear$ = new BehaviorSubject<number[]>([dayjs().year()]); 
  private selectedDistrictId$ = new BehaviorSubject<string[]  | undefined>(undefined);

  protected viewModel$ = combineLatest([
    this.selectedYear$,
    this.selectedDistrictId$,
    this.userService.userProfile$,
  ]).pipe(
    debounceTime(300),
    map(([
      year,
      districtId,
      userProfile
    ]) => ({
      year,
      districtId,
      userProfile
    })),
    map(data => ({
      selectedYear: data.userProfile?.scopeDetails.years ?? data.year,
      userProfile: data.userProfile,
      selectedDistrictIds: data.userProfile?.scopeDetails.districtIds,
      selectedSchoolIds: data.userProfile?.scopeDetails.schoolIds,
      multipleYear: this.multipleYear(),
      formlyForm: new FormGroup({}),
      formlyModel: {
        districts: data.districtId,
        schools: this.schoolOptions$.getValue()?.filter(_ => _.selected === true).map(_ => _.value)
      } as any,
      formlyFields: [
        {
          key: 'year',
          type: 'tdoe-select',
          className: 'tdoe-grow-0',
          props: {
            label: 'Select Year',
            options: this.yearOptions$.asObservable(),
            appearance: 'outline',
            multiple: this.multipleYear()
          },
          defaultValue: (data.userProfile?.scopeDetails.years ?? data.year).toString()
        },
        {
          key: 'districts',
          type: 'tdoe-select',
          className: 'tdoe-grow-1',
          props: {
            label: 'Select Districts',
            options: this.districtOptions$.asObservable(),
            appearance: 'outline',
            multiple: true
          },
          defaultValue: data.districtId,
          expressions: {
            'props.disabled': (): boolean => {
              return this.districtOptions$.getValue().length < 2;
            }
          }
        },
        {
          key: 'schools',
          type: 'tdoe-select',
          className: 'tdoe-grow-1',
          props: {
            label: 'Select Schools',
            options: this.schoolOptions$.asObservable(),
            appearance: 'outline',
            multiple: true
          },
          hooks: {
            onInit: (field: FormlyFieldConfig): void => {
              this.logger.debug('[ScopeContextFilterComponent] -> viewModel$ -> [schools] -> onInit', {
                data: {
                  field
                }
              });
              const districtControl = (field as any).parent.get('districts').formControl;
              districtControl.valueChanges.pipe(
                tap((_: string[]) => {
                  if (!_ || _.length === 0) {
                    field.model.schools = [];
                    this.cacheService.userSelectedSchools = undefined;
                    this.userService.userProfile$.getValue()!.syncCache();
                    this.loadSchools();
                  } else if (!compare(_, this.selectedDistrictId$.getValue())) {
                    this.loadSchools(_);
                  }
                })
              ).subscribe();
            }
          },
          expressions: {
            'props.disabled': (): boolean => {
              return this.schoolOptions$.getValue().length < 2;
            },
            'props.showLoader': (): boolean => {
              return this.loadingSchools;
            }
          }
        }
      ]
    } as viewModel)),
    tap((_) => {
      if (this.schoolOptions$.getValue().length < 1) {
        if ((_.userProfile?.scopeDetails.districtIds?.length ?? 0) > 0)
        this.loadSchools(_.userProfile!.scopeDetails.districtIds);
      }
    })
  );

  private async loadDistricts(): Promise<void> {
      const districts = this.cacheService.devScopeDistricts;

      let districtOptions: TdoeSelectItem[] | undefined = undefined;
      const allowedDistrictIds = this.userService.userProfile$.getValue()?.allowedDistricts();
      const isStateUser = this.userService.userProfile$.getValue()?.isStateUser ?? false;
      const selectedDistricts = this.userService.userProfile$.getValue()?.getScopeIds('district');

      if (isStateUser) {
        districtOptions = districts.map(district => ({
          text: `${district.districtNumber} - ${district.name}`,
          value: district.districtId,
          selected: selectedDistricts?.includes(district.districtId!)
        } as TdoeSelectItem));
        this.selectedDistrictId$.next(selectedDistricts);
      } else if (allowedDistrictIds && allowedDistrictIds.length > 1) {
        districtOptions = districts.filter(_ => allowedDistrictIds.includes(_.districtId!)).map(district => ({
          text: `${district.districtNumber} - ${district.name}`,
          value: district.districtId,
        } as TdoeSelectItem));
        this.selectedDistrictId$.next(selectedDistricts);
      } else if (allowedDistrictIds && allowedDistrictIds.length === 1) {
        districtOptions = districts.filter(_ => allowedDistrictIds.includes(_.districtId!)).map(district => ({
          text: `${district.districtNumber} - ${district.name}`,
          value: district.districtId
        } as TdoeSelectItem));
        this.selectedDistrictId$.next(allowedDistrictIds);
      }

      this.logger.debug('ScopeContextFilterComponent -> loadDistricts', {
        data: {
          districts,
          districtOptions,
          isStateUser,
          selectedDistricts
        }
      });
      
      this.districtOptions$.next(districtOptions ?? []);
  }

  private async loadSchools(districtIds?: string[]): Promise<void> {
    this.loadingSchools = true;

    const profile = this.userService.userProfile$.getValue();
    const cachedSchools = this.cacheService.userSelectedSchools;

    let query: SchoolQuery = {
      years: profile?.scopeDetails.years,
      districtIds: districtIds ?? profile?.scopeDetails.districtIds
    };

    const schools = await firstValueFrom(this.schoolService.searchSchools(query, {
      pageIndex: 0,
      pageSize: 50000
    }, {
      sortColumn: 'schoolNumber',
      sortDirection: 'asc'
    }));

    const schoolOptions = schools?.data?.map(school => ({
      text: `${school.schoolNumber} - ${school.name}`,
      value: school.schoolId,
      selected: (schools.data && schools.data.length === 1) || cachedSchools?.includes(school.schoolId!)
    } as TdoeSelectItem));
    this.loadingSchools = false;
    this.schoolOptions$.next(schoolOptions ?? []);
  }

  private async loadYears(): Promise<void> {
    const years = await firstValueFrom(this.sessionsService.getYears());
    const selectedYear: number = this.userService.userProfile$.getValue()?.getScopeYears() as number ?? dayjs().year()
    const yearOptions = years.map(year => ({
      text: year.toString(),
      value: year.toString(),
      selected: year === selectedYear
    } as TdoeSelectItem));
    this.logger.debug('ScopeContextFilterComponent -> loadYears', {
      data: {
        years,
        selectedYear,
        yearOptions
      }
    });
    this.selectedYear$.next([selectedYear]);
    this.yearOptions$.next(yearOptions);
  }
  
  protected onSearchClick(vm: viewModel): void {
    this.cacheService.userSelectedDistricts = vm.formlyModel.districts;
    this.cacheService.userSelectedSchools = vm.formlyModel.schools;
    this.cacheService.userSelectedYears = vm.formlyModel.year;

    this.selectedDistrictId$.next(vm.formlyModel.districts);
    this.selectedYear$.next(vm.formlyModel.year);

    this.searchClick.emit({ 
      years: vm.formlyModel.year,
      districtIds: vm.formlyModel.districts, 
      schoolIds: vm.formlyModel.schools
    });
  }

}