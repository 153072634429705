/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ScopeContextFilterModel, SelectedData } from '../shared/scope-context-filter/scope-context-filter.model';
import { ErrorCategoryCardsComponent } from './error-category-cards/error-category-cards.component';
import { CommonModule } from '@angular/common';
import { filter, map} from 'rxjs';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { AverageDailyMembershipComponent } from './average-daily-membership/average-daily-membership.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { ScopeContextFilterComponent } from '../shared/scope-context-filter/scope-context-filter.component';
import { UserService } from 'app/services/user/user.service';
import { LoggingService } from '@tdoe/design-system';
import { ensureNumeric } from 'app/utilities/http-params-utilities/http-params-utilities';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbComponent,
    ScopeContextFilterComponent,
    ErrorCategoryCardsComponent,
    AverageDailyMembershipComponent,
    DemographicsComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  protected viewModel$ = this.userService.userProfile$.pipe(
    filter(userProfile => !!userProfile),
    map(data => ({
      selectedYears: ensureNumeric(data.scopeDetails.years ?? []) as number[],
      selectedSchoolIds: data.scopeDetails.schoolIds,
      selectedDistrictIds: data.scopeDetails.districtIds
    }))
  );

  public constructor (private userService: UserService,
                      private logger: LoggingService
  ) { }

  public onSearchClicked(searchTerms: SelectedData): void {
    this.logger.debug('DashboardComponent -> onSearchClicked', { data: { searchTerms }});
    this.userService.userProfile$.getValue()?.syncCache();
    this.userService.rescopeUser(searchTerms);
  }

}
