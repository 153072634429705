/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoggingService, TdoeButtonDirective, TdoeCardComponent, TdoeKpiCardComponent } from '@tdoe/design-system';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { School, SchoolReportingPeriod } from 'app/dto';
import { SchoolService } from 'app/services/school/school.service';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, filter, firstValueFrom, map, mergeMap, Observable, tap } from 'rxjs';
import { AdditionalInfoModel, Category } from 'app/services/additional-info/additional-info.model';
import { CalendarDaysComponent } from './calendar-days/calendar-days.component';
import { additionalInfoFields } from './school-view.additional-info-config';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { Dictionary } from '@fullcalendar/core/internal';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { downloadExcelDocument } from 'app/utilities/excel-export-utilities/excel-export-utilities';

@Component({
  selector: 'app-school-view',
  standalone: true,
  imports: [
    MatIconModule,
    AdditionalInfoComponent,
    MatExpansionModule,
    MatButtonToggleModule,
    AsyncPipe,
    CommonModule,
    RouterModule,
    MatTableModule,
    TdoeKpiCardComponent,
    CalendarDaysComponent, 
    MatMenuModule,
    TdoeButtonDirective,
    MatTabsModule,
    TdoeCardComponent,
    MatButtonToggleModule,
    MatProgressBarModule
  ],
  templateUrl: './school-view.component.html',
  styleUrl: './school-view.component.scss'
})
export class SchoolViewComponent implements OnInit {

  private readonly logger = inject(LoggingService);
  private readonly route = inject(ActivatedRoute);
  private readonly schoolService = inject(SchoolService);
  private readonly titleService = inject(Title);
  private readonly router = inject(Router);

  private readonly additionalInfoFields$ = new BehaviorSubject<AdditionalInfoModel.Category[]>([]);
  private readonly calendarView$ = new BehaviorSubject<boolean>(false);
  private readonly school$ = new BehaviorSubject<School | undefined>(undefined);
  private readonly selectedReportingPeriod$ = new BehaviorSubject<SchoolReportingPeriod | undefined>(undefined);
  private readonly selectedCalendar$ = new BehaviorSubject<string | undefined>(undefined);
 
  protected readonly additionalInfoFields: AdditionalInfoModel.Category[] = additionalInfoFields;

  public isExporting: boolean = false;

  private readonly _selectedAdditionalInfoFields: Observable<AdditionalInfoModel.Category[]> = 
    combineLatest([this.additionalInfoFields$, this.school$])
      .pipe(
        map(([categories, school]) => ({categories, school})),
        tap(data => { console.debug('Selected additional info fields:', data.categories); }),
        map((data) => {
          return data.categories
            .filter(category => category.fields.some(field => field.selected))
            .map(category => ({
              ...category,
              displayedColumns: category.fields.filter(field => field.selected).map(field => category.key? field.key.split('.').slice(-1)[0] : field.key),
              fields: category.fields
                .filter(field => field.selected)
                .map(field => ({
                  ...field,
                  key: category.key? field.key.split('.').slice(-1)[0] : field.key
                }))
            }));
        })
      );
    
  public state: { [k: string]: any; } | undefined;

  public constructor() {
    this.state = this.router.getCurrentNavigation()?.extras.state;
  }

  public readonly viewModel$ = combineLatest([this._selectedAdditionalInfoFields,
                                              this.school$,
                                              this.selectedReportingPeriod$,
                                              this.calendarView$,
                                              this.selectedCalendar$])
      .pipe(
        map(([selectedAdditionalInfoFields, school, reportingPeriod, calendarView, calendarNumber]) => ({ selectedAdditionalInfoFields, school, reportingPeriod, calendarView, calendarNumber })),
        map(data => ({
          selectedAdditionalInfoFields: data.selectedAdditionalInfoFields,
          school: data.school,
            reportingPeriodDisplayedColumns: [
              'periodNumber',
              'beginDate',
              'endDate',
              'numberOfDays',
            ],
            reportingPeriod: data.reportingPeriod,
            calendarDayDisplayedColumns: [
              'description',
              'calendarDate',
              'eventType'
            ],
            viewCalendar: data.calendarView,
            calendarNumber: data.calendarNumber ?? data.school?.additionalInformation?.calendarNumber?.at(0)
        })),
        tap(_ => {
          this.logger.debug('SchoolViewComponent -> viewModel$ -> 1', {
            data: {
              _
            }
          });
        }),
        filter(_ => !!_.calendarNumber),
        mergeMap(_ => this.schoolService.getCalendar(_.school!.schoolId!, _.school!.schoolYear!, _.calendarNumber!)
          .pipe(
            map(calendar => ({
              calendar,
              data: _
            }))
          )),
        map(_ => ({
          selectedAdditionalInfoFields: _.data.selectedAdditionalInfoFields,
          school: _.data.school,
          reportingPeriodDisplayedColumns: _.data.reportingPeriodDisplayedColumns,
          calendarDayDisplayedColumns: _.data.calendarDayDisplayedColumns,
          reportingPeriod: _.data.reportingPeriod ?? _.calendar.reportingPeriods?.at(0),
          totalPeriodDays: _.calendar.reportingPeriods?.reduce((td, p) => td + (p.numberOfDays ?? 0), 0),
          calendar: _.calendar,
          calendarView: _.data.viewCalendar ? 'calendar' : 'list',
          calendarNumber: _.data.calendarNumber
        })),
        tap(_ => {
          this.logger.debug('SchoolViewComponent -> viewModel$ -> 2', {
            data: {
              _
            }
          });
        })
      );
      
  public getEventTypeDisplay(abbreviation: string): string {
    switch (abbreviation) {
      case 'ID':
        return 'Instructional Days';
      case 'AD':
        return 'Abbreviated Days';
      case 'IS':
        return 'In-Service Days';
      case 'IO':
        return 'In-Service Optional Days';
      case 'M':
        return 'Make-up Days';
      case 'O':
        return 'Discretionary Days';
      case 'PT':
        return 'Parent/Teacher Conference Days';
      case 'SI':
        return 'Inclement Weather Days';
      case 'SP':
        return 'Professional Development Days';
      case 'TV':
        return 'Teacher Vacation Days';
      case 'WN':
        return 'Waived Days';
      default:
        return 'Other Holiday';
    }
  }

  private async LoadSchoolById(schoolId: string): Promise<void> {
    const school = await firstValueFrom(this.schoolService.getSchool(schoolId));
    this.school$.next(school);
    this.titleService.setTitle(`${this.titleService.getTitle()} - ${school.name}`);
  }

  public async ngOnInit(): Promise<void> {
    const params = await firstValueFrom(this.route.params);
    if (params) {
      const schoolId: string = params['id'];
      if (schoolId) {
        this.LoadSchoolById(schoolId);
        return Promise.resolve();
      }
    }
  }

  public onFieldSelected(e: AdditionalInfoModel.Category[]): void {
    this.additionalInfoFields$.next(e);
  }

  public onReportingPeriodRowClicked(period: SchoolReportingPeriod): void {
    this.selectedReportingPeriod$.next(undefined);
    this.selectedReportingPeriod$.next(period);
  }

  public onTabSelected(school: School, index: number): void {
    this.selectedCalendar$.next(school.additionalInformation?.calendarNumber?.at(index));
  }
    
  public toggleCalendarView(e: MatButtonToggleChange): void {
    this.calendarView$.next(e.value === 'calendar');
  }

  public getNestedProperty = (object: Dictionary, key: string): unknown => 
    ObjectUtilities.getNestedProperty(object, key);

  public async export(id: string, additonalInfo?: Category[], calendar?: string, period?: number): Promise<void> {
    this.isExporting = true;
    try {
      this.logger.debug('SchoolViewComponent -> export', {
        data: {
          id,
          additonalInfo
        }
      });
      const file = await firstValueFrom(this.schoolService.exportPdf(id, additonalInfo, calendar, period));
      downloadExcelDocument(file);
    } finally {
      this.isExporting = false;
    }
  }

}