/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SchoolCalendarEvent } from './schoolCalendarEvent';
import { SchoolReportingPeriod } from './schoolReportingPeriod';

/**
 * School Calendar Annual Summary
 */
export interface SchoolCalendar { 
    /**
     * Calendar event KPIs
     */
    calendarEvents?: Array<SchoolCalendarEvent>;
    /**
     * Reporting segmentations
     */
    reportingPeriods?: Array<SchoolReportingPeriod>;
}
