import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { combineLatest, debounceTime, filter,  map, switchMap, tap } from 'rxjs';
import { ErrorLogService } from 'app/services/error-log/error-log.service';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { UserService } from 'app/services/user/user.service';

@Component({
  selector: 'app-error-category-cards',
  standalone: true,
  imports: [CommonModule, RouterLink, MatIconModule],
  templateUrl: './error-category-cards.component.html',
  styleUrl: './error-category-cards.component.scss'
})
export class ErrorCategoryCardsComponent {

  public readonly errorLogService = inject(ErrorLogService);
  public readonly userService = inject(UserService);
  
  public readonly viewModel$ = combineLatest([
    this.userService.userProfile$
  ]).pipe(map(([profile]) => ({profile})))
    .pipe(filter(_ => !!_.profile))
    .pipe(tap(_ => {
      _.profile?.syncCache();
    }), debounceTime(100))
    .pipe(switchMap(_ => this.errorLogService.getCounts(
      _.profile?.scopeDetails.districtIds ?? [],
      _.profile?.scopeDetails.schoolIds ?? [],
      _.profile?.scopeDetails.years ?? []
    )))
    .pipe(map(_ => ({
      errorCounts: _
    })));

}
