import { CommonModule } from '@angular/common';
import { Component, inject, Signal, signal } from '@angular/core';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { PagedResponse } from 'app/models/table-data-model';
import { ColumnDefinition, Pagination, School, SchoolQuery, Sorting } from 'app/dto';
import { SchoolService } from 'app/services/school/school.service';
import { UserService } from 'app/services/user/user.service';
import { UserProfile } from 'app/services/user/user.model';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, filter, firstValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { SelectedData } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { LoggingService } from '@tdoe/design-system';
import { DataLookupTableComponent } from '../shared/data-lookup-table/data-lookup-table.component';
import { SchoolTableConfig } from '../shared/school-table/school-table.config';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { downloadExcelDocument } from 'app/utilities/excel-export-utilities/excel-export-utilities';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-data-lookup-school',
  standalone: true,
  imports: [
    CommonModule,
    ScopeContextFilterComponent,
    DataLookupTableComponent
  ],
  templateUrl: './data-lookup-school.component.html',
  styleUrls: ['./data-lookup-school.component.scss']
})
export class DataLookupSchoolComponent {

  private readonly tablePagination = signal({ pageIndex: 0, pageSize: 50} as Pagination);
  private readonly tableSort = signal({} as Sorting);

  private readonly schoolService = inject(SchoolService);
  private readonly userService = inject(UserService);
  private readonly logger = inject(LoggingService);

  protected userProfile: Signal<UserProfile | undefined>;

  public tableConfig = SchoolTableConfig;

  public readonly staticColumns = [
    { key: 'name', label: 'School Name' },
    { key: 'schoolNumber', label: 'School Number' },
    { key: 'calendarNumbers', label: 'Calendars' },
    { key: 'scheduledDays', label: 'Scheduled Days' }
  ];

  public isExporting = false;

  public displayNameFormatter(item: School): string {
    return StringUtilities.FormatStringForUrl(item.name ?? '');
  }

  public excelExport(query: School, columns: ColumnDefinition[]): Observable<File> {
    return new Observable();
  }

  protected isLoading = true;

  protected query = signal<SchoolQuery>({});

  public async onExportClicked(e: { query: School; columns: ColumnDefinition[]; format: 'csv' | 'xlsx'; }): Promise<void> {
    this.logger.debug('onExportClicked', {
      data: {
        e
      }
    });
    this.isExporting = true;

    let file = new File([], '', {});
    
    try {
      file = await firstValueFrom(this.schoolService.getSchoolsExcelExportBySearchTerms(e.query, e.columns, e.format));
    } catch (err) {
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 413:
          this.logger.error('Export is limited to 500,000 records. Please apply filters to reduce the number of results and try again.', {
            data: {
              err
            },
            notify: true,
            title: 'Too Much Data'
          });
          break;
        }
      } else {
        this.logger.error('student export error', { data: {
          err
        }});
      }
    }

    if (file.size > 0) {
      downloadExcelDocument(file);
    }

    this.isExporting = false;
  }

  protected onPageChanged(pagination: Pagination): void {
    this.tablePagination.set(pagination);
  }

  protected onSortClicked(sorting: Sorting): void {
    this.tableSort.set(sorting);
  }

  protected readonly pagedResponse = toSignal(combineLatest([
    toObservable(this.query),
    toObservable(this.tablePagination),
    toObservable(this.tableSort)
  ]).pipe(
    debounceTime(300),
    map(([query, pagination, sorting]) => ({query, pagination, sorting})),
    switchMap(data => {
      const pro = this.userService.userProfile$.getValue();
      
      if ((pro?.scopeDetails.districtIds?.length ?? 0) > 0) {
        data.query.districtIds = [ ...new Set([
          ...pro?.scopeDetails.districtIds!,
          ...data.query.districtIds ?? []])];
      }

      if ((pro?.scopeDetails.schoolIds?.length ?? 0) > 0) {
        data.query.schoolIds = [...new Set([
          ...pro?.scopeDetails.schoolIds!,
          ...data.query.schoolIds ?? []
        ])];
      }

      if ((pro?.scopeDetails.years?.length ?? 0) > 0) {
        data.query.years = [...new Set([
          ...pro?.scopeDetails.years!,
          ...data.query.years ?? []
        ])];
      }

      return this.schoolService.searchSchools(data.query, data.pagination, data.sorting
      ).pipe(
        map((pagedResponse: PagedResponse<School>) => pagedResponse));
      })
  ));

  public constructor() {
    this.userProfile = toSignal<UserProfile>(this.userService.userProfile$.pipe(
      filter(userProfile => !!userProfile),
      tap(userProfile => { 
          this.query.update(query => ({
            ...query,
            schools: userProfile?.scopeDetails.schoolIds,
            districts: userProfile?.scopeDetails.schoolIds
          }));
          this.isLoading = false;
        })
      ));
  }

  protected onTableSortClicked(sorting: Sorting): void {
    this.tableSort.set(sorting);
  }

  protected onTablePageChanged(pagination: Pagination): void {
    this.tablePagination.set(pagination);
  }

  public onResetQuery(query: SchoolQuery): void {
    this.query.set(query);
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: SelectedData): void {
    this.resetPagination();
    this.query.update(query => ({
      ...query,
      ...selectedScopeContextFilterData
    }));
  }

  protected onQuickSearch(schoolQuery: SchoolQuery): void {
    this.resetPagination();
    this.query.update(query => ({
      ...query,
      ...schoolQuery
    }));
  }
  
  private resetPagination(): void {
    this.logger.debug('DataLookupClassComponent -> resetPagination');
    this.tablePagination.update(p => ({
      pageIndex: 0,
      pageSize: 50
    }));
  }
}
