import { HttpParams } from '@angular/common/http';
import dayjs from 'dayjs';

export * as HttpParamsUtilities from '../../utilities/http-params-utilities/http-params-utilities';

export function toHttpParams(...paramsObjects: object[]): HttpParams {
  let params = new HttpParams();

  function addParam(key: string, value: unknown): void {
    if (value === undefined || value === null) {
      return;
    }

    if (value instanceof Date) {
      params = params.set(key, dayjs(value).format('MM/DD/YYYY'));
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        addParam(`${key}[${index}]`, item);
      });
    } else if (typeof value === 'object') {
      Object.entries(value).forEach(([objKey, objValue]) => {
        addParam(`${key}.${objKey}`, objValue);
      });
    } else {
      params = params.set(key, value.toString());
    }
  }

  paramsObjects.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      addParam(key, value);
    });
  });

  return params;
}

export function ensureNumeric(input: string[] | string | number[] | number, returnArray?: boolean): number[] | number {
  if (Array.isArray(input)) {
    return input.map(_ => parseInt(_.toString()));
  } else {
    return returnArray ? [parseInt(input.toString())] : parseInt(input.toString());
  }
}
