<div class="container header-title">
    <div class="container">
        <h1>Student Details</h1>
    </div>
</div>
<div class="container" *ngIf="viewModel$ | async as viewData">
    <div class="container">
        <div class="header">
            <div>
                <a  (click)="onBackToStudentsClick()"><mat-icon>arrow_back</mat-icon> Back to Students</a>
                <h3>Student Profile</h3>
            </div>
            <div>
                <app-additional-info 
                    [additionalInfoFields]="additionalInfoFields" 
                    [contextKey]="additionalInfoContextKey"
                    (fieldSelected)="onFieldSelected($event)" />
            </div>
        </div>
        <div mat-accordion class="mat-accordion">
            <mat-expansion-panel *ngIf="viewData.student as student" expanded hideToggle disabled>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Student Personal Info
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="data-list">
                    <div class="field name-field">
                        <h3 class="value">{{student.nameLast}}, {{student.nameFirst}} {{student.nameMiddle}}</h3>
                        <span class="name">Last, First, Middle Name</span>
                    </div>
                    <div class="field">
                        <span class="name">SSID</span>
                        <span class="value">{{student.ssid}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Grade</span>
                        <span class="value">{{student.grade}}</span>
                    </div>
                    <div class="field">
                        <span class="name">District</span>
                        <span class="value">{{student.district}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Service District</span>
                        <span class="value">{{student.serviceDistrict}}</span>
                    </div>
                    <div class="field">
                        <span class="name">School</span>
                        <span class="value">{{student.school}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Service School</span>
                        <span class="value">{{student.serviceSchool}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Student Standard Day (Minutes)</span>
                        <span class="value">{{student.standardDayMinutes}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Service Type</span>
                        <span class="value">{{student.serviceType}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Withdrawal End Date</span>
                        <span class="value">{{student.withdrawalEndDate}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Withdrawal Code</span>
                        <span class="value">{{student.withdrawalCode}}</span>
                    </div>
                    <div class="field">
                        <span class="name">English Language Background</span>
                        <span class="value">{{student.language}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Gender</span>
                        <span class="value">{{student.gender}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Ethnicity/Race</span>
                        <span class="value">{{student.ethnicity}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <ng-container *ngFor="let category of viewData.studentCategories">
                <ng-template [ngTemplateOutlet]="categoryPanel" [ngTemplateOutletContext]="{category: category}"></ng-template>
            </ng-container>
        </div>
        <div mat-accordion class="mat-accordion">
            <mat-expansion-panel *ngIf="viewData.student as student">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Student Enrollment Information
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="sub-section">
                    <h3>End of service</h3>
                    <div class="data-list">
                        <div class="field">
                            <span class="name">EOS Date</span>
                            <span class="value">{{student.enrollment?.endOfService?.endOfServiceDate}}</span>
                        </div>
                        <div class="field">
                            <span class="name">EOS Action</span>
                            <span class="value">{{student.enrollment?.endOfService?.eosAction}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Comp Doc Date</span>
                            <span class="value">{{student.enrollment?.endOfService?.compDocDate | date}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Comp Doc Type</span>
                            <span class="value">{{student.enrollment?.endOfService?.compDocType}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Comp Doc Period</span>
                            <span class="value">{{student.enrollment?.endOfService?.compDocPeriod}}</span>
                        </div>
                    </div>
                </div>
                <div class="sub-section">
                    <h3>Classification</h3>
                    <app-enrollment-classification [classifications]="student.enrollment?.classifications!"></app-enrollment-classification>
                </div>
                <div class="sub-section">
                    <h3>Club memberships</h3>
                    <app-enrollment-membership [memberships]="student.enrollment?.clubMembership!"></app-enrollment-membership>
                </div>
                <div class="sub-section" *ngIf="student.enrollment">
                    <h3>History</h3>
                    <app-enrollment-history [studentEnrollmentHistorySet]="student.enrollment.enrollmentHistory!"></app-enrollment-history>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>

<ng-template #categoryPanel let-category="category">
    <mat-expansion-panel [(expanded)]="category.expanded" >
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{category.name}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="data-list">
            <ng-container *ngFor="let field of category.fields">
                <div class="field">
                    <span class="name">{{field.name}}</span>
                    <span class="value">{{field.value }}</span>
                </div>
            </ng-container>
        </div>
    </mat-expansion-panel>
</ng-template>