import { Component, inject } from '@angular/core';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ErrorCategoryTabsComponent } from './shared/error-category-tabs/error-category-tabs.component';
import { BehaviorSubject, combineLatest, filter, map } from 'rxjs';
import { UserService } from 'app/services/user/user.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ScopeContextFilterComponent } from "../shared/scope-context-filter/scope-context-filter.component";
import { SelectedData } from '../shared/scope-context-filter/scope-context-filter.model';
import { LoggingService } from '@tdoe/design-system';
import dayjs from 'dayjs';

@Component({
  selector: 'app-error-log',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ErrorCategoryTabsComponent,
    RouterOutlet,
    CommonModule,
    ScopeContextFilterComponent,
    ErrorCategoryTabsComponent
],
  templateUrl: './error-log.component.html',
  styleUrl: './error-log.component.scss'
})
export class ErrorLogComponent {

  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  private readonly logger = inject(LoggingService);

  public readonly selectedData = new BehaviorSubject<SelectedData>({} as SelectedData);

  public readonly viewModel = toSignal(combineLatest([
    this.userService.userProfile$,
    this.selectedData
  ]).pipe(map(([profile, query]) => ({ profile, query })))
    .pipe(filter(profile => !!profile.profile))
    .pipe(map(data => ({
      districtIds: data.profile!.scopeDetails.districtIds,
      schoolIds: data.profile!.scopeDetails.schoolIds,
      scopeDetails: data.profile!.scopeDetails
    })))
  );

  protected onErrorCategoryTabSelected(tabName: string): void { 
    this.router.navigate([`/error-log/${tabName}`]);
  }

  protected onSearchClicked(selectedData: SelectedData): void {
    if (!Array.isArray(selectedData.years)) {
      selectedData.years = [selectedData.years];
    }
    this.userService.userProfile$.getValue()?.syncCache();
    this.userService.rescopeUser(selectedData);
    this.logger.debug('ErrorLogComponent -> onSearchClicked', {
      data: {
        selectedData
      }
    });
  }

}
