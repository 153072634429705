export * as UserModel from './user.model';
import parseURI from 'parse-uri';
import { CacheService } from '../cache-service/cache.service';
import { createScopeClaim } from 'app/utilities/string-utilities/string-utilities';
import dayjs from 'dayjs';
import { SelectedData } from 'app/components/shared/scope-context-filter/scope-context-filter.model';

export interface ScopeDetails {
    districtIds?: string[];
    schoolIds?: string[];
    years?: number[];
}

export class UserProfile {

    public appRoles?: string[];
    public districtRoles!: string[];
    public isDeveloper = false;
    public isStateUser = false;
    public schoolRoles!: string[];
    public scopeDetails!: ScopeDetails;

    private readonly cacheService: CacheService;

    public constructor (cacheService: CacheService, appRoles?: string[]) {
        this.cacheService = cacheService;
        this.appRoles = appRoles;
        if (appRoles) {
            this.isStateUser = appRoles.some(appRole => appRole.toLowerCase().startsWith('state.'));
            // this.isStateUser = true;
            this.isDeveloper = !!appRoles.find(_ => _ === 'App.Developer');
            this.districtRoles = this.getRoles(appRoles, 'district');
            this.schoolRoles = this.getRoles(appRoles, 'school');
            this.initializeScopeDetails();
        }
    }

    public get districtClaims(): string[] | undefined {
        return this.cacheService.devScopeDistricts?.map(_ => createScopeClaim(_));
    }

    public get schoolClaims(): string[] | undefined {
        return this.cacheService.devScopeSchools?.map(_ => createScopeClaim(_));
    }

    public allowedDistricts(): string[] | undefined {
        const result: string[] = [];

        (this.districtRoles).forEach(claim => {
            try {
                const districtNo = parseURI(claim).queryKey['m'];
                const districtNumber = parseInt(districtNo);
                const district = this.cacheService.devScopeDistricts.find(_ => _.districtNumber === districtNumber);
                if (district?.districtId) {
                    result.push(district.districtId);
                }
            } catch (ex) {
                console.error('UserProfile -> allowedDistricts', {
                    data: {
                        ex,
                        claim
                    }
                });
            }
        });

        console.debug('UserProfile -> allowedDistricts', {
            data: {
                roles: this.districtRoles,
                result
            }
        });

        return result;
    }

    public allowedSchools(): string[] | undefined {
        const result = (this.schoolRoles).map(uri => parseURI(uri).host)
            .filter(schoolId => !!schoolId);

        console.debug('UserProfile -> allowedSchools', {
            roles: this.schoolRoles,
            claims: this.schoolClaims,
            result,
            scopeDetails: this.scopeDetails
        });

        return result;
    }

    public checkPermission(id: string, scope: 'district' | 'school', permission?: string): boolean {
        switch (scope) {
            case 'district':
                return (this.districtClaims ?? this.districtRoles).map(uriString => parseURI(uriString)).some(uri => uri.host === id && (!permission || uri.userInfo === permission)) ?? false;
            case 'school':
                return (this.schoolClaims ?? this.schoolRoles).map(uriString => parseURI(uriString)).some(uri => uri.host === id && (!permission || uri.userInfo === permission)) ?? false;
        }
    }

    public getScopeIds(type: 'district' | 'school'): string[] | undefined {
        this.syncCache();
        switch (type) {
            case 'district':
                if (this.scopeDetails.districtIds) {
                    return this.scopeDetails.districtIds;
                } else if (this.isStateUser) {
                    return [];
                } else {
                    return this.allowedDistricts();
                }
            case 'school':
                if (this.scopeDetails.schoolIds) {
                    return this.scopeDetails.schoolIds;
                } else if (this.isStateUser) {
                    return [];
                } else {
                    return this.allowedSchools();
                }
        }
    }

    public getScopeYears(): number[] | number {
        this.syncCache();
        if (Array.isArray(this.scopeDetails.years)) {
            return this.scopeDetails.years ?? [dayjs().year()];
        } else if ((this.scopeDetails.years as any) instanceof String) {
            return parseInt(this.scopeDetails.years!.toString()) ?? dayjs().year();
        } else {
            return (this.scopeDetails.years as unknown) as number ?? dayjs().year();
        }
    }

    public setScopeIds(type: 'district' | 'school', entityIds: string[]): void {
        switch (type) {
            case 'district':
                this.scopeDetails.districtIds = entityIds;
                break;
            case 'school':
                this.scopeDetails.schoolIds = entityIds;
                break;
        }
    }

    public syncCache(): void {
        this.scopeDetails = {
            districtIds: this.cacheService.userSelectedDistricts,
            schoolIds: this.cacheService.userSelectedSchools,
            years: Array.isArray(this.cacheService.userSelectedYears) ? this.cacheService.userSelectedYears : [this.cacheService.userSelectedYears as number]
        };
    }

    private initializeScopeDetails(): void {
        this.scopeDetails = {
            years: [dayjs().year()]
        };

        if (this.schoolRoles.length === 1 && !this.isStateUser) {
            this.scopeDetails = {
                ...this.scopeDetails,
                schoolIds: this.schoolRoles.map(schoolRoles => parseURI(schoolRoles).host)
                    .filter(districtId => !!districtId)
            };
        }

        if ((this.districtRoles?.length ?? 0) === 1 && !this.isStateUser) {
            this.scopeDetails = {
                ...this.scopeDetails,
                districtIds: this.districtRoles.map(districtRole => parseURI(districtRole).host)
                    .filter(districtId => !!districtId)
            };
        }
    }

    private getRoles(appRoles: string[], type: 'district' | 'school'): string[] {
        return appRoles.filter(appRole => appRole.startsWith(`${type}://`));
    }

    public ProfileFactory(selectedData: SelectedData): UserProfile {
        this.scopeDetails = {
            schoolIds: selectedData.schoolIds ?? this.scopeDetails.schoolIds,
            districtIds: selectedData.districtIds ?? this.scopeDetails.districtIds,
            years: selectedData.years ?? this.scopeDetails.years
        };

        return this;
    }

}
