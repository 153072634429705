import { FormlyFieldConfig } from '@ngx-formly/core';

export const StaffTableConfig: { [key: string]: FormlyFieldConfig } = {
    nameLast: {
        key: 'nameLast',
        type: 'tdoe-input',
        props: {
            label: 'Last Name',
            type: 'text'
        }
    },
    nameFirst: {
        key: 'nameFirst',
        type: 'tdoe-input',
        props: {
            label: 'First Name',
            type: 'text'
        }
    },
    nameMiddle: {
        key: 'nameMiddle',
        type: 'tdoe-input',
        props: {
            label: 'Middle Name',
            type: 'text'
        }
    },
    teacherLicenseNumber: {
        key: 'teacherLicenseNumber',
        type: 'tdoe-input',
        props: {
            label: 'Teacher License No.',
            type: 'text'
        }
    },
    email: {
        key: 'email',
        type: 'tdoe-input',
        props: {
            label: 'Email',
            type: 'email'
        }
    }
};

export const formlyFieldConfigs: FormlyFieldConfig[] = [
    {
        fieldGroupClassName: 'display-flex',
        fieldGroup: [
            {
                key: 'nameLast',
                type: 'tdoe-input',
                props: {
                    label: 'Last Name',
                    type: 'text'
                },
                className: 'text-field'
            },       
            {
                key: 'nameFirst',
                type: 'tdoe-input',
                props: {
                    label: 'First Name',
                    type: 'text'
                },
                className: 'text-field'
            },
            {
                key: 'nameMiddle',
                type: 'tdoe-input',
                props: {
                    label: 'Middle Name',
                    type: 'text'
                },
                className: 'text-field'
            },
            {
                key: 'teacherLicenseNumber',
                type: 'tdoe-input',
                props: {
                    label: 'Teacher License No.',
                    type: 'text'
                },
                className: 'text-field'
            },
            {
                key: 'email',
                type: 'tdoe-input',
                props: {
                    label: 'Staff Organization Email',
                    type: 'email'
                },
                className: 'email-field'
            }
        ]
    }
];