/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventService, InactivityService, LoggingService, NavEvent } from '@tdoe/design-system';
import { HeaderNavItemsService } from './services/header-nav-items/header-nav-items.service';
import { ConfigService } from './services/config/config.service';
import packageInfo from '../../package.json';
import { BehaviorSubject, combineLatest, debounceTime, firstValueFrom, map, startWith, switchMap, tap, timer } from 'rxjs';
import { UserService } from './services/user/user.service';
import { MsalService } from '@azure/msal-angular';
import { ErrorLogService } from './services/error-log/error-log.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private readonly hasErrors$ = new BehaviorSubject<boolean>(false);
  private readonly msalInitialized$ = new BehaviorSubject<boolean>(false);

  public viewModel$ = combineLatest([
    this._configService.getApiVersion().pipe(startWith(undefined)),
    this._eventService.onLoadingTextChange.pipe(startWith('Loading...')),
    this._headerNavItemsService.getNavItems(),
    this.userService.userProfile$,
    this.msalInitialized$,
    timer(2000, 60000)
    .pipe(switchMap(() => this.errorLogService.hasErrors()))
    .pipe(startWith(false))
  ]).pipe(
    debounceTime(300),
    map(([apiVersion, loadingText, navItems, profile, msalInitialized, hasErrors]) => ({apiVersion, loadingText, navItems, profile, msalInitialized, hasErrors})),
    map(data => ({
      apiVersion: data.apiVersion?.version,
      clientVersion: packageInfo.version,
      loadingText: data.loadingText ?? 'Loading...',
      navItems: data.navItems,
      profile: data.profile,
      hasErrors: data.hasErrors,
      msalInitialized: data.msalInitialized
    })),
    tap(result => {
      if (result) {
        const errorLogNavItem = result.navItems.find(_ => _.id === 'error-log');
        if (errorLogNavItem) {
          this.logger.debug('AppComponent -> viewModel$ -> hasErrors', {
            data: {
              result
            }
          });
          errorLogNavItem.alert = result.hasErrors;
        }
      }
    })
  );

  public showPanel = false;

  public constructor(
    private readonly _router: Router,
    private readonly _headerNavItemsService: HeaderNavItemsService,
    private readonly _inactivityService: InactivityService,
    private readonly _configService: ConfigService,
    private readonly _eventService: EventService,
    private readonly userService: UserService,
    private readonly msalService: MsalService,
    private readonly errorLogService: ErrorLogService,
    private readonly logger: LoggingService) {
    console.log('AppComponent -> constructor');
    userService.loadDistrictData();
  }

  public async ngOnInit(): Promise<void> {
    this.logger.debug('AppComponent -> ngOnInit -> Enter');

    this.logger.debug('AppComponent -> ngOnInit -> Start inactivity timer');
    this._inactivityService.startMonitoring();

    this.logger.debug('AppComponent -> ngOnInit -> Initialize MSAL');
    await this.initializeMsal();

    this.logger.debug('AppComponent -> ngOnInit -> Exit');
  }

  public onNavClick($event: NavEvent): void {
    if ($event.source.children) return;
    this._router.navigate([$event.source.id]);
  }

  public onPanelClosed(): void {
    console.debug('AppComponent -> onPanelClosed');
    this.showPanel = false;
  }

  public togglePanel(): void {
    console.debug('AppComponent -> togglePanel', { showPanel: this.showPanel });
    this.showPanel = !this.showPanel;
  }

  private async initializeMsal(): Promise<void> {
    await this.msalService.instance.initialize();
    await firstValueFrom(this.msalService.initialize());
    this.msalInitialized$.next(true);
    this.msalInitialized$.complete();
  }

}
