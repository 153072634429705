export const ClassFilterConfig = [
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'courseCode',
          type: 'tdoe-input',
          props: {
            label: 'Course Code'
          },
          className: 'course-code-field'
        },
        {
          key: 'localClassNumber',
          type: 'tdoe-input',
          props: {
            label: 'Local Class Number'
          },
          className: 'local-class-number-field'
        },
        {
          key: 'sectionIdentifier',
          type: 'tdoe-input',
          props: {
            label: 'Section Identifier'
          },
          className: 'section-identifier-field'
        },
        {
          key: 'classTypes',
          type: 'tdoe-select',
          props: {
            label: 'Class Type',
            options: [
              {
                label: 'Conditional',
                value: 'C'
              },
              {
                label: 'Pull-Out',
                value: 'P'
              },
              {
                label: 'Traditional',
                value: 'T'
              }
            ],
            multiple: true
          },
          className: 'class-type-field'
        },
        {
          key: 'teacherTln',
          type: 'tdoe-input',
          props: {
            label: 'Teacher Assigned'
          },
          className: 'teacher-assigned-field'
        },
        {
          key: 'classStartDate',
          type: 'tdoe-date',
          props: {
            label: 'Class Begin Date'
          },
          className: 'class-start-date-field'
        }
      ]
    }
  ];