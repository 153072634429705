/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DayOfWeek } from './dayOfWeek';

/**
 * Represents search criteria for staff assignment of staff members in the education system
 */
export interface StaffMemberStaffAssignmentSearchTerms { 
    /**
     * The assignment code
     */
    assignmentCode?: string;
    /**
     * The end date for the assignment
     */
    assignmentEnd?: string;
    /**
     * The start date for the assignment
     */
    assignmentStart?: string;
    /**
     * The class assignments
     */
    classAssignments?: string;
    /**
     * The end date for the class
     */
    classEnd?: string;
    /**
     * The start date for the class
     */
    classStart?: string;
    /**
     * The course code
     */
    courseCode?: string;
    /**
     * The current assignment description
     */
    currentAssignment?: string;
    /**
     * Indicates if the assignment is federally funded
     */
    federallyFunded?: string;
    /**
     * The grades associated with the assignment
     */
    grades?: string;
    /**
     * The local class number
     */
    localClassNumber?: string;
    /**
     * The days of the week the assignment period covers
     */
    periodDuration?: Array<DayOfWeek>;
    /**
     * The teacher of record
     */
    teacherOfRecord?: string;
    /**
     * The job title for the assignment
     */
    title?: string;
}
