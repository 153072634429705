import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { UserProfile } from './user.model';
import { CacheService } from '../cache-service/cache.service';
import { AccountInfo } from '@azure/msal-browser';
import { LoggingService, ProfileService } from '@tdoe/design-system';
import { SelectedData } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { District } from 'app/dto';
import { SchoolService } from '../school/school.service';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly logger = inject(LoggingService);
  private readonly schoolService = inject(SchoolService);

  public userProfile$ = new BehaviorSubject<UserProfile | undefined>(undefined);

  private GetRoles(accountInfo: AccountInfo): string[] {
    console.log('UserService -> GetRoles -> Enter');
    const idTokenClaims = accountInfo.idTokenClaims;
    if (idTokenClaims) {
      const idTokenClaimValues = (idTokenClaims['scopedRoles'] ?? []);

      if(Array.isArray(idTokenClaimValues)){
        return (idTokenClaimValues as string[]).concat(idTokenClaims?.roles ?? []);
      }
      return ([idTokenClaimValues as string]).concat(idTokenClaims?.roles ?? []);
    }
    return [];
  }

  public constructor(
    private readonly cacheService: CacheService,
                     profileService: ProfileService
  ) {

    if (!this.cacheService.userSelectedDistricts) {
      this.cacheService.userSelectedDistricts = [];
    }

    if (!this.cacheService.userSelectedSchools) {
      this.cacheService.userSelectedSchools = [];
    }

    if (!this.cacheService.userSelectedYears) {
      this.cacheService.userSelectedYears = [dayjs().year()];
    }

    profileService.azureAccount$.subscribe(accountInfo => {
      if (accountInfo) this.initializeUserProfile(accountInfo);
    });
  }

  public initializeUserProfile(accountInfo: AccountInfo): void {
    if (accountInfo) {
      this.userProfile$.next(new UserProfile(this.cacheService, this.GetRoles(accountInfo)));
    } else {
      this.userProfile$.next(undefined);
    }
  }

  public loadDistrictData(): void {
    this.logger.debug('[UserService] -> Loading district data');
    this.schoolService.getDistricts().subscribe({
      next: (districts) => {
        this.logger.debug('[UserService] -> District data received', {
          data: {
            districts
          }
        });
        this.cacheService.devScopeDistricts = districts;
      },
      error: (err) => {
        this.logger.error('UserService -> loadDistrictData', {
          data: {
            err
          },
          notify: true,
          title: 'Error loading district data'
        });
      }
    });
  }

  public rescopeUser(selectedData: SelectedData): UserProfile | undefined {
    const result = this.userProfile$.getValue()?.ProfileFactory(selectedData);
    
    if (result) {
      this.userProfile$.next(result);
      return result;
    }

    return this.userProfile$.getValue();
  }

}
