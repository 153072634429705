/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StudentEnrollment } from './studentEnrollment';
import { StudentEnrollmentClassification } from './studentEnrollmentClassification';
import { StudentVocational } from './studentVocational';
import { StudentAdditionalInformation } from './studentAdditionalInformation';
import { StudentDemographics } from './studentDemographics';
import { StudentIneligibilityFundingStatus } from './studentIneligibilityFundingStatus';
import { StudentEnrollmentEndOfService } from './studentEnrollmentEndOfService';

/**
 * Represents search criteria for querying student records.
 */
export interface StudentSearchTerms { 
    enrollmentStartDate?: string;
    tos?: string;
    withdrawalEndDate?: string;
    withdrawalCode?: string;
    assignmentEndDate?: string;
    assignmentStartDate?: string;
    /**
     * Class end date
     */
    classEndDate?: string;
    classStartDate?: string;
    classType?: string;
    courseCode?: string;
    localClassNumber?: string;
    alphaGrade?: string;
    creditsEarned?: number;
    numericGrade?: number;
    optionLevel?: string;
    optionNumber?: number;
    specEdBeginDate?: string;
    specEdEndDate?: string;
    specEdDisabilityBeginDate?: string;
    specEdDisabilityEndDate?: string;
    specEdDisabilityLevel?: string;
    specEdDisabilityType?: string;
    standardReportPeriod?: string;
    standardStudentAda?: string;
    standardStudentAdm?: string;
    disciplinaryEnrollmentPeriodStart?: string;
    disciplinaryEnrollmentPeriodEnd?: string;
    disciplinaryPeriodBegin?: string;
    disciplinaryPeriodEnd?: string;
    disciplinaryType?: string;
    disciplinaryReason?: string;
    zeroTolerance?: boolean;
    additionalInfo?: StudentAdditionalInformation;
    assignmentEnrollmentPeriodEndDate?: string;
    assignmentEnrollmentPeriodStartDate?: string;
    classification?: StudentEnrollmentClassification;
    /**
     * Single Character Alpha. probably enrollmentCode.
     */
    code?: string;
    dateOfBirth?: string;
    demographics?: StudentDemographics;
    displayName?: string;
    /**
     * District number and name concatenation
     */
    readonly district?: string;
    /**
     * District Identifier (LEA)
     */
    districtId?: string;
    /**
     * District name
     */
    districtName?: string;
    /**
     * District number
     */
    districtNumber?: number;
    endOfService?: StudentEnrollmentEndOfService;
    enrollment?: StudentEnrollment;
    enrollmentDuration?: number;
    readonly enrollmentEndDate?: string;
    ethnicity?: string;
    gender?: string;
    /**
     * [K-12]
     */
    grade?: string;
    id?: string;
    ineligibilityFundingStatus?: StudentIneligibilityFundingStatus;
    label?: string;
    language?: string;
    /**
     * Last, First, Middle Gender
     */
    nameFirst?: string;
    /**
     * Last, First, Middle Gender
     */
    nameLast?: string;
    /**
     * Last, First, Middle Gender
     */
    nameMiddle?: string;
    /**
     * School number and name contcatenation
     */
    readonly school?: string;
    /**
     * School name
     */
    schoolName?: string;
    /**
     * School Number
     */
    schoolNumber?: number;
    /**
     * Would like string identifier k_school
     */
    schoolId?: string;
    /**
     * School year
     */
    schoolYear?: number;
    /**
     * ServiceDistrict
     */
    serviceDistrict?: string;
    /**
     * ServiceSchool
     */
    serviceSchool?: string;
    /**
     * Single Character Alpha
     */
    serviceType?: string;
    sped?: string;
    /**
     * U.S. Social Security Number
     */
    ssid?: string;
    standardDayMinutes?: number;
    vocationalInfo?: StudentVocational;
    vocOutsideIP?: string;
    /**
     * Gets or sets a collection of district identifiers to filter the search.
     */
    districtIds?: Array<string>;
    /**
     * Gets or sets a collection of school identifiers to filter the search.
     */
    schoolIds?: Array<string>;
    /**
     * Gets or sets the academic year for the search.
     */
    years?: Array<number>;
    /**
     * Calculated EL Service Years
     */
    calculatedELServiceYears?: string;
    /**
     * Date first enrolled in public school
     */
    dateFirstEnrolledInPublicSchool?: string;
    /**
     * Demographic
     */
    demographic?: string;
    /**
     * EL Service Years
     */
    elServiceYears?: string;
    /**
     * English language background
     */
    englishLanguageBackground?: boolean;
    immigration?: string;
    /**
     * Native language
     */
    nativeLanguage?: Array<string>;
    /**
     * Race
     */
    race?: string;
    /**
     * Year entered 9th grade
     */
    yearEntered9thGrade?: number;
    /**
     * Gets or sets the enrollment code for filtering.
     */
    enrollmentCode?: string;
    /**
     * Gets or sets the enrollment classification for filtering.
     */
    enrollmentClassification?: string;
    /**
     * Gets or sets the funding eligibility criteria for filtering.
     */
    fundingEligibility?: Array<string>;
    /**
     * Gets or sets the attendance date for filtering.
     */
    attendanceDate?: string;
    /**
     * Gets or sets the attendance type for filtering.
     */
    attendanceType?: Array<string>;
    /**
     * Reporting begin date
     */
    reportingBeginDate?: string;
    /**
     * Reporting end date
     */
    reportingEndDate?: string;
    /**
     * Class period duration
     */
    classPeriodDuration?: number;
    /**
     * Class period meeting days
     */
    classPeriodMeetingDays?: Array<string>;
    /**
     * Class period name
     */
    classPeriodName?: string;
    /**
     * Gets or sets the enrollment period start date for filtering.
     */
    enrollmentPeriodStartDate?: string;
    /**
     * Gets or sets the enrollment period end date for filtering.
     */
    enrollmentPeriodEndDate?: string;
    /**
     * Gets or sets the classification for filtering.
     */
    classificationCode?: string;
    /**
     * Gets or sets the classification end date for filtering.
     */
    classificationEndDate?: string;
    /**
     * Classification start date
     */
    classificationStartDate?: string;
    /**
     * Alpha grade
     */
    alphaGradeInput?: Array<string>;
    /**
     * Year class was taken
     */
    classSchoolYear?: number;
    /**
     * Term class was taken
     */
    classTerm?: Array<string>;
    /**
     * Credits attempted
     */
    creditsAttempted?: number;
    /**
     * Gets or sets the credits earned filter.
     */
    creditsEarnedInput?: string;
    /**
     * Gets or sets the numeric grade filter.
     */
    numericGradeInput?: string;
    /**
     * Quality points
     */
    qualityPoints?: number;
    /**
     * Gets or sets the option number filter.
     */
    optionNumberInput?: string;
    /**
     * Gets or sets the vocational program item filter.
     */
    vocationalProgramItem?: string;
    /**
     * Gets or sets the vocational report period filter.
     */
    vocationalReportPeriod?: string;
    /**
     * Gets or sets the vocational student ADA (Average Daily Attendance) filter.
     */
    vocationalStudentAda?: string;
    /**
     * Gets or sets the vocational student ADM (Average Daily Membership) filter.
     */
    vocationalStudentAdm?: string;
    /**
     * Gets or sets the club begin date for filtering.
     */
    clubBeginDate?: string;
    /**
     * Gets or sets the club end date for filtering.
     */
    clubEndDate?: string;
    /**
     * Gets or sets the club identifier for filtering.
     */
    clubId?: string;
    /**
     * CTSO chapter ID
     */
    ctsoChapterId?: string;
    /**
     * CTSO membership ID
     */
    ctsoMembershipId?: string;
    endOfServiceDate?: string;
    /**
     * P=Promoted, R=Retained, D =Demoted
     */
    eosAction?: string;
    compDocDate?: string;
    compDocType?: string;
    /**
     * EA=Early Grad, SP= Spring Grad, SU=Summer Grad
     */
    compDocPeriod?: string;
    /**
     * Ineligibility funding begin date
     */
    ineligibilityFundingBeginDate?: string;
    /**
     * Ineligibility funding end date
     */
    ineligibilityFundingEndDate?: string;
    /**
     * Gets or sets the begin date for filtering.
     */
    beginDate?: string;
    /**
     * Gets or sets the class start dates for filtering.
     */
    classStartDates?: string;
    /**
     * Gets or sets the enrollment period end date for filtering.
     */
    enrollmentPeriodEnd?: string;
    /**
     * Gets or sets the enrollment period start date for filtering.
     */
    enrollmentPeriodStart?: string;
    /**
     * Gets or sets a collection of grade levels to filter the search.
     */
    grades?: Array<string>;
}
