/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const DayOfWeek = {
    NUMBER_0: 0 as DayOfWeek,
    NUMBER_1: 1 as DayOfWeek,
    NUMBER_2: 2 as DayOfWeek,
    NUMBER_3: 3 as DayOfWeek,
    NUMBER_4: 4 as DayOfWeek,
    NUMBER_5: 5 as DayOfWeek,
    NUMBER_6: 6 as DayOfWeek
};