import { Dictionary } from 'app/models/dictionary';

export * as ObjectUtilities from './object-utilities';

/**
 * @deprecated Use `removeFalsyStringProperties`
 */
export function setEmptyStringsToUndefined<T extends object>(obj: T): T {
  if(Array.isArray(obj)){
    throw new Error('Input cannot be an Array.');
  }

  const updatedObj: T = { ...obj };

  for (const key in updatedObj) {
      if (Object.prototype.hasOwnProperty.call(updatedObj, key) 
          && typeof updatedObj[key] === 'string' 
          && updatedObj[key] === '') {
          delete updatedObj[key];
      }
  }

  return updatedObj;
}

/**
 * Deletes all string properties of the object were the value evaluates to false.
 * @param obj 
 * @returns T
 */
export function removeFalsyStringProperties<T extends object>(obj: T): T {
  if(Array.isArray(obj)){
    throw new Error('Input cannot be an Array.');
  }

  const updatedObj: T = { ...obj };

  for (const key in updatedObj) {
      if (Object.prototype.hasOwnProperty.call(updatedObj, key) 
          //&& (typeof updatedObj[key] === 'string' ||  typeof updatedObj[key] === 'undefined')
          && !updatedObj[key]
        ) {
          delete updatedObj[key];
      }
  }

  return updatedObj;
}

export function trimStrings<T extends object>(obj: T): T {
  const updatedObj: T = { ...obj };
  
  for (const key in updatedObj) {
      const updatedTerm = Object.prototype.hasOwnProperty.call(updatedObj, key) ? updatedObj[key] : undefined;
      if (updatedTerm) {
        if(typeof updatedTerm === 'string') {
          (updatedObj[key] as string) = updatedTerm.trim();
        }
        if(Array.isArray(updatedTerm)){
          (updatedObj[key] as unknown[]) = trimArrayStrings(updatedTerm);
        }
      }
  }

  return updatedObj;
}

/**
 * Trims all string values in an array
 * @param arr 
 * @returns An array with all string values trimmed.
 */
export function trimArrayStrings<T extends string | unknown>(arr: T[]): T[] {
  return Array.from(arr, item => 
    typeof item === 'string' ? item.trim() as T : item
  );
}

export function sum (theObject: object): number {
    
  return Object.values(theObject).reduce((previousValue, currentValue) => {
      if ( typeof currentValue === 'number' ){
          return currentValue + previousValue;
      }
      return previousValue;
  },0);
}

/**
 * Shallow compares two objects
 * @param {T} obj1 
 * @param {T} obj2 
 * @returns {boolean} true if objects are equal, otherwise false
 */
export function shallowEqual<T extends Record<string, unknown>>(obj1: T, obj2: T): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => obj1[key] === obj2[key]);
}

/**
 * Deep compares two objects
 * @param {T} obj1 
 * @param {T} obj2 
 * @throws {Error} will throw an error on circular references
 * @returns {boolean} true if objects are equal, otherwise false
 */
export function deepEqual<T extends object>(obj1: T, obj2: T): boolean {

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = (obj1 as Dictionary)[key];
    const val2 = (obj2 as Dictionary)[key];

    // Check if both values are objects and not null
    const areObjects = 
      typeof val1 === 'object' && 
      typeof val2 === 'object' && 
      val1 !== null && 
      val2 !== null;

    if (areObjects) {
      // Handle arrays
      if (Array.isArray(val1) && Array.isArray(val2)) {
        if (val1.length !== val2.length) {
          return false;
        }
        // Recursively compare array elements
        for (let i = 0; i < val1.length; i++) {
          if (!deepCompareValues(val1[i], val2[i])) {
            return false;
          }
        }
      } else if (Array.isArray(val1) !== Array.isArray(val2)) {
        // One is array, other is not
        return false;
      } else {
        // Both are objects, recurse
        if (!deepEqual(val1 as Record<string, unknown>, val2 as Record<string, unknown>)) {
          return false;
        }
      }
    } else {
      // Handle primitives
      if (val1 !== val2) {
        return false;
      }
    }
  }

  return true;
}

/**
 * Compare any two values
 * @param {unknown} val1 
 * @param {unknown} val2 
 * @returns {boolean} true if objects are equal, otherwise false
 */
function deepCompareValues(val1: unknown, val2: unknown): boolean {
  if (typeof val1 === 'object' && typeof val2 === 'object' && val1 !== null && val2 !== null) {
    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (val1.length !== val2.length) {
        return false;
      }
      return val1.every((item, index) => deepCompareValues(item, val2[index]));
    } else if (!Array.isArray(val1) && !Array.isArray(val2)) {
      return deepEqual(val1 as Record<string, unknown>, val2 as Record<string, unknown>);
    }
    return false;
  }
  return val1 === val2;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getNestedProperty(obj: any, path: string): any {
  return path.split('.').reduce((acc, key) => acc[key], obj);
}
