/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Model for error log entry
 */
export interface ErrorItem { 
    /**
     * Class identifier
     */
    classId?: string;
    /**
     * Entity value
     */
    entityValue?: string;
    /**
     * Error
     */
    error?: string;
    /**
     * Error category
     */
    errorCategory?: string;
    /**
     * Error code
     */
    errorCode?: string;
    /**
     * Error report
     */
    errorReport?: string;
    /**
     * School identifier
     */
    schoolId?: string;
    /**
     * School name
     */
    schoolName?: string;
    /**
     * School number
     */
    schoolNumber?: number;
    /**
     * Severity level
     */
    severityLevel?: string;
    /**
     * Staff identifier
     */
    staffId?: string;
    /**
     * Student identifier
     */
    studentId?: string;
}
