/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DayOfWeek } from './dayOfWeek';

export interface StaffMemberStaffAssignment { 
    assignmentEnd?: string;
    assignmentStart?: string;
    classAssignments?: string;
    classEnd?: string;
    classStart?: string;
    courseCode?: string;
    currentAssignment?: string;
    federallyFunded?: string;
    grades?: string;
    localClassNumber?: string;
    periodDuration?: Array<DayOfWeek>;
    teacherOfRecord?: string;
    title?: string;
}
