<ng-container *ngIf="!isLoading; else loading">
  <div class="container page-title">
    <div class="container">
      <h1>Staff List</h1>
    </div>
  </div>

  <div class="container scope-context">
    <div class="container">
      <app-scope-context-filter (searchClick)="onContextFilterSearchClicked($event)">
      </app-scope-context-filter>
    </div>
  </div>

  <div class="container staff-filter">
    <div class="container">
      <app-staff-filter
        [staffSearchTerms]="query()"
        (searchClicked)="onStaffFilterSearchClicked($event)">
      </app-staff-filter>
    </div>
  </div>

  <div class="container table">
    <div class="container">
      <app-data-lookup-table additionalInfoContextKey="StaffTableComponent"
                      clickRoute="/data-lookup/staff-view"
                      identifierPath="id"
                      tableHeading="Staff Information"
                      [chipIgnorables]="['schoolIds','districtIds','schoolIDs','districtIDs','year','years','pageScope']"
                      [exportConfiguration]="staffTableExcelExportConfig"
                      [pagedData]="staffMembersPagedResponse()!"
                      [staticColumns]="staticColumns"
                      [tableConfig]="FormlyFieldConfigs"
                      [displayNameFormatter]="displayNameFormatter"
                      [query]="query()"
                      [additionalInfoFields]="additionalInfoFields"
                      [isExporting]="isExporting"
                      (exportClicked)="onExportClicked($event)"
                      (pageChanged)="onPageChanged($event)"
                      (resetQuery)="onResetQuery($event)"
                      (sortClicked)="onSortClicked($event)" />
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <div style="padding: 1rem;">
    <p>Loading...</p>
  </div>
</ng-template>