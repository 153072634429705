import { FormlyFieldConfig } from '@ngx-formly/core';

export const StudentTableFilterConfig: { [key: string]: FormlyFieldConfig } = {
    'grades': {
      key: 'grades',
      type: 'select',
      className: 'select-grade',
      props: {
        options: [
          {
            label: 'K',
            value: 'K'
          },
          {
            label: '1',
            value: '1'
          },
          {
            label: '2',
            value: '2'
          },
          {
            label: '3',
            value: '3'
          },
          {
            label: '4',
            value: '4'
          },
          {
            label: '5',
            value: '5'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '7',
            value: '7'
          },
          {
            label: '8',
            value: '8'
          },
          {
            label: '9',
            value: '9'
          },
          {
            label: '10',
            value: '10'
          },
          {
            label: '11',
            value: '11'
          },
          {
            label: '12',
            value: '12'
          }
        ],
        multiple: true,
        label: 'Grade'
      }
    },
    'ssid': {
      key: 'ssid',
      type: 'input',
      props: {
        type: 'text',
        label: 'SSID'
      }
    },
    'nameFirst': {
      key: 'nameFirst',
      type: 'input',
      props: {
        label: 'First Name'
      }
    },
    'nameLast': {
      key: 'nameLast',
      type: 'input',
      props: {
        label: 'Last Name'
      }
    },
    'nameMiddle': {
      key: 'nameMiddle',
      type: 'input',
      props: {
        label: 'Middle Name'
      }
    },
    'dateOfBirth': {
      key: 'dateOfBirth',
      type: 'tdoe-date',
      props: {
        label: 'Date of Birth'
      }
    },
    'district': {
      key: 'district',
      type: 'input'
    },
    'school': {
      key: 'school',
      type: 'input'
    },
    'immigration': {
      key: 'immigration',
      type: 'input'
    },
    'dateFirstEnrolledInPublicSchool': {
      key: 'dateFirstEnrolledInPublicSchool',
      type: 'tdoe-date'
    },
    'yearEntered9thGrade': {
      key: 'yearEntered9thGrade',
      type: 'input'
    },
    'nativeLanguage': {
      key: 'nativeLanguage',
      type: 'select',
      props: {
        options: [
          {
            label: 'English',
            value: 'English'
          },
          {
            label: 'Spanish',
            value: 'Spanish'
          },
          {
            label: 'French',
            value: 'French'
          },
          {
            label: 'Chinese',
            value: 'Chinese'
          }
        ],
        multiple: true
      }
    },
    'elServiceYears': {
      key: 'elServiceYears',
      type: 'input'
    },
    'calculatedElServiceYears': {
      key: 'calculatedElServiceYears',
      type: 'input'
    },
    'englishLanguageBackground': {
      key: 'englishLanguageBackground',
      type: 'select',
      props: {
        options: [
          {
            label: 'Yes',
            value: 'true'
          },
          {
            label: 'No',
            value: 'false'
          }
        ]
      }
    },
    'race': {
      key: 'race',
      type: 'input'
    },
    'enrollmentStartDate': {
      key: 'enrollmentStartDate',
      type: 'tdoe-date'
    },
    'enrollmentCode': {
      key: 'enrollmentCode',
      type: 'input'
    },
    'enrollmentClassification': {
      key: 'enrollmentClassification',
      type: 'input'
    },
    'tos': {
      key: 'tos',
      type: 'input'
    },
    'withdrawalEndDate': {
      key: 'withdrawalEndDate',
      type: 'tdoe-date'
    },
    'withdrawalCode': {
      key: 'withdrawalCode',
      type: 'input'
    },
    'attendanceDate': {
      key: 'attendanceDate',
      type: 'tdoe-date'
    },
    'attendanceType': {
      key: 'attendanceType',
      type: 'select',
      props: {
        options: [
          { label: 'A', value: 'A' },
          { label: 'H', value: 'H' },
          { label: 'I', value: 'I' },
          { label: 'Y', value: 'Y' },
          { label: 'Z', value: 'Z' }
        ],
        multiple: true
      }
    },
    'reportingBeginDate': {
      key: 'reportingBeginDate',
      type: 'tdoe-date'
    },
    'reportingEndDate': {
      key: 'reportingEndDate',
      type: 'tdoe-date'
    },
    'localClassNumber': {
      key: 'localClassNumber',
      type: 'input'
    },
    'courseCode': {
      key: 'courseCode',
      type: 'input'
    },
    'classType': {
      key: 'classType',
      type: 'select',
      props: {
        options: [
          { label: 'C', value: 'C' },
          { label: 'P', value: 'P' },
          { label: 'T', value: 'T' }
        ],
        multiple: true
      }
    },
    'enrollmentPeriodStartDate': {
      key: 'enrollmentPeriodStartDate',
      type: 'tdoe-date'
    },
    'enrollmentPeriodEndDate': {
      key: 'enrollmentPeriodEndDate',
      type: 'tdoe-date'
    },
    'classStartDate': {
      key: 'classStartDate',
      type: 'tdoe-date'
    },
    'enrollmentBeginDate':{
      key: 'enrollmentBeginDate',
      type: 'tdoe-date'
    },
    'enrollmentEndDate':{
      key: 'enrollmentEndDate',
      type: 'tdoe-date'
    },
    'classEndDate':{
      key: 'classEndDate',
      type: 'tdoe-date'
    },
    'assignmentStartDate':{
      key: 'assignmentStartDate',
      type: 'tdoe-date'
    },
    'assignmentEndDate':{
      key: 'assignmentEndDate',
      type: 'tdoe-date'
    },
    'classPeriodName': {
      key: 'classPeriodName',
      type: 'input'
    },
    'classPeriodDuration': {
      key: 'classPeriodDuration',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'classPeriodMeetingDays': {
      key: 'classPeriodMeetingDays',
      type: 'select',
      props: {
        options: [
          {
            label: 'M-F',
            value: 'M-F'
          },
          {
            label: 'M-S',
            value: 'M-S'
          },
          {
            label: 'M-W',
            value: 'M-W'
          },
          {
            label: 'W-F',
            value: 'W-F'
          },
          {
            label: 'T-TH',
            value: 'T-TH'
          }
        ],
        multiple: true
      }
    },
    'classificationCode': {
      key: 'classificationCode',
      type: 'select',
      className: 'select-classification',
      props: {
        multiple: true,
        options: [
          { label: 'ID619', value: 'ID619' },
          { label: '504', value: '504' },
          { label: 'R', value: 'R' },
          { label: 'DYS02', value: 'DYS02' },
          { label: 'DYS03', value: 'DYS03' },
          { label: 'MF', value: 'MF' },
          { label: 'MR', value: 'MR' },
          { label: 'DYS01', value: 'DYS01' },
          { label: 'A', value: 'A' }
        ]
      }
    },
    'classificationStartDate': {
      key: 'classificationStartDate',
      type: 'tdoe-date'
    },
    'classificationEndDate': {
      key: 'classificationEndDate',
      type: 'tdoe-date'
    },
    'creditsEarnedInput': {
      key: 'creditsEarnedInput',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'alphaGradeInput': {
      key: 'alphaGradeInput',
      type: 'select',
      props: {
        multiple: true,
        options: [
          {
            label: 'A+',
            value: 'A+'
          },
          {
            label: 'A',
            value: 'A'
          },
          {
            label: 'A-',
            value: 'A-'
          },
          {
            label: 'B+',
            value: 'B+'
          },
          {
            label: 'B',
            value: 'B'
          },
          {
            label: 'B-',
            value: 'B-'
          },
          {
            label: 'C+',
            value: 'C+'
          },
          {
            label: 'C',
            value: 'C'
          },
          {
            label: 'C-',
            value: 'C-'
          },
          {
            label: 'D+',
            value: 'D+'
          },
          {
            label: 'D',
            value: 'D'
          },
          {
            label: 'D-',
            value: 'D-'
          },
          {
            label: 'F+',
            value: 'F+'
          },
          {
            label: 'F',
            value: 'F'
          },
          {
            label: 'F-',
            value: 'F-'
          }
        ]
      }
    },
    'numericGradeInput': {
      key: 'numericGradeInput',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'classSchoolYear': {
      key: 'classSchoolYear',
      type: 'input'
    },
    'classTerm': {
      key: 'classTerm',
      type: 'select',
      props: {
        options: [
          {
            label: 'Year Round',
            value: 'Year Round'
          },
          {
            label: 'First Quarter',
            value: 'First Quarter'
          },
          {
            label: 'Second Quarter',
            value: 'Second Quarter'
          },
          {
            label: 'Third Quarter',
            value: 'Third Quarter'
          },
          {
            label: 'Fourth Quarter',
            value: 'Fourth Quarter'
          },
          {
            label: 'Fall Semester',
            value: 'Fall Semester'
          },
          {
            label: 'Spring Semester',
            value: 'Spring Semester'
          }
        ],
        multiple: true
      }
    },
    'creditsAttempted': {
      key: 'creditsAttempted',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'qualityPoints': {
      key: 'qualityPoints',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'optionLevel': {
      key: 'optionLevel',
      type: 'input'
    },
    'optionNumberInput': {
      key: 'optionNumberInput',
      type: 'input',
      props: {
        type: 'number'
      }
    },
    'specEdBeginDate': {
      key: 'specEdBeginDate',
      type: 'tdoe-date'
    },
    'specEdEndDate': {
      key: 'specEdEndDate',
      type: 'tdoe-date'
    },
    'specEdDisabilityLevel': {
      key: 'specEdDisabilityLevel',
      type: 'input'
    },
    'specEdDisabilityType': {
      key: 'specEdDisabilityType',
      type: 'input'
    },
    'specEdDisabilityBeginDate': {
      key: 'specEdDisabilityBeginDate',
      type: 'tdoe-date'
    },
    'specEdDisabilityEndDate': {
      key: 'specEdDisabilityEndDate',
      type: 'tdoe-date'
    },
    'standardReportPeriod': {
      key: 'standardReportPeriod',
      type: 'input'

    },
    'standardStudentAdm': {
      key: 'standardStudentAdm',
      type: 'input'
    },
    'standardStudentAda': {
      key: 'standardStudentAda',
      type: 'input'
    },
    'vocationalReportPeriod': {
      key: 'vocationalReportPeriod',
      type: 'input',
    },
    'vocationalProgramItem': {
      key: 'vocationalProgramItem',
      type: 'input',
    },
    'vocationalStudentAdm': {
      key: 'vocationalStudentAdm',
      type: 'input',
    },
    'vocationalStudentAda': {
      key: 'vocationalStudentAda',
      type: 'input',
    },
    'disciplinaryEnrollmentPeriodStart': {
      key: 'disciplinaryEnrollmentPeriodStart',
      type: 'tdoe-date'
    },
    'disciplinaryEnrollmentPeriodEnd': {
      key: 'disciplinaryEnrollmentPeriodEnd',
      type: 'tdoe-date'
    },
    'disciplinaryPeriodBegin': {
      key: 'disciplinaryPeriodBegin',
      type: 'tdoe-date'
    },
    'disciplinaryPeriodEnd': {
      key: 'disciplinaryPeriodEnd',
      type: 'tdoe-date'
    },
    'disciplinaryType': {
      key: 'disciplinaryType',
      type: 'select',
      props: {
        options: [
          { label: 'I', value: 'I' },
          { label: 'R', value: 'R' }
        ]
      }
    },
    'disciplinaryReason': {
      key: 'disciplinaryReason',
      type: 'input'
    },
    'zeroTolerance': {
      key: 'zeroTolerance',
      type: 'select',
      props: {
        options: [
          { label: 'Y', value: 'Y' },
          { label: 'N', value: 'N' }
        ]
      }
    },
    'endOfServiceDate': {
      key: 'endOfServiceDate',
      type: 'tdoe-date'
    },
    'eosAction': {
      key: 'eosAction',
      type: 'select',
      props: {
        options: [
          {
            label: 'Promoted',
            value: 'P'
          },
          {
            label: 'Retained',
            value: 'R'
          },
          {
            label: 'Demoted',
            value: 'D'
          }
        ],
        multiple: true
      }
    },
    'compDocDate': {
      key: 'compDocDate',
      type: 'tdoe-date'
    },
    'compDocType': {
      key: 'compDocType',
      type: 'input'
    },
    'compDocPeriod': {
      key: 'compDocPeriod',
      type: 'select',
      props: {
        options: [
          {
            label: 'Early Grad',
            value: 'EA'
          },
          {
            label: 'Spring Grad',
            value: 'SP'
          },
          {
            label: 'Summer Grad',
            value: 'SU'
          }
        ],
        multiple: true
      }
    },
    'fundingEligibility': {
      key: 'fundingEligibility',
      type: 'select',
      props: {
        options: [
          { label: 'Excluded. Out-of-State', value: '1' },
          { label: 'Excluded',              value: '2' },
          { label: 'Student',              value: 'I20' },
          { label: 'Not Excluded',         value: '0' }
        ],
        multiple: true
      }
    },
    'ineligibilityFundingBeginDate': {
      key: 'ineligibilityFundingBeginDate',
      type: 'tdoe-date'
    },
    'ineligibilityFundingEndDate': {
      key: 'ineligibilityFundingEndDate',
      type: 'tdoe-date'
    }
  };