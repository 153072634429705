<button tdoe-button (click)="onAdditionalInfoClick()">Additional Info</button>

<div class="side-panel" [class.visible]="isPanelVisible">
    <div class="component-header">
        <h3>Additional Info</h3>
        <mat-icon class="close" (click)="onCloseClicked()">close</mat-icon>
    </div>
    <div class="buttons">
        <button tdoe-button (click)="onSaveClicked()">Save</button>
        <button tdoe-button class="light-button" (click)="onResetClicked()">Reset</button>
    </div>
    <div class="scrollable" mat-accordion>
        <ng-container *ngFor="let category of categories$ | async">
            <ng-template [ngTemplateOutlet]="categoryPanel" [ngTemplateOutletContext]="{category: category}"></ng-template>
        </ng-container>
    </div>
</div>

<ng-template #categoryPanel let-category="category">
    <mat-expansion-panel [(expanded)]="category.expanded" >
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{category.name}}
            </mat-panel-title>
            <mat-panel-description>
                <span>
                    <span class="select-all" (click)="onSelectAllClick($event, category)">Select All</span>
                </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="body">
            <ng-container *ngFor="let field of category.fields">
                <button [class.selected]="field.selected" (click)="onFieldClicked(field)">{{field.name}}</button>
            </ng-container>
        </div>
    </mat-expansion-panel>
</ng-template>