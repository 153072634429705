import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { PageScope } from 'app/enums/page-scope';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, of, shareReplay, startWith, Subject, switchMap, tap } from 'rxjs';
import { StudentFilterComponent } from '../shared/student-filter/student-filter.component';
import { StudentTableComponent } from '../shared/student-table/student-table.component';
import { StudentService } from 'app/services/student/student.service';
import { SchoolService } from 'app/services/school/school.service';
import { UserService } from 'app/services/user/user.service';
import { TableDataModel } from 'app/models/table-data-model';
import { Student } from 'app/dto';
import { StudentSearchTerms } from 'app/services/student/student.model';
import { Category } from 'app/services/additional-info/additional-info.model';

@Component({
  selector: 'app-data-lookup-student',
  standalone: true,
  imports: [
    CommonModule,
    ScopeContextFilterComponent,
    StudentFilterComponent,
    StudentTableComponent
  ],
  templateUrl: './data-lookup-student.component.html',
  styleUrl: './data-lookup-student.component.scss'
})
export class DataLookupStudentComponent {
  private readonly _studentTablePaginationSubject$ = new BehaviorSubject({ pageIndex: 0, pageSize: 50} as TableDataModel.Pagination);
  private readonly _studentTableSortingSubject$ = new BehaviorSubject({} as TableDataModel.Sorting);
  private readonly _scopeContextFilterSelectedDataSubject$ = new Subject<ScopeContextFilterModel.SelectedData>();
  private readonly _studentSearchTermsSubject$ = new BehaviorSubject<StudentSearchTerms>({});
  
  private readonly _scopeContextFilterSelectedData$ = this._userProfileService.userProfile$.pipe(
    filter(profile => !!profile),
    switchMap(userProfile =>  this._scopeContextFilterSelectedDataSubject$.pipe(
      startWith({
        pageScope: userProfile.pageScope,
        year: new Date().getFullYear(),
        schoolIds: userProfile?.scopeDetails.schoolIds,
        districtIds: userProfile?.scopeDetails.districtIds
      }),
      distinctUntilChanged((prev, curr) => this.isCurrentScopeFilterSelectedDataEqualToCurrent(prev, curr)),
      map(searchTerms => ({
        sessionState: userProfile,
        ...searchTerms
      })),
      shareReplay(1)
    ))
  );

  private readonly _selectedScopeDescriptor$ = this._scopeContextFilterSelectedData$.pipe(
    switchMap(scopeContextFilterData => {
      switch(scopeContextFilterData.pageScope){
        case PageScope.State: {
          return of(`${scopeContextFilterData.year} - Statewide`);
        }
        case PageScope.District: {
          return this._schoolService.getDistricts(scopeContextFilterData.districtIds).pipe(
            map(districts => districts.flatMap(_ =>`${scopeContextFilterData.year} - ${_.name}`))
          );
        }
        case PageScope.School: {
          return this._schoolService.getSchools(scopeContextFilterData.schoolIds!).pipe(
            map(schools => schools.flatMap(_ => `${scopeContextFilterData.year} - ${_.name}`))
          );
        }
      }
    })
  );
  
  private readonly _studentsPagedResponse$ = combineLatest([
    this._studentSearchTermsSubject$,
    this._studentTablePaginationSubject$,
    this._studentTableSortingSubject$
  ]).pipe(
    debounceTime(300),
    map(([studentSearchTerms, studentPagination, studentSorting]) => ({studentSearchTerms, studentPagination, studentSorting})),
    switchMap(data => 
      this._studentService.getStudentsBySearchTerms(data.studentSearchTerms, data.studentPagination, data.studentSorting).pipe(
        map((studentsPagedResponse: TableDataModel.PagedResponse<Student>) => studentsPagedResponse)
    )),
  );

  protected viewModel$ = combineLatest([
    this._userProfileService.userProfile$.pipe(tap(userProfile => console.debug('userProfile:', userProfile))), 
    this._scopeContextFilterSelectedData$.pipe(tap(scopeContextFilterSelectedData => console.debug('scopeContextFilterSelectedData:', scopeContextFilterSelectedData))), 
    this._studentsPagedResponse$.pipe(tap(studentsPagedResponse => console.debug('studentsPagedResponse:', studentsPagedResponse))), 
    this._selectedScopeDescriptor$.pipe(tap(selectedScopeDescriptor => console.debug('selectedScopeDescriptor:', selectedScopeDescriptor))) ]).pipe(
    map(([
      userProfile, 
      scopeContextFilterSelectedData, 
      studentsPagedResponse, 
      selectedScopeDescriptor]) => ({ 
        userProfile,
        scopeContextFilterSelectedData,
        studentsPagedResponse,
        selectedScopeDescriptor
      })),
    tap(data => console.debug('data:', data)),
    map(data => ({
      userPageScope: PageScope.State,
      userScopeId: 0,
      selectedPageScope: data.scopeContextFilterSelectedData.pageScope,
      selectedYear: data.scopeContextFilterSelectedData.year,
      selectedSchoolId: data.scopeContextFilterSelectedData.schoolIds,
      selectedDistrictId: data.scopeContextFilterSelectedData.districtIds,
      studentData: data.studentsPagedResponse?.data ?? [],
      studentDataTotalRecords: data.studentsPagedResponse?.totalRecords ?? 0,
      selectedScopeDescriptor: data.selectedScopeDescriptor
    })),
    shareReplay(1)
  );

  public additionalInfoFields: Category[] = [
    {
      name: 'Enrollment Info',
      expanded: true,
      fields: [
        {
          name: 'Enrollment start date',
          key: 'enrollmentStartDate',
          selected: false,
          filter: {
            key: 'enrollmentStartDate',
            type: 'datepicker',
            props: {
              label: 'Enrollment start date'
            }
          }
        },
        {
          name: 'Enrollment code',
          key: 'enrollmentCode',
          selected: false,
          filter: {
            key: 'enrollmentCode',
            type: 'input',
            props: {
              label: 'Enrollment code'
            }
          }
        },
        {
          name: 'Enrollment classification',
          key: 'enrollmentClassification',
          selected: false,
          filter: {
            key: 'enrollmentClassification',
            type: 'input',
            props: {
              label: 'Enrollment classification'
            }
          }
        },
        {
          name: 'TOS',
          key: 'tos',
          selected: false,
          filter: {
            key: 'tos',
            type: 'input',
            props: {
              label: 'TOS'
            }
          }
        },
        {
          name: 'Funding eligibility',
          key: 'fundingEligibility',
          selected: false,
          filter: {
            key: 'fundingEligibility',
            type: 'input',
            props: {
              label: 'Funding eligibility'
            }
          }
        },
        {
          name: 'Withdraw',
          key: 'withdraw',
          selected: false,
          filter: {
            key: 'withdraw',
            type: 'input',
            props: {
              label: 'Withdraw'
            }
          }
        },
        {
          name: 'Enrollment start date',
          key: 'enrollmentStartDate',
          selected: false,
          filter: {
            key: 'enrollmentStartDate',
            type: 'input',
            props: {
              label: 'Enrollment start date'
            }
          }
        }
      ]
    },
    {
      name: 'Attendance',
      expanded: true,
      fields: [
        {
          name: 'Attendance Date',
          key: 'attendanceDate',
          selected: false,
          filter: {
            key: 'attendanceDate',
            type: 'datepicker',
            props: {
              label: 'Attendance date'
            }
          },
        },
        {
          name: 'Attendance Type',
          key: 'attendanceType',
          selected: false,
          filter: {
            key: 'attendanceType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'A',
                  value: 'A',
                },
                {
                  label: 'H',
                  value: 'H',
                },
                {
                  label: 'I',
                  value: 'I',
                },
                {
                  label: 'Y',
                  value: 'Y',
                },
                {
                  label: 'Z',
                  value: 'Z',
                },
              ],
              multiple: true,
              label: 'Attendance type'
            },
          },
        },
        {
          name: 'Label',
          key: 'label',
          selected: false,
          filter: {
            key: 'label',
            type: 'input',
            props: {
              type: 'text',
              label: 'Label'
            },
          },
        },
      ],
    },
    {
      name: 'Class Assignment',
      expanded: true,
      fields: [
        {
          name: 'Local Class Number',
          key: 'localClassNumber',
          selected: false,
          filter: {
            key: 'localClassNumber',
            type: 'input',
            props: {
              type: 'text',
              label: 'Local class number'
            }
          }
        },
        {
          name: 'Course Code',
          key: 'courseCode',
          selected: false,
          filter: {
            key: 'courseCode',
            type: 'input',
            props: {
              type: 'text',
              label: 'Course code'
            }
          }
        },
        {
          name: 'Class Type',
          key: 'classType',
          selected: false,
          filter: {
            key: 'classType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'C',
                  value: 'C'
                },
                {
                  label: 'P',
                  value: 'P'
                },
                {
                  label: 'T',
                  value: 'T'
                }
              ],
              multiple: true,
              label: 'Class type'
            }
          }
        },
        {
          name: 'Voc. Outside IP',
          key: 'vocOutsideIP',
          selected: false,
          filter: {
            key: 'vocOutsideIP',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'Voc. Outside IP'
            }
          }
        },
        {
          name: 'Enrolment Period Start Date',
          key: 'enrolmentPeriodStartDate',
          selected: false,
          filter: {
            key: 'enrolmentPeriodStartDate',
            type: 'datepicker',
            props: {
              label: 'Enrolment period start date'
            }
          }
        },
        {
          name: 'Enrolment Period End Date',
          key: 'enrolmentPeriodEndDate',
          selected: false,
          filter: {
            key: 'enrolmentPeriodEndDate',
            type: 'datepicker',
            props: {
              label: 'Enrolment period end date'
            }
          }
        },
        {
          name: 'Assignment Start Dates',
          key: 'assignmentStartDates',
          selected: false,
          filter: {
            key: 'assignmentStartDates',
            type: 'datepicker',
            props: {
              label: 'Assignment start dates'
            }
          }
        },
        {
          name: 'Assignment End Dates',
          key: 'assignmentEndDates',
          selected: false,
          filter: {
            key: 'assignmentEndDates',
            type: 'datepicker',
            props: {
              label: 'Assignment end dates'
            }
          }
        },
        {
          name: 'Class Start Dates',
          key: 'classStartDates',
          selected: false,
          filter: {
            key: 'classStartDates',
            type: 'datepicker',
            props: {
              label: 'Class start dates'
            }
          }
        },
      ],
    },
    {
      name: 'Classification',
      expanded: false,
      fields: [
        {
          name: 'Classification',
          key: 'classification',
          selected: false,
          filter: {
            key: 'classification',
            type: 'select',
            props: {
              multiple: true,
              options: [
                {
                  label: 'ID619',
                  value: 'ID619'
                },
                {
                  label: '504',
                  value: '504'
                },
                {
                  label: 'R',
                  value: 'R'
                },
                {
                  label: 'DYS02',
                  value: 'DYS02'
                },
                {
                  label: 'DYS03',
                  value: 'DYS03'
                },
                {
                  label: 'MF',
                  value: 'MF'
                },
                {
                  label: 'MR',
                  value: 'MR'
                },
                {
                  label: 'DYS01',
                  value: 'DYS01'
                },
                {
                  label: 'A',
                  value: 'A'
                }
              ],
              label: 'Classification'
            }
          }
        },
        {
          name: 'Begin Date',
          key: 'beginDate',
          selected: false,
          filter: {
            key: 'beginDate',
            type: 'datepicker',
            props: {
              label: 'Classification begin date'
            }
          }
        },
        {
          name: 'End Date',
          key: 'classificationEndDate',
          selected: false,
          filter: {
            key: 'endDate',
            type: 'datepicker',
            props: {
              label: 'Classification end date'
            }
          }
        }
      ],
    },
    {
      name: 'Final grade',
      expanded: false,
      fields: [
        {
          name: 'Local class number',
          key: 'localClassNumber',
          selected: false,
          filter: {
            key: 'localClassNumber',
            type: 'input',
            props: {
              label: 'Local class number'
            }
          }
        },
        {
          name: 'Course code',
          key: 'courseCode',
          selected: false,
          filter: {
            key: 'courseCode',
            type: 'input',
            props: {
              label: 'Course code'
            }
          }
        },
        {
          name: 'Credits earned',
          key: 'crediteEarned',
          selected: false,
          filter: {
            key: 'crediteEarned',
            type: 'input',
            props: {
              label: 'Credits earned',
              type: 'number'
            }
          }
        },
        {
          name: 'Credits earned',
          key: 'creditsEarned',
          selected: false,
          filter: {
            key: 'creditsEarned',
            type: 'input',
            props: {
              label: 'Credits earned',
              type: 'number'
            }
          }
        },
        {
          name: 'Numeric grade',
          key: 'numericGrade',
          selected: false,
          filter: {
            key: 'numericGrade',
            type: 'input',
            props: {
              label: 'Numeric grade',
              type: 'number'
            }
          }
        }
      ]
    },
    {
      name: 'Special Ed Options',
      expanded: false,
      fields: [
        {
          name: 'Option level',
          key: 'optionLevel',
          selected: false,
          filter: {
            key: 'optionLevel',
            type: 'input',
            props: {
              label: 'Option level'
            }
          }
        },
        {
          name: 'Option number',
          key: 'optionNumber',
          selected: false,
          filter: {
            key: 'optionNumber',
            type: 'input',
            props: {
              label: 'Option number',
              type: 'number'
            }
          }
        },
        {
          name: 'Begin date',
          key: 'specEdBeginDate',
          selected: false,
          filter: {
            key: 'specEdBeginDate',
            type: 'datepicker',
            props: {
              label: 'Begin date'
            }
          }
        },
        {
          name: 'End date',
          key: 'specEdEndDate',
          selected: false,
          filter: {
            key: 'specEdEndDate',
            type: 'datepicker',
            props: {
              label: 'End date'
            }
          }
        }
      ]
    },
    {
      name: 'Special Ed Disabilities',
      expanded: false,
      fields: [
        {
          name: 'Disability level',
          key: 'specEdDisabilityLevel',
          selected: false,
          filter: {
            key: 'specEdDisabilityLevel',
            type: 'input',
            props: {
              label: 'Disability level'
            }
          }
        },
        {
          name: 'Disability type',
          key: 'specEdDisabilityType',
          selected: false,
          filter: {
            key: 'specEdDisabilityType',
            type: 'input',
            props: {
              label: 'Disability type'
            }
          }
        },
        {
          name: 'Begin date',
          key: 'specEdDisabilityBeginDate',
          selected: false,
          filter: {
            key: 'specEdDisabilityBeginDate',
            type: 'datepicker',
            props: {
              label: 'Begin date'
            }
          }
        },
        {
          name: 'End date',
          key: 'specEdDisabilityEndDate',
          selected: false,
          filter: {
            key: 'specEdDisabilityEndDate',
            type: 'datepicker',
            props: {
              label: 'End date'
            }
          }
        }
      ]
    },
    {
      name: 'Standard AMA/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report period',
          key: 'standardReportPeriod',
          selected: false,
          filter: {
            key: 'standardReportPeriod',
            props: {
              label: 'Report period'
            }
          }
        },
        {
          name: 'Student ADM',
          key: 'standardStudentAdm',
          selected: false,
          filter: {
            key: 'standardStudentAdm',
            props: {
              label: 'Student ADM'
            }
          }
        },
        {
          name: 'Student ADA',
          key: 'standardStudentAda',
          selected: false,
          filter: {
            key: 'standardStudentAda',
            props: {
              label: 'Student ADA'
            }
          }
        }
      ]
    },
    {
      name: 'Vocational ADM/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report period',
          key: 'vocationalReportPeriod',
          selected: false,
          filter: {
            key: 'vocationalReportPeriod',
            props: {
              label: 'Report period'
            }
          }
        },
        {
          name: 'Program item',
          key: 'vocationalProgramItem',
          selected: false,
          filter: {
            key: 'vocationalProgramItem',
            props: {
              label: 'Program item'
            }
          }
        },
        {
          name: 'Student ADM',
          key: 'vocationalStudentAdm',
          selected: false,
          filter: {
            key: 'vocationalStudentAdm',
            props: {
              label: 'Student ADM'
            }
          }
        },
        {
          name: 'Student ADA',
          key: 'vocationalStudentAda',
          selected: false,
          filter: {
            key: 'vocationalStudentAda',
            props: {
              label: 'Student ADA'
            }
          }
        }
      ]
    },
    {
      name: 'Club Membership',
      expanded: false,
      fields: [
        {
          name: 'Club ID',
          key: 'clubID',
          selected: false,
          filter: {
            key: 'clubID',
            type: 'input',
            props: {
              type: 'text',
              label: 'Club ID'
            }
          }
        },
        {
          name: 'Begin Date',
          key: 'clubBeginDate',
          selected: false,
          filter: {
            key: 'beginDate',
            type: 'datepicker',
            props: {
              label: 'Club begin date'
            }
          }
        },
        {
          name: 'End Date',
          key: 'clubEndDate',
          selected: false,
          filter: {
            key: 'endDate',
            type: 'datepicker',
            props: {
              label: 'Club end date'
            }
          }
        },
      ],
    },
    {
      name: 'Disciplinary Actions',
      expanded: false,
      fields: [
        {
          name: 'Enrollment Period Start',
          key: 'enrollmentPeriodStart',
          selected: false,
          filter: {
            key: 'enrollmentPeriodStart',
            type: 'datepicker',
            props: {
              label: 'Enrollment period start'
            }
          }
        },
        {
          name: 'Enrollment Period End',
          key: 'enrollmentPeriodEnd',
          selected: false,
          filter: {
            key: 'enrollmentPeriodEnd',
            type: 'datepicker',
            props: {
              label: 'Enrollment period end'
            }
          }
        },
        {
          name: 'Disciplinary Period Begin',
          key: 'disciplinaryPeriodBegin',
          selected: false,
          filter: {
            key: 'disciplinaryPeriodBegin',
            type: 'datepicker',
            props: {
              label: 'Disciplinary period begin'
            }
          }
        },
        {
          name: 'Disciplinary Period End',
          key: 'disciplinaryPeriodEnd',
          selected: false,
          filter: {
            key: 'disciplinaryPeriodEnd',
            type: 'datepicker',
            props: {
              label: 'Disciplinary period end'
            }
          }
        },
        {
          name: 'Disciplinary Type',
          key: 'disciplinaryType',
          selected: false,
          filter: {
            key: 'disciplinaryType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'I',
                  value: 'I'
                },
                {
                  label: 'R',
                  value: 'R'
                }
              ],
              multiple: true,
              label: 'Disciplinary type'
            }
          }
        },
        {
          name: 'Disciplinary Reason',
          key: 'disciplinaryReason',
          selected: false,
          filter: {
            key: 'disciplinaryReason',
            type: 'input',
            props: {
              type: 'text',
              label: 'Disciplinary reason'
            }
          }
        },
        {
          name: 'Sped',
          key: 'sped',
          selected: false,
          filter: {
            key: 'sped',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'SPED'
            }
          }
        },
        {
          name: 'Zero Tolerance',
          key: 'zeroTolerance',
          selected: false,
          filter: {
            key: 'zeroTolerance',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'Zero tolerance'
            }
          }
        },
      ],
    }
  ];

  public constructor (
    private readonly _userProfileService: UserService,
    private readonly _studentService: StudentService,
    private readonly _schoolService: SchoolService
  ) {}

  //Clark: move closer to ScopeContextFilterModel.SelectedData so it can be reused
  private isCurrentScopeFilterSelectedDataEqualToCurrent(previous: ScopeContextFilterModel.SelectedData, current: ScopeContextFilterModel.SelectedData): boolean {
    console.debug('onStudentFilterSearchClicked -> isCurrentScopeFilterSelectedDataEqualToCurrent:', previous, current);
    return previous.year === current.year
      && previous.districtIds === current.districtIds
      && previous.schoolIds === current.schoolIds;
  }

  //Clark: move closer to StudentModel.StudentSearchTerms so it can be reused
  private isPreviousStudentSearchTermsEqualToCurrent(previous: StudentSearchTerms, current: StudentSearchTerms): boolean {
    console.debug('onStudentFilterSearchClicked -> isPreviousStudentSearchTermsEqualToCurrent:', previous, current);
    return previous?.year === current?.year
      && previous?.districtIds === current?.districtIds
      && previous?.schoolIds === current?.schoolIds
      && previous?.ssid === current?.ssid
      && previous?.serviceType == current?.serviceType
      && previous?.grade == current?.grade
      && previous?.middleName == current?.middleName
      && previous?.firstName == current?.firstName
      && previous?.lastName == current?.lastName
      && previous?.dateOfBirth == current?.dateOfBirth;
  }

  protected onStudentTableSortClicked(sorting: TableDataModel.Sorting): void {
    this._studentTableSortingSubject$.next(sorting);
  }

  protected onStudentTablePageChanged(pagination: TableDataModel.Pagination): void {
    this._studentTablePaginationSubject$.next(pagination);
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    console.debug('onContextFilterSearchClicked:', selectedScopeContextFilterData);
    this._scopeContextFilterSelectedDataSubject$.next(selectedScopeContextFilterData);
  }

  protected onStudentFilterSearchClicked(studentSearchTerms: StudentSearchTerms): void {
    console.debug('onStudentFilterSearchClicked -> studentSearchTerms:', studentSearchTerms);
    this._studentSearchTermsSubject$.next(studentSearchTerms);
  }

  protected onStudentFilterResetClicked(): void {
    this._studentSearchTermsSubject$.next({});
  }
}
