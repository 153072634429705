/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Student final grade
 */
export interface StudentFinalGrade { 
    /**
     * The year class was taken
     */
    classSchoolYear?: number;
    /**
     * The term class was taken
     */
    classTerm?: string;
    /**
     * Credits attempted
     */
    creditsAttempted?: number;
    /**
     * Quality points
     */
    qualityPoints?: number;
}