<div class="content-wrapper">
@if (!pagedData()) {
    <div class="skeleton-buttons">
        <ngx-skeleton-loader [theme]="{ 'width':'30%', 'min-width': '9rem','height': '2.5rem' }" count="3" appearance="line"/>
    </div>
    <ngx-skeleton-loader [theme]="{ 'height': '2rem' }" count="1" appearance="line"/>
    <ngx-skeleton-loader count="25" appearance="line"/>
} @else {
    <div class="header">
        @if (filterChipItems() && filterChipItems().length > 0) {
            <tdoe-chips
            [chips]="filterChipItems()"
            (onChipRemove)="filterChipItemRemoved($event)">
          </tdoe-chips>
        } @else {
            <h3></h3>
        }
  
        <div class="controls">
          <div class="buttons">
            @if (additionalInfoFields() && additionalInfoFields().length > 0) {
              <app-additional-info style="margin-right:15px;"
                [additionalInfoFields]="additionalInfoFields()"
                [contextKey]="additionalInfoContextKey()"
                (fieldSelected)="onAdditionalInfoSelectionChanged($event)"/>
            }

            <button tdoe-button size="large" (click)="onResetFiltersClick()">
              Reset Filters
            </button>
            @if (isExporting()) {
              <div class="progress-bar">
                <div class="progress-text">Exporting...</div>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </div>
            } @else {
              <button tdoe-button size="large" [matMenuTriggerFor]="menu">
                Export
              </button>
            }
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="onExportToExcelClicked('csv')">CSV</button>
              <button mat-menu-item (click)="onExportToExcelClicked('xlsx')">
                Excel
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
  
      <div class="table-title">
        <span [innerText]="tableHeading()"></span>
      </div>
  
      <div class="scrollable-container">
        <mat-table matSort (matSortChange)="onSortChanged($event)" [dataSource]="pagedData().data!">

            @for (column of staticColumns();track column) {
                <ng-container [matColumnDef]="column.key">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        @if (enableColumnFilters()) {
                            <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                                <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('courseType')" [model]="query()"></formly-form>
                            </form>
                        } @else {
                            <span [innerText]="column.label"></span>
                        }
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">{{resolveNestedProperty(item, column.key)}}</mat-cell>
                </ng-container>
            }
    
            @if (dynamicColumns().length > 0) {
                @for (col of dynamicColumns(); track col)
                {
                    <ng-container [matColumnDef]="col.key">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            @if (enableColumnFilters()) {
                                <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                                    <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig(col.key)" [model]="query()"></formly-form>
                                </form>
                            } @else {
                                <span [innerText]="col.name"></span>
                            }
                        </mat-header-cell>
                        <mat-cell *matCellDef="let item"> {{resolveNestedValue(item, col)}} </mat-cell>
                    </ng-container>
                }
            }
    
            <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
            <mat-row *matRowDef="let item; columns: displayedColumns()" [routerLink]="[clickRoute(), item[identifierPath()]]" [queryParams]="{ name: displayNameFormatter(item) }" [state]="getRouteState(item)"></mat-row>
    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching applied filters</td>
            </tr>
        </mat-table>
      </div>
      <mat-paginator
        [length]="pagedData().totalRecords"
        [pageSize]="pagedData().pageSize"
        [pageSizeOptions]="[25, 50, 100, 500, 1000]"
        [pageIndex]="pagedData().pageIndex"
        showFirstLastButtons
        (page)="onPageChanged($event)">
  </mat-paginator>
}
</div>