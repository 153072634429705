import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { ErrorLogService } from 'app/services/error-log/error-log.service';
import { Observable, combineLatest, filter, map, startWith, switchMap} from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { ErrorCategoryTab } from 'app/models';
import { UserService } from 'app/services/user/user.service';
import { LoggingService } from '@tdoe/design-system';

@Component({
  selector: 'app-error-category-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-category-tabs.component.html',
  styleUrl: './error-category-tabs.component.scss'
})
export class ErrorCategoryTabsComponent {

  public errorCategoryTabSelected = output<string>();

  private selectedTabName$: Observable<string> = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.getCurrentPageName(this._activatedRoute.snapshot)),
      startWith(this.getCurrentPageName(this._activatedRoute.snapshot))
    );

  public viewModel$ = combineLatest([
    this.selectedTabName$,
    this.userService.userProfile$]
  ).pipe(map(([tabName, profile]) => ({ tabName, profile })))
   .pipe(filter(data => !!data.profile))
   .pipe(switchMap(data => this._errorLogService.getCounts(
      data.profile?.getScopeIds('district'),
      data.profile?.getScopeIds('school'),
      data.profile?.scopeDetails.years
   )))
   .pipe(map(data => ({
      tabs: data.map(_ => ({
        selected: _.name === this.getCurrentPageName(this._activatedRoute.snapshot),
        name: _.name ?? '',
        count: _.count?.toString() ?? '0'
      } as ErrorCategoryTab))
   })));

  public constructor (
    private _errorLogService: ErrorLogService,
    private _router: Router, 
    private _activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly logger: LoggingService
  ) {
    //
  }

  protected onTabClick(selectedTab: ErrorCategoryTab): void {
    this.logger.debug('ErrorCategoryTabsComponent -> onTabClick', { data: { selectedTab } });
    this.errorCategoryTabSelected.emit(selectedTab.name.toLowerCase());
  }

  private getCurrentPageName (activatedRoute: ActivatedRouteSnapshot): string {
    return this.getLastChild(activatedRoute).routeConfig?.path ?? '';
  }

  private getLastChild(activatedRoute: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if(activatedRoute.children.length > 0 && activatedRoute.firstChild) {
      return this.getLastChild(activatedRoute.firstChild);
    }
    return activatedRoute;
  }

}
