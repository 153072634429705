/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DayOfWeek } from './dayOfWeek';

export interface ClassPeriod { 
    /**
     * Meeting days
     */
    meetingDays?: Array<DayOfWeek>;
    /**
     * Period duration
     */
    periodDuration?: string;
    /**
     * Period name
     */
    periodName?: string;
}