import { NavItem } from '@tdoe/design-system';
import { District, School } from './dto';

export class TypeGuards {
  public static isNavItem(obj: unknown): obj is NavItem {
    return typeof obj === 'object' &&
           obj !== null &&
           'id' in obj && typeof (obj as NavItem).id === 'string' &&
           'text' in obj && typeof (obj as NavItem).text === 'string';
  }

  public static isDistrict(obj: unknown): obj is District {
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }
    const candidate = obj as District;
    if (candidate.districtNumber == null || candidate.name == null) {
      return false;
    }
    if ('schoolId' in candidate && candidate.schoolId != null) {
      return false;
    }
    if ('schoolNumber' in candidate && candidate.schoolNumber != null) {
      return false;
    }
    return true;
  }

  public static isSchool(obj: unknown): obj is School {
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }
    const candidate = obj as School;
    if (candidate.districtNumber == null || candidate.name == null) {
      return false;
    }
    if (candidate.schoolId == null || candidate.schoolNumber == null) {
      return false;
    }
    return true;
  }
}