import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoggingService, TdoeButtonDirective } from '@tdoe/design-system';
import { ClassDetail } from 'app/dto';
import { ClassService } from 'app/services/class/class.service';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, tap } from 'rxjs';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { AdditionalInfoModel, Category } from 'app/services/additional-info/additional-info.model';
import { MatExpansionModule } from '@angular/material/expansion';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DayOfWeek } from 'app/models/dayOfWeek';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { Title } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { TdoeBoolDisplayPipe } from 'app/pipes/bool-display/tdoe-bool-display.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { downloadExcelDocument } from 'app/utilities/excel-export-utilities/excel-export-utilities';
import { MatProgressBarModule } from '@angular/material/progress-bar';
@Component({
  selector: 'app-class-view',
  standalone: true,
  imports: [
    MatIconModule,
    AdditionalInfoComponent,
    MatExpansionModule,
    DatePipe,
    MatButtonToggleModule,
    AsyncPipe,
    CommonModule,
    RouterModule,
    TdoeButtonDirective,
    MatTableModule,
    TdoeBoolDisplayPipe, 
    MatMenuModule,
    MatProgressBarModule,
  ],
  templateUrl: './class-view.component.html',
  styleUrl: './class-view.component.scss'
})
export class ClassViewComponent implements OnChanges, OnInit {

  // #region Inputs
  @Input() public set classDetail(value: ClassDetail | undefined) {
    this.logger.debug('ClassViewComponent -> classDetail', { data: { value }});
    this.class$.next(value);
  }
  public get classDetail(): ClassDetail | undefined {
    return this.class$.getValue();
  }

  @Input() public classId!: string;
  // #endregion

  public AdditionalInfoContextKey = 'ClassViewComponent';
  public isExporting: boolean = false;

  private readonly categoriesSubject = new BehaviorSubject<AdditionalInfoModel.Category[]>([]);
  private readonly categories$: Observable<AdditionalInfoModel.Category[]> = this.categoriesSubject.asObservable();
  private readonly class$ = new BehaviorSubject<ClassDetail | undefined>(undefined);

  // #region D.I.
  private readonly classService = inject(ClassService);
  private readonly logger = inject(LoggingService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly titleService = inject(Title);
  // #endregion

  public daysOfWeek = DayOfWeek;
  public readonly breadcrumbData: any;

  public resolver(obj: any, key: string): any {
    return ObjectUtilities.getNestedProperty(obj, key);
  }

  public additionalInfoFields: Category[] = [
    {
      name: 'Student',
      expanded: true,
      fields: [
        {
          name: 'Student Last',
          key: 'nameLast',
          selected: false,
          exportColumn: {
            jsonPath: '$.NameLast'
          }
        },
        {
          name: 'Student First',
          key: 'nameFirst',
          selected: false,
          exportColumn: {
            jsonPath: '$.NameFirst'
          }
        },
        {
          name: 'Student Middle',
          key: 'nameMiddle',
          selected: false,
          exportColumn: {
            jsonPath: '$.NameMiddle'
          }
        },
        {
          name: 'State ID',
          key: 'ssid',
          selected: false,
          exportColumn: {
            jsonPath: '$.SSID'
          }
        },
        {
          name: 'Grade',
          key: 'grade',
          selected: false,
          exportColumn: {
            jsonPath: '$.Grade'
          }
        },
        {
          name: 'Date of Birth',
          key: 'dateOfBirth',
          selected: false,
          exportColumn: {
            jsonPath: '$.DateOfBirth'
          }
        },
        {
          name: 'Student Class Assignment Begin Date',
          key: 'assignmentEnrollmentPeriodStartDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentEnrollmentPeriodStartDate'
          }
        },
        {
          name: 'Student Class Assignment End Date',
          key: 'assignmentEnrollmentPeriodEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentEnrollmentPeriodEndDate'
          }
        }
      ],
      valuePath: '$.additionalInfo.Students'
    },
    {
      name: 'Staff',
      expanded: true,
      fields: [
        {
          name: 'Staff Last',
          key: '_nameLast_',
          selected: false,
          exportColumn: {
            jsonPath: '$.NameLast'
          }
        },
        {
          name: 'Staff First',
          key: '_nameFirst_',
          selected: false,
          exportColumn: {
            jsonPath: '$.NameFirst'
          }
        },
        {
          name: 'Staff Middle',
          key: '_nameMiddle_',
          selected: false,
          exportColumn: {
            jsonPath: '$.NameMiddle'
          }
        },
        {
          name: 'Staff TLN',
          key: 'teacherLicenseNumber',
          selected: false,
          exportColumn: {
            jsonPath: '$.TeacherLicenseNumber'
          }
        },
        {
          name: 'Teacher of Record',
          key: 'teacherOfRecord',
          selected: false,
          exportColumn: {
            jsonPath: '$.TeacherLicenseNumber'
          }
        },
        {
          name: 'Staff Class Assignment Begin Date',
          key: 'assignmentBeginDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentBeginDate'
          }
        },
        {
          name: 'Staff Class Assignment End Date',
          key: 'assignmentEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentEndDate'
          }
        },
        {
          name: 'Staff Organization Email',
          key: 'email',
          selected: false,
          exportColumn: {
            jsonPath: '$.email'
          }
        }
      ],
      valuePath: '$.additionalInfo.Staff'
    }
  ];

  private readonly selectedCategories: Observable<AdditionalInfoModel.Category[]> =
    combineLatest([this.categories$])
      .pipe(
        map(([categories]) => {
          return categories
            .filter(category => category.fields.some(field => field.selected))
            .map(category => ({
              ...category,
              fields: category.fields
                .filter(field => field.selected)
                .map(field => ({
                  ...field
                }))
            }));
        }),
        tap(_ => this.logger.debug('ClassViewComponent -> selectedCategories', { data: {
          _
        } }))
      );
    
  public state: { [k: string]: any; } | undefined;

  public readonly viewModel$ = combineLatest([this.selectedCategories, this.class$.asObservable()])
    .pipe(
      map(([selectedCategories, classDetail]) => ({ selectedCategories, classDetail })),
      map(data => ({
        selectedCategories: data.selectedCategories,
        classDetail: data.classDetail,
        staffColumns: this.getCategory(data.selectedCategories, 'Staff')?.fields.map(_ => this.strip(_.key)),
        studentColumns: this.getCategory(data.selectedCategories, 'Student')?.fields.map(_ => this.strip(_.key)),
        staffCategory: this.getCategory(data.selectedCategories, 'Staff'),
        studentCategory: this.getCategory(data.selectedCategories, 'Student')
      })),
      tap(_ => this.logger.debug('ClassViewComponent -> viewModel$', { data: { _ } }))
    );

  public getCategory(categories: Category[], categoryName: string): Category | undefined {
    return categories.find(_ => _.name === categoryName && _.fields.some(field => field.selected));
  }

  public strip(val: string): string {
    return val.replace(new RegExp(/_/, 'g'), '');
  }

  public constructor() {
    this.state = this.router.getCurrentNavigation()?.extras.state;
  }

  private async loadClassDetail(classId: string): Promise<void> {
    const classDetail = await firstValueFrom(this.classService.getClass(classId));
    this.logger.debug('ClassViewComponent -> loadClassDetail', {
      data: {
        classId,
        classDetail
      }
    });
    this.class$.next(classDetail);
    this.titleService.setTitle(`${this.titleService.getTitle()} - ${(classDetail as ClassDetail)?.courseName}`);
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {

    if (changes['classId']?.currentValue) {
      this.loadClassDetail(this.classId);
    }

  }

  public async ngOnInit(): Promise<void> {
    // Do not continue initializing if input parameter is provided by a parent control, route data or injection
    if (this.classDetail) {
      this.titleService.setTitle(`${this.titleService.getTitle()} - ${(this.classDetail as ClassDetail)?.courseName}`);
      Promise.resolve();
      return;
    }

    this.logger.debug('ClassViewComponent -> ngOnInit -> Attempt load');

    // Check route params for class ID
    const params = await firstValueFrom(this.route.params);
    if (params) {
      const classId: string = params['id'];
      if (classId) {
        // Request class details from service API
        this.loadClassDetail(classId);
        Promise.resolve();
      }
    }

    
  }

  public onFieldSelected(e: Category[]): void {
    this.logger.debug('ClassViewComponent -> onFieldSelected', { data: { e } });
    this.categoriesSubject.next(e);
  }

  public async export(id: string, additonalInfo?: Category[]): Promise<void> {
    this.isExporting = true;
    try {
      this.logger.debug('ClassViewComponent -> export', {
        data: { id, additonalInfo }
      });
      const file = await firstValueFrom(this.classService.exportPdf(id, additonalInfo));
      downloadExcelDocument(file);
    } finally {
      this.isExporting = false;
    }
  }

}
