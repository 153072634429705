<div class="container header-title">
  <div class="container">
    <h1>School Details</h1>
  </div>
</div>

<div class="container" *ngIf="viewModel$ | async as vm">
  <div class="container" *ngIf="vm.school as school">
    <div class="header">
      <div class="back-link">
        <a [routerLink]="['/data-lookup', 'school']" [state]="state"
          ><mat-icon>arrow_back</mat-icon> Back to School List</a
        >
      </div>
      <div class="buttons">
        <app-additional-info
          [additionalInfoFields]="additionalInfoFields"
          [contextKey]="'SchoolViewComponent'"
          (fieldSelected)="onFieldSelected($event)"
        />
        <ng-container *ngIf="!isExporting; else loadingExport">
          <button
            tdoe-button
            size="large"
            (click)="export(school.schoolId!, vm.selectedAdditionalInfoFields, vm.calendarNumber, vm.reportingPeriod?.periodNumber)"
          >
            Export
          </button>
        </ng-container>
        <ng-template #loadingExport>
          <div class="progress-bar">
            <div class="progress-text">Exporting...</div>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="mat-accordion" mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> School Directory Info </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="data-list">
          <div class="field name-identity">
            <span class="name">School Name and Number</span>
            <span class="value" [innerText]="school.display"></span>
          </div>
          <div class="field">
            <span class="name">Principal Name</span>
            <span
              class="value"
              [innerText]="
                school.additionalInformation?.directoryInfo?.principalName
              "
            ></span>
          </div>
          <div class="field">
            <span class="name">Principal Email Address</span>
            <span
              class="value"
              [innerText]="
                school.additionalInformation?.directoryInfo
                  ?.principalEmailAddress
              "
            ></span>
          </div>
          <div class="field">
            <span class="name">Office Number</span>
            <span
              class="value"
              [innerText]="
                school.additionalInformation?.directoryInfo?.officeNumber
              "
            ></span>
          </div>
          <div class="field">
            <span class="name">Address</span>
            <span
              class="value"
              [innerText]="school.additionalInformation?.directoryInfo?.address"
            ></span>
          </div>
          <div class="field">
            <span class="name">City</span>
            <span
              class="value"
              [innerText]="school.additionalInformation?.directoryInfo?.city"
            ></span>
          </div>
          <div class="field">
            <span class="name">State / Zip Code</span>
            <span class="value" [innerText]="school.stateZip"></span>
          </div>
          <div class="field">
            <span class="name">Grade</span>
            <span
              class="value"
              [innerText]="school.additionalInformation?.directoryInfo?.grade"
            ></span>
          </div>
          <div class="field">
            <span class="name">Programs</span>
            <span
              class="value"
              [innerText]="
                school.additionalInformation?.directoryInfo?.programs
              "
            ></span>
          </div>
          <div class="field">
            <span class="name">Web Address</span>
            <span
              class="value"
              [innerText]="
                school.additionalInformation?.directoryInfo?.webAddress
              "
            ></span>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="mat-accordion" mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> School Calendar Info </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-tab-group
          (selectedTabChange)="onTabSelected(vm.school, $event.index)"
        >
          @for (calendarNumber of school.additionalInformation?.calendarNumber;
          track calendarNumber) {
          <mat-tab [label]="'Calendar ' + calendarNumber">
            <tdoe-card>
              <div
                class="kpi-card-wrap"
                *ngIf="vm.calendar as overview"
                content
              >
                <div class="kpi-tiles">
                  @for (metric of overview.calendarEvents;track metric;let last
                  = $last) {
                  <tdoe-kpi-card
                    [metricValue]="metric.numberOfDays!.toString()"
                    [metricName]="metric.description!"
                  />
                  }
                </div>
              </div>
              <div *ngIf="vm.calendar.reportingPeriods as periods" content>
                <div class="table-header">Reporting Periods</div>
                <mat-table class="selectable-table" [dataSource]="periods">
                  <ng-container matColumnDef="periodNumber">
                    <mat-header-cell *matHeaderCellDef
                      >Period Number</mat-header-cell
                    >
                    <mat-cell *matCellDef="let period"
                      >Period {{ period.periodNumber }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="beginDate">
                    <mat-header-cell *matHeaderCellDef
                      >Begin Date</mat-header-cell
                    >
                    <mat-cell *matCellDef="let period">{{
                      period.beginDate
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="endDate">
                    <mat-header-cell *matHeaderCellDef
                      >End Date</mat-header-cell
                    >
                    <mat-cell *matCellDef="let period">{{
                      period.endDate
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef
                      >Total Days</mat-footer-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="numberOfDays">
                    <mat-header-cell *matHeaderCellDef
                      >Number of Days</mat-header-cell
                    >
                    <mat-cell *matCellDef="let period">{{
                      period.numberOfDays
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>{{
                      vm.totalPeriodDays
                    }}</mat-footer-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="vm.reportingPeriodDisplayedColumns"
                  />
                  <mat-row
                    [class.tdoe-selected]="
                      period.periodNumber === vm.reportingPeriod?.periodNumber
                    "
                    *matRowDef="
                      let period;
                      columns: vm.reportingPeriodDisplayedColumns
                    "
                    (click)="onReportingPeriodRowClicked(period)"
                  />
                  <mat-footer-row
                    *matFooterRowDef="vm.reportingPeriodDisplayedColumns"
                  />

                  <!-- Row shown when there is no matching data. -->
                  <tr *matNoDataRow>
                    <td colspan="4">No reporting periods assigned</td>
                  </tr>
                </mat-table>
              </div>
              @if(vm.reportingPeriod && vm.reportingPeriod.calendarDays) {
              <div content>
                <div class="table-header">Calendar Days</div>
                <div class="toggler-wrap">
                  <h3>
                    Reporting Period {{ vm.reportingPeriod.periodNumber }}
                  </h3>
                  <mat-button-toggle-group
                    [value]="vm.calendarView"
                    (change)="toggleCalendarView($event)"
                  >
                    <mat-button-toggle value="list">List</mat-button-toggle>
                    <mat-button-toggle value="calendar"
                      >Calendar</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
                @if (vm.calendarView === 'calendar') {
                <app-calendar-days
                  [reportingPeriod]="vm.reportingPeriod"
                  [resolver]="getEventTypeDisplay"
                ></app-calendar-days>
                } @else {
                <mat-table
                  class="selectable-table"
                  [dataSource]="vm.reportingPeriod.calendarDays"
                >
                  <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef
                      >Event</mat-header-cell
                    >
                    <mat-cell *matCellDef="let day">{{
                      day.description
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="calendarDate">
                    <mat-header-cell *matHeaderCellDef
                      >Event Date</mat-header-cell
                    >
                    <mat-cell *matCellDef="let day">{{
                      day.calendarDate | date:'YYYY-MM-dd'
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="eventType">
                      <mat-header-cell *matHeaderCellDef>Event Type</mat-header-cell>
                      <mat-cell *matCellDef="let day">
                        @switch (day.eventCode) {
                          @case ('ID') {
                            Instructional
                          } @default {
                            Non-Instructional
                          }
                        }
                      </mat-cell>
                  </ng-container>
                        
                  <mat-header-row
                    *matHeaderRowDef="vm.calendarDayDisplayedColumns"
                  />
                  <mat-row
                    *matRowDef="
                      let day;
                      columns: vm.calendarDayDisplayedColumns
                    "
                  />

                  <!-- Row shown when there is no matching data. -->
                  <tr *matNoDataRow>
                    <td colspan="4">No calendar days assigned</td>
                  </tr>
                </mat-table>
                }
              </div>
              }
            </tdoe-card>
          </mat-tab>
          }
        </mat-tab-group>
      </mat-expansion-panel>
    </div>
    <ng-container *ngFor="let category of vm.selectedAdditionalInfoFields">
      @if(category.key) {
      <ng-template
        [ngTemplateOutlet]="categoryPanelTable"
        [ngTemplateOutletContext]="{
          category: category,
          dataSource: getNestedProperty(vm.school, category.key)
        }"
      ></ng-template
      >} @else {
      <ng-template
        [ngTemplateOutlet]="categoryPanel"
        [ngTemplateOutletContext]="{
          category: category,
          dataSource: vm.school
        }"
      ></ng-template>
      }
    </ng-container>
  </div>
</div>

<ng-template #categoryPanel let-category="category" let-dataSource="dataSource">
  <div class="mat-accordion" mat-accordion>
    <mat-expansion-panel [(expanded)]="category.expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ category.name }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="data-list">
        <ng-container *ngFor="let field of category.fields">
          <div class="field">
            <span class="name">{{ field.name }}</span>
            <span class="value">{{
              getNestedProperty(dataSource, field.key)
            }}</span>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </div>
</ng-template>

<ng-template
  #categoryPanelTable
  let-category="category"
  let-dataSource="dataSource"
>
  <div class="mat-accordion" mat-accordion>
    <mat-expansion-panel [(expanded)]="category.expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ category.name }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <mat-table [dataSource]="dataSource">
          @for (field of category.fields; track $index) {
          <ng-container [matColumnDef]="field.key">
            <mat-header-cell *matHeaderCellDef>{{
              field.name
            }}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{
              getNestedProperty(item, field.key)
            }}</mat-cell>
          </ng-container>
          }
          <mat-header-row *matHeaderRowDef="category.displayedColumns" />
          <mat-row *matRowDef="let field; columns: category.displayedColumns" />
          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow>
            <td [colSpan]="category.displayedColumns.length">
              No {{ category.name }} data.
            </td>
          </tr>
        </mat-table>
      </div>
    </mat-expansion-panel>
  </div>
</ng-template>
