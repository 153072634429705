import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { ColumnDefinition, DataType, ErrorCount, ErrorItem, Pagination, Sorting } from 'app/dto';
import { PagedResponse } from 'app/models';
import { HttpParamsUtilities } from 'app/utilities/http-params-utilities/http-params-utilities';
import { ErrorLogExportRequest } from 'app/dto/errorLogExportRequest';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  public constructor(private http: HttpClient) { }

  private readonly controllerUri = `${environment.apiBaseUrl}ErrorLog`;

  public getCounts(districtIds?: string[], schoolIds?: string[], years?: number[] | number): Observable<ErrorCount[]> {
    let params = new HttpParams();
    
    schoolIds?.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    districtIds?.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });


    if (Array.isArray(years)) {
      years?.forEach(year => {
        params = params.append('years', year);
      });
    } else {
      params = params.append('years', years as number);
    }


    return this.http.get<ErrorCount[]>(`${this.controllerUri}/Counts`, { params });
  }

  public getErrors(districtIds?: string[],
                   schoolIds?: string[],
                   years?: number[] | number,
                   category?: string,
                   pagination?: Pagination,
                   sorting?: Sorting): Observable<PagedResponse<ErrorItem[]>> {
    
    let params = HttpParamsUtilities.toHttpParams(pagination ?? { pageSize: 50 }, sorting ?? {});
    
    schoolIds?.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    districtIds?.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    if (Array.isArray(years)) {
      years?.forEach(year => {
        params = params.append('years', year);
      });
    } else {
      params = params.append('years', years as number);
    }

    if (category) {
      params = params.append('category', category);
    }

    return this.http.get<PagedResponse<ErrorItem[]>>(`${this.controllerUri}/Errors`, { params });
  }

  public hasErrors(districtIds?: string[], schoolIds?: string[], years?: number[]): Observable<boolean> {
    let params = new HttpParams();
    
    schoolIds?.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    districtIds?.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    years?.forEach(year => {
      params = params.append('years', year);
    });

    return this.http.get<boolean>(`${this.controllerUri}/HasErrors`, { params });
  }

  public export(districtIds?: string[], schoolIds?: string[], years?: number[], category?: string, format?: 'csv' | 'xlsx', columns?: ColumnDefinition[]): Observable<File> {
    let params = new HttpParams();
    
    schoolIds?.forEach(schoolId => {
      params = params.append('schoolIds', schoolId);
    });

    districtIds?.forEach(districtId => {
      params = params.append('districtIds', districtId);
    });

    years?.forEach(year => {
      params = params.append('years', year);
    });

    if (category) {
      params = params.append('category', category);
    }

    const staticColumns: ColumnDefinition[] = [
      {
        displayName: 'School Name',
        jsonPath: '$.schoolName',
        dataType: DataType.Text
      },
      {
        displayName: 'School Number',
        jsonPath: '$.schoolNumber',
        dataType: DataType.Text
      },
      {
        displayName: 'Error Code',
        jsonPath: '$.errorCode',
        dataType: DataType.Text
      },
      {
        displayName: 'Error Category',
        jsonPath: '$.errorCategory',
        dataType: DataType.Text
      }
    ];

    const request: ErrorLogExportRequest = {
      columns: [
        ...staticColumns,
        ...columns!,
        {
          displayName: 'Details',
          jsonPath: '$.error',
          dataType: DataType.Text
        }
      ],
      districtIds,
      schoolIds,
      years,
      category,
      format
    };
    
    return this.http
      .post<Blob>(`${this.controllerUri}/export`, request, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        map(
          (response) => new File(
              [response.body!],
              `error-log-export-${category ?? 'all'}-${new Date().toISOString().slice(0, 10)}.${format}`
            )
        )
      );
  }

}
