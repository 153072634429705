import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AfterContentInit, Component, inject, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from 'app/services/user/user.service';
import { StaffService } from 'app/services/staff/staff.service';
import { ColumnDefinition, Pagination, Sorting, StaffMember, StaffSearchTerms } from 'app/dto';
import { UserModel } from 'app/services/user/user.model';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { StaffFilterComponent } from '../shared/staff-filter/staff-filter.component';
import { StaffTableExcelExportConfig } from '../shared/staff-table/staff-table.excel-export.config';
import { DataLookupTableComponent } from '../shared/data-lookup-table/data-lookup-table.component';
import { additionalInfoFields } from '../shared/staff-table/staff-table.additional-info-config';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { StaffTableConfig } from '../shared/staff-filter/staff-filter.formly-config';
import { LoggingService } from '@tdoe/design-system';
import { downloadExcelDocument } from 'app/utilities/excel-export-utilities/excel-export-utilities';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-data-lookup-staff',
  standalone: true,
  imports: [
    CommonModule,
    ScopeContextFilterComponent,
    StaffFilterComponent,
    DataLookupTableComponent
],
  templateUrl: './data-lookup-staff.component.html',
  styleUrls: ['./data-lookup-staff.component.scss'],
})
export class DataLookupStaffComponent implements AfterContentInit {
  private readonly logger = inject(LoggingService);
  private readonly userService = inject(UserService);
  private readonly staffService = inject(StaffService);
  private readonly router = inject(Router);

  public readonly tablePagination = signal<Pagination>({
    pageIndex: 0,
    pageSize: 50,
  });
  public readonly tableSorting = signal<Sorting>({});

  protected isLoading = true;
  public query = signal<StaffSearchTerms>({});

  public staffTableExcelExportConfig = StaffTableExcelExportConfig;
  public FormlyFieldConfigs = StaffTableConfig;
  public additionalInfoFields = additionalInfoFields;
  
  public isExporting = false;

  public readonly staticColumns = [
    { key: 'nameLast', label: 'Last Name' },
    { key: 'nameFirst', label: 'First Name' },
    { key: 'nameMiddle', label: 'Middle Name' },
    { key: 'email', label: 'Staff Organization Email' },
    { key: 'district', label: 'District' },
    { key: 'school', label: 'School' },
    { key: 'gender', label: 'Gender' },
    { key: 'teacherLicenseNumber', label: 'Teacher License Number' },
  ];

  public displayNameFormatter(item: StaffMember): string {
    return StringUtilities.FormatStringForUrl(item.name ?? '');
  }

  public excelExport(query: StaffSearchTerms, columns: ColumnDefinition[], format: 'csv' | 'xlsx'): Observable<File> {
    return this.staffService.getStaffMembersExcelExportBySearchTerms(query, columns, format);
  }

  protected userProfile: Signal<UserModel.UserProfile | undefined>;
    
  public state: { [k: string]: any; } | undefined;

  public constructor() {
    this.state = this.router.getCurrentNavigation()?.extras.state;

    this.logger.debug('DataLookupStaffComponent -> constructor', {
      data: {
        state: this.state
      }
    });

    this.userProfile = toSignal<UserModel.UserProfile>(
      this.userService.userProfile$.pipe(
        filter((userProfile) => !!userProfile),
        tap((userProfile) => {
          // Example: set up scope-based IDs from user
          this.query.update((search) => ({
            ...search,
            schoolIDs: userProfile?.scopeDetails.schoolIds,
            districtIDs: userProfile?.scopeDetails.schoolIds,
          }));
          this.isLoading = false;
        })
      )
    );
  }

  public readonly staffMembersPagedResponse = toSignal(
    combineLatest([
      toObservable(this.query),
      toObservable(this.tablePagination),
      toObservable(this.tableSorting),
    ]).pipe(
      debounceTime(300),
      map(([searchTerms, tablePagination, tableSorting]) => ({
        searchTerms,
        tablePagination,
        tableSorting,
      })),
      switchMap(data => {
        const pro = this.userService.userProfile$.getValue();
      
        if ((pro?.scopeDetails.districtIds?.length ?? 0) > 0) {
          data.searchTerms.districtIDs = [...new Set([
            ...pro?.scopeDetails.districtIds!,
            ...data.searchTerms.districtIDs ?? []
          ])];
        }
  
        if ((pro?.scopeDetails.schoolIds?.length ?? 0) > 0) {
          data.searchTerms.schoolIDs = [...new Set([
            ...pro?.scopeDetails.schoolIds!,
            ...data.searchTerms.schoolIDs ?? []
          ])];
        }
  
        if ((pro?.scopeDetails.years?.length ?? 0) > 0) {
          data.searchTerms.years = [...new Set([
            ...pro?.scopeDetails.years!,
            ...data.searchTerms.years ?? []
          ])];
        }
  
        return this.staffService.getStaffBySearchTerms(
          data.searchTerms,
          data.tablePagination,
          data.tableSorting
        )
      }
      ),
      tap((pagedResponse) => {
        // 2) Clamp pageIndex if out of range
        const { pageIndex, pageSize, totalRecords } = pagedResponse;
        const maxPageIndex = Math.floor(Math.max(totalRecords - 1, 0) / pageSize);
        if (pageIndex > maxPageIndex && totalRecords > 0) {
          this.tablePagination.update((p) => ({ ...p, pageIndex: 0 }));
        }
        this.isLoading = false;
      })
    )
  );

  public ngAfterContentInit(): void {
    this.isLoading = !this.userProfile();
        
    if (this.state !== null && this.state!['query']) {
      this.query.set(this.state!['query'])
    }

  }

  public async onExportClicked(e: { query: StaffSearchTerms; columns: ColumnDefinition[]; format: 'csv' | 'xlsx'; }): Promise<void> {
    this.logger.debug('onExportClicked', {
      data: {
        e
      }
    });
    this.isExporting = true;

    let file = new File([], '', {});
    
    try {
      file = await firstValueFrom(this.staffService.getStaffMembersExcelExportBySearchTerms(e.query, e.columns, e.format));
    } catch (err) {
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 413:
          this.logger.error('Export is limited to 500,000 records. Please apply filters to reduce the number of results and try again.', {
            data: {
              err
            },
            notify: true,
            title: 'Too Much Data'
          });
          break;
        }
      } else {
        this.logger.error('student export error', { data: {
          err
        }});
      }
    }

    if (file.size > 0) {
      downloadExcelDocument(file);
    }

    this.isExporting = false;
  }

  protected onPageChanged(pagination: Pagination): void {
    this.tablePagination.set(pagination);
  }

  public onResetQuery(query: StaffSearchTerms): void {
    this.query.set(query);
  }

  protected onSortClicked(sorting: Sorting): void {
    this.tableSorting.set(sorting);
  }

  protected onContextFilterSearchClicked(selectedScope: ScopeContextFilterModel.SelectedData): void {
    this.query.update((current) => {
      delete current.schoolIDs;
      delete current.districtIDs;
      // Add your new scope-based filter values here:
      return { ...current, ...selectedScope };
    });
    // Force page 0 so we don’t fetch page 2 on new filters
    this.tablePagination.update((p) => ({ ...p, pageIndex: 0 }));
  }

  protected onStaffFilterSearchClicked(staffSearchTerms: StaffSearchTerms): void {
    this.logger.debug('DataLookupStaffComponent -> onStaffFilterSearchClicked', { data: { staffSearchTerms } });
    this.query.update((current) => {
      delete current.nameLast;
      delete current.nameFirst;
      delete current.nameMiddle;
      delete current.teacherLicenseNumber;
      delete current.email;
      // Merge new filters
      return { ...current, ...staffSearchTerms };
    });
    // Force page 0 on filter
    this.tablePagination.update((p) => ({ ...p, pageIndex: 0 }));
  }
}