import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, inject, Signal, signal } from '@angular/core';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { combineLatest, debounceTime, filter, firstValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { StudentFilterComponent } from '../shared/student-filter/student-filter.component';

import { StudentService } from 'app/services/student/student.service';
import { UserService } from 'app/services/user/user.service';
import { TableDataModel } from 'app/models/table-data-model';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UserModel } from 'app/services/user/user.model';
import { ColumnDefinition, Pagination, Sorting, Student, StudentSearchTerms } from 'app/dto';
import { LoggingService } from '@tdoe/design-system';
import { DataLookupTableComponent } from '../shared/data-lookup-table/data-lookup-table.component';
import { StudentTableFilterConfig } from '../shared/student-table/student-table-filter.config';
import { StudentTableCategoryConfig } from '../shared/student-table/student-table-category.config';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { downloadExcelDocument } from 'app/utilities/excel-export-utilities/excel-export-utilities';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-data-lookup-student',
  standalone: true,
  imports: [
    CommonModule,
    ScopeContextFilterComponent,
    StudentFilterComponent,
    DataLookupTableComponent
  ],
  templateUrl: './data-lookup-student.component.html',
  styleUrl: './data-lookup-student.component.scss'
})
export class DataLookupStudentComponent implements AfterContentInit {

  private readonly tablePagination = signal({ pageIndex: 0, pageSize: 50} as Pagination);
  private readonly tableSort = signal({} as Sorting);

  private readonly logger = inject(LoggingService);

  protected userProfile: Signal<UserModel.UserProfile | undefined>;

  protected isLoading = true;

  public query = signal<StudentSearchTerms>({});

  public tableConfig = StudentTableFilterConfig;

  public StudentTableCategoryConfig = StudentTableCategoryConfig;

  public isExporting = false;

  public displayNameFormatter(item: Student): string {
    return StringUtilities.FormatStringForUrl(item.displayName ?? '');
  }

  public excelExport(query: Student, columns: ColumnDefinition[], format: 'csv' | 'xlsx'): Observable<File> {
    return this.studentService.getStudentsExcelExportBySearchTerms(query, columns, format);
  }
    
  protected onPageChanged(pagination: Pagination): void {
    this.tablePagination.set(pagination);
  }

  protected onSortClicked(sorting: Sorting): void {
    this.tableSort.set(sorting);
  }

  public readonly staticColumns = [
    { key: 'nameLast', label: 'Last Name' },
    { key: 'nameFirst', label: 'First Name' },
    { key: 'nameMiddle', label: 'Middle Name' },
    { key: 'ssid', label: 'SSID' },
    { key: 'dateOfBirth', label: 'Date of Birth' },
    { key: 'grade', label: 'Grade' },
    { key: 'district', label: 'District' },
    { key: 'school', label: 'School' }
  ];

  protected readonly pagedResponse = toSignal(combineLatest([
    toObservable(this.query),
    toObservable(this.tablePagination),
    toObservable(this.tableSort)
  ]).pipe(
    debounceTime(300),
    map(([searchTerms, studentPagination, studentSorting]) => ({searchTerms, studentPagination, studentSorting})),
    switchMap(data => {
      const pro = this._userProfileService.userProfile$.getValue();
      
      if ((pro?.scopeDetails.districtIds?.length ?? 0) > 0) {
        data.searchTerms.districtIds = [...new Set([
          ...pro?.scopeDetails.districtIds!,
          ...data.searchTerms.districtIds ?? []
        ])];
      }

      if ((pro?.scopeDetails.schoolIds?.length ?? 0) > 0) {
        data.searchTerms.schoolIds = [...new Set([
          ...pro?.scopeDetails.schoolIds!,
          ...data.searchTerms.schoolIds ?? []
        ])];
      }

      if ((pro?.scopeDetails.years?.length ?? 0) > 0) {
        data.searchTerms.years = [...new Set([
          ...pro?.scopeDetails.years!,
          ...data.searchTerms.years ?? []
        ])];
      }

      return this.studentService.getStudentsBySearchTerms(data.searchTerms, data.studentPagination, data.studentSorting)
        .pipe(map((studentsPagedResponse: TableDataModel.PagedResponse<Student>) => studentsPagedResponse));
      })
  ));
    
  public state: { [k: string]: any; } | undefined;

  public constructor (
    private readonly _userProfileService: UserService,
    private readonly studentService: StudentService,
    readonly router: Router
  ) {
    this.state = router.getCurrentNavigation()?.extras.state;

    this.logger.debug('DataLookupStudentComponent -> constructor', {
      data: {
        state: this.state
      }
    });

    this.userProfile = toSignal<UserModel.UserProfile>(this._userProfileService.userProfile$.pipe(
      filter(userProfile => !!userProfile),
      tap(userProfile => {
          this.query.update(searchTerms => ({
            ...searchTerms,
            years: [new Date().getFullYear()],
            schoolIds: userProfile?.scopeDetails.schoolIds,
            districtIds: userProfile?.scopeDetails.schoolIds
          }));
          this.isLoading = false;
        })
      ));
  }

  public ngAfterContentInit(): void {
    this.isLoading = !this.userProfile();
    
    if (this.state !== null && this.state!['query']) {
      this.query.set(this.state!['query'])
    }

  }

  public async onExportClicked(e: { query: StudentSearchTerms; columns: ColumnDefinition[]; format: 'csv' | 'xlsx'; }): Promise<void> {
    this.logger.debug('onExportClicked', {
      data: {
        e
      }
    });
    this.isExporting = true;

    let file = new File([], '', {});
    
    try {
      file = await firstValueFrom(this.studentService.getStudentsExcelExportBySearchTerms(e.query, e.columns, e.format));
    } catch (err) {
      if (err instanceof HttpErrorResponse) {
        switch (err.status) {
          case 413:
          this.logger.error('Export is limited to 500,000 records. Please apply filters to reduce the number of results and try again.', {
            data: {
              err
            },
            notify: true,
            title: 'Too Much Data'
          });
          break;
        }
      } else {
        this.logger.error('student export error', { data: {
          err
        }});
      }
    }

    if (file.size > 0) {
      downloadExcelDocument(file);
    }

    this.isExporting = false;
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    this.resetPagination();
    this.query.update(searchTerms =>
    {
      delete searchTerms.schoolIds;
      delete searchTerms.districtIds;
      return {
        ...searchTerms,
        ...selectedScopeContextFilterData
      };
    });
  }

  public onResetQuery(query: StudentSearchTerms): void {
    this.query.set(query);
  }

  protected onStudentFilterSearchClicked(studentSearchTerms: StudentSearchTerms): void {
    this.resetPagination();
    this.query.update(searchTerms => {
      delete searchTerms.nameFirst;
      delete searchTerms.nameLast;
      delete searchTerms.nameMiddle;
      delete searchTerms.ssid;
      delete searchTerms.dateOfBirth;
      delete searchTerms.grades;
      return {
        ...searchTerms,
        ...studentSearchTerms
      };
    });
  }
  
  private resetPagination(): void {
    this.logger.debug('DataLookupClassComponent -> resetPagination');
    this.tablePagination.update(p => ({
      pageIndex: 0,
      pageSize: 50
    }));
  }
}
