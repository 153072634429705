/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface StaffMemberLocalCourseNumber { 
    abbreviatedName?: string;
    beginDate?: string;
    category?: string;
    classDetails?: string;
    classification?: string;
    courseCode?: string;
    courseDetails?: string;
    courseName?: string;
    endDate?: string;
    excludePrelimReport?: boolean;
    localClassNumber?: string;
    primaryDistrict?: string;
    primarySchool?: string;
    /**
     * School year
     */
    schoolYear?: number;
    serviceDistrict?: string;
    serviceSchool?: string;
    teachingMethod?: string;
    testWindow?: string;
    vpec?: string;
}
