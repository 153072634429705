/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StaffMemberAdditionalInfoSearchTerms } from './staffMemberAdditionalInfoSearchTerms';

/**
 * Represents search criteria for staff members in the education system
 */
export interface StaffSearchTerms { 
    /**
     * The last name of the staff member
     */
    nameLast?: string;
    /**
     * The first name of the staff member
     */
    nameFirst?: string;
    /**
     * The middle name of the staff member
     */
    nameMiddle?: string;
    /**
     * The teacher\'s professional license number
     */
    teacherLicenseNumber?: string;
    /**
     * Type of teaching license held by the staff member
     */
    licensureCheck?: string;
    /**
     * Array of school identifiers to filter the search by specific schools
     */
    schoolIDs?: Array<string>;
    /**
     * Array of district identifiers to filter the search by specific districts
     */
    districtIDs?: Array<string>;
    /**
     * School Name
     */
    school?: string;
    /**
     * District Name
     */
    district?: string;
    /**
     * Array of academic years to filter the search
     */
    years?: Array<number>;
    /**
     * The gender of the staff member
     */
    gender?: string;
    /**
     * The email address of the staff member
     */
    email?: string;
    /**
     * Total number of years of professional experience
     */
    experienceInYears?: number;
    /**
     * Highest level of education achieved by the staff member
     */
    educationLevel?: string;
    /**
     * Date when the highest degree was conferred
     */
    conferralDate?: string;
    /**
     * Date when the staff member was added to the system
     */
    addedToFile?: string;
    additionalInfo?: StaffMemberAdditionalInfoSearchTerms;
}
