import { Injectable } from '@angular/core';
import { District, School } from 'app/dto';
import { ScopeDetails } from '../user/user.model';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public storageKeys = {
    devScopeDistricts: 'DEV_SCOPE_DISTRICTS_KEY',
    devScopeSchools: 'DEV_SCOPE_SCHOOLS_KEY',
    userScope: 'USER_SCOPE_KEY',
    userSelectedDistricts: 'USER_SELECTED_DISTRICTS_KEY',
    userSelectedSchools: 'USER_SELECTED_SCHOOLS_KEY',
    userSelectedYears: 'USER_SELECTED_YEARS_KEY'
  };

  public set userScope(scope: ScopeDetails | undefined) {
    this.setValue(this.storageKeys.userScope, scope);
  }
  public get userScope(): ScopeDetails | undefined {
    return this.getValue<ScopeDetails>(this.storageKeys.userScope);
  }

  public set userSelectedDistricts(districtIds: string[] | undefined) {
    this.setValue(this.storageKeys.userSelectedDistricts, districtIds);
  }
  public get userSelectedDistricts(): string[] | undefined {
    return this.getValue<string[]>(this.storageKeys.userSelectedDistricts);
  }

  public set userSelectedSchools(schoolIds: string[] | undefined) {
    this.setValue(this.storageKeys.userSelectedSchools, schoolIds);
  }
  public get userSelectedSchools(): string[] | undefined {
    return this.getValue<string[]>(this.storageKeys.userSelectedSchools);
  }

  public set userSelectedYears(years: number[] | number | undefined) {
    if (Array.isArray(years)) {
      this.setValue(this.storageKeys.userSelectedYears, years);
    } else {
      this.setValue(this.storageKeys.userSelectedYears, years ? parseInt(years.toString()) : years);
    }
  }
  public get userSelectedYears(): number[] | number | undefined {
    return this.getValue<number[]>(this.storageKeys.userSelectedYears);
  }

  public get devScopeDistricts(): District[] {
    return this.getValue<District[]>(this.storageKeys.devScopeDistricts) ?? [];
  }
  public set devScopeDistricts(val: District[]) {
    this.setValue(this.storageKeys.devScopeDistricts, val);
  }

  public get devScopeSchools(): School[] | undefined {
    return this.getValue<School[]>(this.storageKeys.devScopeSchools);
  }
  public set devScopeSchools(val: School[] | undefined) {
    this.setValue(this.storageKeys.devScopeSchools, val);
  }

  public setValue(key: string, obj?: unknown): void {
    if (obj) {
      localStorage.setItem(key, JSON.stringify(obj));
    } else {
      localStorage.removeItem(key);
    }
  }

  public getValue<T>(key: string): T | undefined {
    const json = localStorage.getItem(key);

    if (json) {
      return JSON.parse(json);
    }
    
    return undefined;
  }

}
