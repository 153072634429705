import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MembershipByGradeSummary } from 'app/dto';
import { MembershipService } from 'app/services/membership/membership.service';
import { UserService } from 'app/services/user/user.service';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { combineLatest, debounceTime, map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-adm-by-grade',
  standalone: true,
  imports: [CommonModule, NgxEchartsModule],
  templateUrl: './adm-by-grade.component.html',
  styleUrl: './adm-by-grade.component.scss'
})
export class AdmByGradeComponent {

  private readonly userService = inject(UserService);
  private readonly membershipService = inject(MembershipService);

  private _colorPalette = ['#FF0000','#002d72','#cecf25','#4594ce','#00FFFF','#ff8b0f']; 

  private _chartOptions: EChartsOption =  {
    title: {
      text: 'ADM by Grade',
      left: 'left',
      top: 5,
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: 10
    },
    xAxis: {
      type: 'category',
      data: ['Grade']
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value: number): string {
          if (value >= 1000) {
            return (value / 1000) + 'k';
          }
          return value.toString();
        }
      }
    },
    grid: {
      left: '15%'
    }
  };

  public viewModel$ =
    combineLatest([
      this.userService.userProfile$
    ]).pipe(
      debounceTime(300),
      map(([profile]) => ({profile})),
      mergeMap(data => this.membershipService.getMembershipByGradeSummary(
        data.profile?.getScopeIds('district'),
        data.profile?.getScopeIds('school'),
        data.profile?.scopeDetails.years
      ).pipe(
        map(membershipByGradeSummaries => ({
          chartOptions: {
            ...this._chartOptions,
            legend: {
              ...this._chartOptions.legend,
              data: membershipByGradeSummaries.map(membershipByGradeSummary => membershipByGradeSummary.grade)
            },
            series: this.getBarSeries(membershipByGradeSummaries)
          } as EChartsOption
        }))
      ))
    );

  private getBarSeries(membershipByGradeSummaries: MembershipByGradeSummary[]): BarSeriesOption[] {
    return membershipByGradeSummaries
      .map((membershipByGradeSummary, index) => ({
        name: membershipByGradeSummary.grade,
        data: [membershipByGradeSummary.baseADM],
        type: 'bar',
        itemStyle:  (index < this._colorPalette.length) 
          ? {
            color: this._colorPalette[index]
          } 
          : undefined
      }));
  }
}
