@if(!isLoading){
    <div class="container page-title">
        <div class="container">
            <h1>Student List</h1>
        </div>
    </div>
    
    <div class="container scope-context">
        <div class="container">
            <app-scope-context-filter (searchClick)="onContextFilterSearchClicked($event)" />
        </div>
    </div>

    <div class="container student-filter">
        <div class="container">
            <app-student-filter
                [studentSearchTerms]="query()"
                (searchClicked)="onStudentFilterSearchClicked($event)" />
        </div>
    </div>

    <div class="container table">
        <div class="container">
            <app-data-lookup-table additionalInfoContextKey="StudentTableComponent"
                clickRoute="/data-lookup/student-view"
                identifierPath="id"
                tableHeading="Student Information"
                [chipIgnorables]="['schoolIds','districtIds','year','years','pageScope','schools','districts']"
                [pagedData]="pagedResponse()!"
                [staticColumns]="staticColumns"
                [tableConfig]="tableConfig"
                [displayNameFormatter]="displayNameFormatter"
                [additionalInfoFields]="StudentTableCategoryConfig"
                [query]="query()"
                [isExporting]="isExporting"
                (exportClicked)="onExportClicked($event)"
                (pageChanged)="onPageChanged($event)"
                (resetQuery)="onResetQuery($event)"
                (sortClicked)="onSortClicked($event)" />
        </div>
    </div>
}
