import { CommonModule } from '@angular/common';
import { Component, OnInit, effect, inject, input, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TdoeButtonDirective } from '@tdoe/design-system';
import { AdditionalInfoService, AdditionalInfoModel } from 'app/services/additional-info/additional-info.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrl: './additional-info.component.scss',
  standalone: true,
  imports: [
    FormsModule, 
    CommonModule, 
    MatExpansionModule, 
    MatIconModule,
    TdoeButtonDirective
  ]
})
export class AdditionalInfoComponent implements OnInit {

  private readonly toast = inject(ToastrService);

  // Input
  public additionalInfoFields = input.required<AdditionalInfoModel.Category[]>();

  // Input
  public contextKey = input.required<string>();

  // Output
  public fieldSelected = output<AdditionalInfoModel.Category[]>(); 

  protected categories = signal<AdditionalInfoModel.Category[]>([]);

  protected isAdditionalInfoPanelVisible = false;

  public constructor( private readonly _additionalInfoService: AdditionalInfoService ) {
      effect(() => {
        this._additionalInfoService.saveFieldStateToSessionStorage(this.categories(), this.contextKey());
        this.fieldSelected.emit(this.categories());
      });
  }

  public ngOnInit(): void {
    const persistedFieldState = this._additionalInfoService.getFieldStateNew(this.contextKey());
    if (persistedFieldState && persistedFieldState.length > 0) {
      this.categories.set(persistedFieldState);
    } else {
      this.categories.set(this.additionalInfoFields());
    }
  }

  protected onAdditionalInfoClick(): void {
    this.toggleAdditionalInfoPanel();
  }

  protected onCloseClicked(): void {
    this.toggleAdditionalInfoPanel();
  }

  protected onSaveClicked(): void {
    this._additionalInfoService.saveFieldStateToLocalStorage(this.categories(), this.contextKey());
  }

  protected onResetClicked(): void {
    this.additionalInfoFields().flatMap((_) => _.fields).forEach(_ => _.selected = false);
    this.categories.set(this.additionalInfoFields());
    this.toast.success('Additional info fields successfully reset');
  }

  protected onFieldClicked(field: AdditionalInfoModel.Field): void {
    const updatedCategories = [...this.categories()].map(c => {
      if (c.fields.some(f => f.key === field.key)) {
        return {
          ...c,
          fields: c.fields.map(f => {
            if (f.key === field.key) {
              return {
                ...f,
                selected: !f.selected
              };
            }
            return f;
          })
        };
      }
      return c;
    });
    
    this.categories.set(updatedCategories);
  }

  protected onSelectAllClick($event: MouseEvent, category: AdditionalInfoModel.Category): void {
    $event.stopPropagation();
    
    const currentState = this.categories();
    const categoryIndex = currentState.findIndex(c => c.name === category.name);
    
    if (categoryIndex === -1) return;
    
    const newState = [...currentState];
    const allSelected = newState[categoryIndex].fields.every(f => f.selected);
    
    newState[categoryIndex] = {
      ...newState[categoryIndex],
      fields: newState[categoryIndex].fields.map(f => ({
        ...f,
        selected: !allSelected
      }))
    };
    
    this.categories.set(newState);
  }

  protected areAllFieldsSelected(category: AdditionalInfoModel.Category): boolean {
    return category.fields.every(field => field.selected);
  }

  private toggleAdditionalInfoPanel(): void {
    this.isAdditionalInfoPanelVisible = !this.isAdditionalInfoPanelVisible;
  }
}