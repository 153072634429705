/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoggingService } from '@tdoe/design-system';
import { ClassDetail } from 'app/dto';
import { ClassService } from 'app/services/class/class.service';
import { firstValueFrom, of } from 'rxjs';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { Category } from 'app/services/additional-info/additional-info.model';
import { MatExpansionModule } from '@angular/material/expansion';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DayOfWeek } from 'app/models/dayOfWeek';
import { AdditionalInfoService } from 'app/services/additional-info/additional-info.service';
import { StaffTableComponent } from '../shared/staff-table/staff-table.component';
import { StudentTableComponent } from '../shared/student-table/student-table.component';

@Component({
  selector: 'app-class-view',
  standalone: true,
  imports: [
    MatIconModule,
    AdditionalInfoComponent,
    MatExpansionModule,
    DatePipe,
    MatButtonToggleModule,
    AsyncPipe,
    StaffTableComponent,
    CommonModule,
    StudentTableComponent,
    RouterModule
],
  templateUrl: './class-view.component.html',
  styleUrl: './class-view.component.scss'
})
export class ClassViewComponent implements OnChanges, OnInit {

  // #region Inputs
  @Input() public classDetail!: ClassDetail;
  @Input() public classId!: string;
  // #endregion

  // #region D.I.
  private readonly additionalInfoService = inject(AdditionalInfoService);
  private readonly classService = inject(ClassService);
  private readonly logger = inject(LoggingService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  // #endregion

  public daysOfWeek = DayOfWeek;
  public readonly categories = of([]); //firstValueFrom(this.additionalInfoService.getFieldState());
  public readonly breadcrumbData: any;

  public additionalInfoFields: Category[] = [
    {
      name: 'Attendance',
      expanded: true,
      fields: [
        {
          name: 'Attendance Date',
          key: 'attendanceDate',
          selected: false,
          filter: {
            key: 'attendanceDate',
            type: 'datepicker',
            props: {
              label: 'Attendance date'
            }
          },
        },
        {
          name: 'Attendance Type',
          key: 'attendanceType',
          selected: false,
          filter: {
            key: 'attendanceType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'A',
                  value: 'A',
                },
                {
                  label: 'H',
                  value: 'H',
                },
                {
                  label: 'I',
                  value: 'I',
                },
                {
                  label: 'Y',
                  value: 'Y',
                },
                {
                  label: 'Z',
                  value: 'Z',
                },
              ],
              multiple: true,
              label: 'Attendance type'
            },
          },
        },
        {
          name: 'Label',
          key: 'label',
          selected: false,
          filter: {
            key: 'label',
            type: 'input',
            props: {
              type: 'text',
              label: 'Label'
            },
          },
        },
      ],
    },
    {
      name: 'Class Assignment',
      expanded: true,
      fields: [
        {
          name: 'Local Class Number',
          key: 'localClassNumber',
          selected: false,
          filter: {
            key: 'localClassNumber',
            type: 'input',
            props: {
              type: 'text',
              label: 'Local class number'
            }
          }
        },
        {
          name: 'Course Code',
          key: 'courseCode',
          selected: false,
          filter: {
            key: 'courseCode',
            type: 'input',
            props: {
              type: 'text',
              label: 'Course code'
            }
          }
        },
        {
          name: 'Class Type',
          key: 'classType',
          selected: false,
          filter: {
            key: 'classType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'C',
                  value: 'C'
                },
                {
                  label: 'P',
                  value: 'P'
                },
                {
                  label: 'T',
                  value: 'T'
                }
              ],
              multiple: true,
              label: 'Class type'
            }
          }
        },
        {
          name: 'Voc. Outside IP',
          key: 'vocOutsideIP',
          selected: false,
          filter: {
            key: 'vocOutsideIP',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'Voc. Outside IP'
            }
          }
        },
        {
          name: 'Enrolment Period Start Date',
          key: 'enrolmentPeriodStartDate',
          selected: false,
          filter: {
            key: 'enrolmentPeriodStartDate',
            type: 'datepicker',
            props: {
              label: 'Enrolment period start date'
            }
          }
        },
        {
          name: 'Enrolment Period End Date',
          key: 'enrolmentPeriodEndDate',
          selected: false,
          filter: {
            key: 'enrolmentPeriodEndDate',
            type: 'datepicker',
            props: {
              label: 'Enrolment period end date'
            }
          }
        },
        {
          name: 'Assignment Start Dates',
          key: 'assignmentStartDates',
          selected: false,
          filter: {
            key: 'assignmentStartDates',
            type: 'datepicker',
            props: {
              label: 'Assignment start dates'
            }
          }
        },
        {
          name: 'Assignment End Dates',
          key: 'assignmentEndDates',
          selected: false,
          filter: {
            key: 'assignmentEndDates',
            type: 'datepicker',
            props: {
              label: 'Assignment end dates'
            }
          }
        },
        {
          name: 'Class Start Dates',
          key: 'classStartDates',
          selected: false,
          filter: {
            key: 'classStartDates',
            type: 'datepicker',
            props: {
              label: 'Class start dates'
            }
          }
        },
      ],
    },
    {
      name: 'Classification',
      expanded: true,
      fields: [
        {
          name: 'Classification',
          key: 'classification',
          selected: false,
          filter: {
            key: 'classification',
            type: 'select',
            props: {
              multiple: true,
              options: [
                {
                  label: 'ID619',
                  value: 'ID619'
                },
                {
                  label: '504',
                  value: '504'
                },
                {
                  label: 'R',
                  value: 'R'
                },
                {
                  label: 'DYS02',
                  value: 'DYS02'
                },
                {
                  label: 'DYS03',
                  value: 'DYS03'
                },
                {
                  label: 'MF',
                  value: 'MF'
                },
                {
                  label: 'MR',
                  value: 'MR'
                },
                {
                  label: 'DYS01',
                  value: 'DYS01'
                },
                {
                  label: 'A',
                  value: 'A'
                }
              ],
              label: 'Classification'
            }
          }
        },
        {
          name: 'Begin Date',
          key: 'beginDate',
          selected: false,
          filter: {
            key: 'beginDate',
            type: 'datepicker',
            props: {
              label: 'Classification begin date'
            }
          }
        },
        {
          name: 'End Date',
          key: 'classificationEndDate',
          selected: false,
          filter: {
            key: 'endDate',
            type: 'datepicker',
            props: {
              label: 'Classification end date'
            }
          }
        },
      ],
    },
    {
      name: 'Club Membership',
      expanded: true,
      fields: [
        {
          name: 'Club ID',
          key: 'clubID',
          selected: false,
          filter: {
            key: 'clubID',
            type: 'input',
            props: {
              type: 'text',
              label: 'Club ID'
            }
          }
        },
        {
          name: 'Begin Date',
          key: 'clubBeginDate',
          selected: false,
          filter: {
            key: 'beginDate',
            type: 'datepicker',
            props: {
              label: 'Club begin date'
            }
          }
        },
        {
          name: 'End Date',
          key: 'clubEndDate',
          selected: false,
          filter: {
            key: 'endDate',
            type: 'datepicker',
            props: {
              label: 'Club end date'
            }
          }
        },
      ],
    },
    {
      name: 'Disciplinary Actions',
      expanded: true,
      fields: [
        {
          name: 'Enrollment Period Start',
          key: 'enrollmentPeriodStart',
          selected: false,
          filter: {
            key: 'enrollmentPeriodStart',
            type: 'datepicker',
            props: {
              label: 'Enrollment period start'
            }
          }
        },
        {
          name: 'Enrollment Period End',
          key: 'enrollmentPeriodEnd',
          selected: false,
          filter: {
            key: 'enrollmentPeriodEnd',
            type: 'datepicker',
            props: {
              label: 'Enrollment period end'
            }
          }
        },
        {
          name: 'Disciplinary Period Begin',
          key: 'disciplinaryPeriodBegin',
          selected: false,
          filter: {
            key: 'disciplinaryPeriodBegin',
            type: 'datepicker',
            props: {
              label: 'Disciplinary period begin'
            }
          }
        },
        {
          name: 'Disciplinary Period End',
          key: 'disciplinaryPeriodEnd',
          selected: false,
          filter: {
            key: 'disciplinaryPeriodEnd',
            type: 'datepicker',
            props: {
              label: 'Disciplinary period end'
            }
          }
        },
        {
          name: 'Disciplinary Type',
          key: 'disciplinaryType',
          selected: false,
          filter: {
            key: 'disciplinaryType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'I',
                  value: 'I'
                },
                {
                  label: 'R',
                  value: 'R'
                }
              ],
              multiple: true,
              label: 'Disciplinary type'
            }
          }
        },
        {
          name: 'Disciplinary Reason',
          key: 'disciplinaryReason',
          selected: false,
          filter: {
            key: 'disciplinaryReason',
            type: 'input',
            props: {
              type: 'text',
              label: 'Disciplinary reason'
            }
          }
        },
        {
          name: 'Sped',
          key: 'sped',
          selected: false,
          filter: {
            key: 'sped',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'SPED'
            }
          }
        },
        {
          name: 'Zero Tolerance',
          key: 'zeroTolerance',
          selected: false,
          filter: {
            key: 'zeroTolerance',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'Zero tolerance'
            }
          }
        },
      ],
    }
  ];
    
  public constructor() {
    const routeData = this.router.getCurrentNavigation()?.extras.state;
    if (routeData) {

      // Extract class detail from route data, if exists
      const routeClass = routeData['class-detail'];
      if (routeClass) {
        this.classDetail = routeClass;
      }

      // Extract breadcrumb data from route data, if exists
      const breadcrumbData = routeData['breadcrumb-data'];
      if (breadcrumbData) {
        this.breadcrumbData = breadcrumbData;
      }
    }
  }

  private async loadClassDetail(classId: string): Promise<void> {
    this.classDetail = await firstValueFrom(this.classService.getClass(classId));
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {

    if (changes['classId']?.currentValue) {
      this.loadClassDetail(this.classId);
    }

  }

  public async ngOnInit(): Promise<void> {
    // Do not continue initializing if input parameter is provided by a parent control, route data or injection
    if (this.classDetail) return;

    // Check route params for class ID
    const params = await firstValueFrom(this.route.params);
    if (params) {
      const classId: string = params['id'];
      if (classId) {
        // Request class details from service API
        this.loadClassDetail(classId);
      }
    }
  }

  public onFieldSelected(e: Category[]): void {
    this.logger.debug('ClassViewComponent -> onFieldSelected', { data: { e } });
    // TODO: As part of SWORD-235
  }

}
