import { Component, computed, effect, input, ViewChild } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { SchoolReportingPeriod } from 'app/dto';
import dayGridPlugin from '@fullcalendar/daygrid';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar-days',
  standalone: true,
  imports: [
    MatDatepickerModule,
    FullCalendarModule,
    DatePipe
  ],
  providers: [
    provideNativeDateAdapter()
  ],
  templateUrl: './calendar-days.component.html',
  styleUrl: './calendar-days.component.scss'
})
export class CalendarDaysComponent {
  @ViewChild('calendar') public calendar!: FullCalendarComponent;

  public reportingPeriod = input.required<SchoolReportingPeriod>();
  public resolver = input.required<(abbreviation: string) => string>();

  public calendarOptions = computed<CalendarOptions>(() => ({
    events: this.reportingPeriod().calendarDays?.map(_ => ({
      title: this.resolver()(_.eventCode!),
      date: _.calendarDate,
      className: `school-event school-event-${_.eventCode}`
    })),
    initialView: 'dayGridMonth',
    plugins: [
      dayGridPlugin
    ],
    initialDate: this.reportingPeriod().beginDate
  }));

  public constructor() {
    effect(() => {
      const api = this.calendar.getApi();

      api.render();
      api.gotoDate(this.reportingPeriod().beginDate!);
    });
  }

}
