/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClassPeriod } from './classPeriod';
import { AdditionalInfomationClass } from './additionalInfomationClass';

export interface ClassQuery { 
    classEndDate?: string;
    classStartDate?: string;
    classType?: string;
    /**
     * Course Classification
     */
    courseClassification?: string;
    courseCode?: string;
    courseName?: string;
    courseType?: string;
    /**
     * CTE Program service
     */
    cteProgramService?: string;
    /**
     * District number and name concatenation
     */
    readonly district?: string;
    /**
     * District ID
     */
    districtId?: string;
    /**
     * District name
     */
    districtName?: string;
    /**
     * District number
     */
    districtNumber?: number;
    id?: string;
    localClassNumber?: string;
    primaryDistrict?: string;
    primarySchool?: string;
    /**
     * School number and name concatenation
     */
    school?: string;
    /**
     * School ientifier
     */
    schoolId?: string;
    /**
     * School name
     */
    schoolName?: string;
    /**
     * School number
     */
    schoolNumber?: number;
    /**
     * School year
     */
    schoolYear?: number;
    sectionIdentifier?: string;
    /**
     * Service type
     */
    serviceType?: string;
    teachingMethod?: string;
    testWindow?: string;
    abbreviatedName?: string;
    additionalInfo?: AdditionalInfomationClass;
    bellScheduleMinutes?: number;
    category?: string;
    classification?: string;
    classPeriod?: ClassPeriod;
    /**
     * Course description
     */
    courseDescription?: string;
    dualEnrollment?: boolean;
    excludePrelimReport?: boolean;
    honorFlag?: string;
    localDualCredit?: boolean;
    postSecondaryInstitution?: string;
    stateDualCredit?: boolean;
    teacherOfRecord?: string;
    teacherTln?: string;
    vepc?: string;
    /**
     * Class type filter
     */
    classTypes?: Array<string>;
    /**
     * District identifier filter
     */
    districtIds?: Array<string>;
    /**
     * Section identifier filter
     */
    sectionIdentifiers?: Array<string>;
    /**
     * School identifier filter
     */
    schoolIds?: Array<string>;
    /**
     * Year filter
     */
    years?: Array<number>;
}
