import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClassDetail, ClassQuery, ClassQueryExcelExportRequest, ClassSummary, ColumnDefinition, DataType, Pagination, Sorting } from 'app/dto';
import { environment } from 'environments/environment';
import { PagedResponse } from 'app/models';
import { HttpParamsUtilities } from 'app/utilities/http-params-utilities/http-params-utilities';
import dayjs from 'dayjs';
import { Category } from '../additional-info/additional-info.model';
import { CategoryConfig } from 'app/dto/categoryConfig';
import { LoggingService } from '@tdoe/design-system';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  private http = inject(HttpClient);
  private logger = inject(LoggingService);
  
  public getClassesBySearchTerms(classQuery: ClassQuery, pagination: Pagination, sorting: Sorting): Observable<PagedResponse<ClassSummary>>  {
    classQuery.years = HttpParamsUtilities.ensureNumeric(classQuery.years!, true) as number[];
    const httpParams = HttpParamsUtilities.toHttpParams(classQuery, pagination, sorting);
    return this.http.get<PagedResponse<ClassSummary>>(`${environment.apiBaseUrl}classes/search`, { params: httpParams });
  }

  public getClassesExcelExportBySearchTerms(query: ClassQuery, columnDefinitions: ColumnDefinition[], format: 'csv' | 'xlsx'): Observable<File> {

    const dateFormat = 'YYYY-MM-DD';

    if (!query.years) {
      query.years = [dayjs().year()];
    }

    query.years = HttpParamsUtilities.ensureNumeric(query.years, true) as number[];

    if (query.classEndDate) {
      query.classEndDate = dayjs(query.classEndDate).format(dateFormat);
    }

    if (query.classStartDate) {
      query.classStartDate = dayjs(query.classStartDate).format(dateFormat);
    }

    const requestBody: ClassQueryExcelExportRequest = {
      searchTerms: query,
      columnDefinitions,
      format
    };
    return this.http.post<Blob>(`${environment.apiBaseUrl}classes/export/excel`,
      requestBody,
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        map(
          (response) =>
            new File(
              [response.body!],
                `classes-export-${new Date().toISOString().slice(0, 10)}.${format}`
            )
        )
      );
  }

  public getClass(id: string): Observable<ClassDetail> {
    return this.http.get<ClassDetail>(`${environment.apiBaseUrl}classes/${id}`);
  }

  public exportPdf(id: string, additionalInfo?: Category[]): Observable<File> {

    const dataCategories: CategoryConfig[] = [
      {
        name: 'Class Section Profile',
        fields: [
          {
            displayName: 'Primary District',
            jsonPath: '$.primaryDistrict'
          },
          {
            displayName: 'Primary School',
            jsonPath: '$.primarySchool'
          },
          {
            displayName: 'Course Type',
            jsonPath: '$.courseType'
          },
          {
            displayName: 'Course Code',
            jsonPath: '$.courseCode'
          },
          {
            displayName: 'Local Class Number',
            jsonPath: '$.localClassNumber'
          },
          {
            displayName: 'Section Identifier',
            jsonPath: '$.sectionIdentifier'
          },
          {
            displayName: 'Course Description',
            jsonPath: '$.courseDescription',
            colSpan: 3
          }
        ]
      },
      {
        name: 'Class Section Detail',
        fields: [
          {
            displayName: 'Class Begin Date',
            jsonPath: '$.classStartDate'
          },
          {
            displayName: 'Class End Date',
            jsonPath: '$.classEndDate'
          },
          {
            displayName: 'Class Period Name',
            jsonPath: '$.classPeriod.periodName'
          },
          {
            displayName: 'Class Period Duration',
            jsonPath: '$.classPeriod.periodDuration'
          },
          {
            displayName: 'Class Period Meeting Days',
            jsonPath: '$.classPeriod.meetingDays',
            dataType: 'DayOfWeek'
          },
          {
            displayName: 'Class Type',
            jsonPath: '$.classType'
          }
        ]
      },
      {
        name: 'Course Attributes',
        fields: [
          {
            displayName: 'Honor Flag',
            jsonPath: '$.honorFlag',
            trueValue: 'Yes',
            falseValue: 'No',
            defaultValue: 'No',
            dataType: DataType.Boolean
          },
          {
            displayName: 'State Dual Credit',
            jsonPath: '$.stateDualCredit',
            trueValue: 'Yes',
            falseValue: 'No',
            defaultValue: 'No',
            dataType: DataType.Boolean
          },
          {
            displayName: 'Local Dual Credit',
            jsonPath: '$.localDualCredit',
            trueValue: 'Yes',
            falseValue: 'No',
            defaultValue: 'No',
            dataType: DataType.Boolean
          },
          {
            displayName: 'Dual Enrollment',
            jsonPath: '$.dualEnrollment',
            trueValue: 'Yes',
            falseValue: 'No',
            defaultValue: 'No',
            dataType: DataType.Boolean
          },
          {
            displayName: 'Post Secondary Institution',
            jsonPath: '$.postSecondaryInstitution'
          },
          {
            displayName: 'CTE Program Service',
            jsonPath: '$.cteProgramService'
          },
          {
            displayName: 'Classification of Course',
            jsonPath: '$.classification'
          },
          {
            displayName: 'Teaching Method',
            jsonPath: '$.teachingMethod'
          },
          {
            displayName: 'Test Window',
            jsonPath: '$.testWindow'
          }
        ]
      }
    ];

    if (additionalInfo) {
      dataCategories.push(...additionalInfo.map(_ => ({
        name: _.name,
        jsonPath: _.valuePath,
        fields: _.fields.map(f => ({
          displayName: f.name,
          jsonPath: f.exportColumn?.jsonPath
        }))
      })));
    }

    const url = `${environment.apiBaseUrl}Classes/export/pdf/${id}`;
    
    this.logger.debug('ClassService -> exportPdf', {
      data: {
        id,
        additionalInfo,
        url,
        dataCategories
      }
    });

    return this.http.post<Blob>(url, dataCategories,
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        observe: 'response'
      }
    ).pipe(
      map(response => new File([response.body!], `class-export-${dayjs().format('YYYY-MM-DD')}.pdf`))
    );
  }
}
