import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ErrorLogService } from 'app/services/error-log/error-log.service';
import { combineLatest, filter, firstValueFrom, map, switchMap} from 'rxjs';
import { ErrorTableComponent } from '../shared/error-table/error-table.component';
import { UserService } from 'app/services/user/user.service';
import { ColumnDefinition, DataType, Pagination, Sorting } from 'app/dto';
import { toObservable } from '@angular/core/rxjs-interop';
import { TableColumn } from 'app/models/table-column';
import { downloadExcelDocument } from 'app/utilities/excel-export-utilities/excel-export-utilities';
import dayjs from 'dayjs';

@Component({
  selector: 'app-error-log-student',
  standalone: true,
  imports: [CommonModule, MatTabsModule, ErrorTableComponent],
  templateUrl: './error-log-student.component.html',
  styleUrl: './error-log-student.component.scss',
  host: {
    'style': 'width:100%'
  }
})
export class ErrorLogStudentComponent {

  private errorLogService = inject(ErrorLogService);
  private userService = inject(UserService);

  private pagination = signal<Pagination>({ pageSize: 50, pageIndex: 0 });
  private sort = signal<Sorting>({});

  public readonly tableColumns: TableColumn[] = [
    {
      name: 'schoolName',
      label: 'School Name'
    },
    {
      name: 'schoolNumber',
      label: 'School Number'
    },
    {
      name: 'errorCode',
      label: 'Error Code'
    },
    {
      name: 'errorCategory',
      label: 'Error Category'
    },
    {
      name: 'entityValue',
      label: 'Student Unique ID'
    },
    {
      name: 'errorReport',
      label: 'Details'
    }
  ];

  public viewModel$ = combineLatest([
    this.userService.userProfile$,
    toObservable(this.pagination),
    toObservable(this.sort)
  ]).pipe(map(([profile, pagination, sort]) => ({profile, pagination, sort})))
    .pipe(filter(_ => !!_.profile))
    .pipe(switchMap(_ => this.errorLogService.getErrors(
      _.profile?.getScopeIds('district'),
      _.profile?.getScopeIds('school'),
      _.profile?.scopeDetails.years,
      'student',
      _.pagination,
      _.sort
    )))
    .pipe(map(_ => ({
      pagedData: _
    }))
  );

  public onPageChanged(e: Pagination): void {
    this.pagination.set(e);
  }

  public onSortChanged(e: Sorting): void {
    this.sort.set(e);
  }

  public async onExport(format: 'xlsx' | 'csv'): Promise<void> {
    const profile = this.userService.userProfile$.getValue();

    const columns: ColumnDefinition[] = [
      {
        displayName: 'Student Unique ID',
        jsonPath: '$.entityValue',
        dataType: DataType.Text
      }
    ];

    const file = await firstValueFrom(this.errorLogService.export(profile?.scopeDetails.districtIds,
                                                                  profile?.scopeDetails.schoolIds,
                                                                  profile?.scopeDetails.years,
                                                                  'student',
                                                                  format,
                                                                  columns));
      downloadExcelDocument(file, `errorlog-export-student-${dayjs().format('YYYYMMDDHHmmss')}.${format}`);
  }

}
