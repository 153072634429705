import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.service';

export const additionalInfoFields: AdditionalInfoModel.Category[] = [
  {
    name: 'Staff Current Assignments',
    expanded: true,
    fields: [
      { 
        name: 'Assignment Code', 
        key: 'assignmentCode', 
        selected: false 
      },
      { 
        name: 'Current Assignment Begin Date', 
        key: 'assignmentBeginDate', 
        selected: false 
      },
      { 
        name: 'Current Assignment End Date', 
        key: 'assignmentEndDate', 
        selected: false 
      }
    ]
  },
  {
    name: 'Staff Licensure & Endorsement Details',
    expanded: true,
    fields: [
      { 
        name: 'Experience', 
        key: 'experienceInYears', 
        selected: false 
      },
      { 
        name: 'Education Level', 
        key: 'educationLevel', 
        selected: false 
      },
      { 
        name: 'Conferral Date', 
        key: 'conferralDate', 
        selected: false 
      },
      { 
        name: 'Date Added to File', 
        key: 'addedToFile', 
        selected: false 
      },
      { 
        name: 'Date Issued', 
        key: 'additionalInfo.staffMemberLicensureDetails.dateIssued', 
        selected: false 
      },
      { 
        name: 'Type', 
        key: 'additionalInfo.staffMemberLicensureDetails.type', 
        selected: false 
      },
      { 
        name: 'Expiration Date', 
        key: 'additionalInfo.staffMemberLicensureDetails.expirationDate', 
        selected: false 
      },
      { 
        name: 'Renewed Date', 
        key: 'additionalInfo.staffMemberLicensureDetails.renewedDate', 
        selected: false 
      },
      { 
        name: 'Number of Renewals', 
        key: 'additionalInfo.staffMemberLicensureDetails.numberOfRenewals', 
        selected: false 
      }
    ]
  }
];