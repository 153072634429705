/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SortDirection } from './sortDirection';
import { DataType } from './dataType';

export interface ColumnDefinition { 
    colSpan?: number;
    defaultValue?: string;
    displayName?: string;
    falseValue?: string;
    jsonPath?: string;
    propertyName?: string;
    stringFormat?: string;
    trueValue?: string;
    width?: number;
    dataType?: DataType;
    sortDirection?: SortDirection;
}
export namespace ColumnDefinition {
}

