/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [
    RouterOutlet,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatSelectModule
  ]
})
export class HomeComponent {
  public form = new FormGroup({});
  public model: any = {};
  public formlyFields: FormlyFieldConfig[] = [
    {
      key: 'years',
      type: 'tdoe-select',
      className: 'tdoe-grow-0',
      props: {
        options: [
          {
            text: 'One',
            value: 'one'
          },
          {
            text: 'Two',
            value: 'two'
          },
          {
            text: 'Three',
            value: 'three'
          }
        ],
        appearance: 'outline',
        multiple: true
      }
    }
  ];

}