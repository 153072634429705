@if (viewModel()) {
    <div class="container breadcrumb">
        <div class="container">
            <app-breadcrumb/>
            <h1>Error Log</h1>
            <app-scope-context-filter (searchClick)="onSearchClicked($event)"/>
        </div>
    </div>
    <app-error-category-tabs (errorCategoryTabSelected)="onErrorCategoryTabSelected($event)"/>
    <div class="container error-table">
        <div class="container">
            <router-outlet/>
        </div>
    </div>
}