/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StaffMemberStaffAssignmentSearchTerms } from './staffMemberStaffAssignmentSearchTerms';
import { StaffMemberLicensureDetailsSearchTerms } from './staffMemberLicensureDetailsSearchTerms';

/**
 * Represents search criteria for additional information of staff members in the education system
 */
export interface StaffMemberAdditionalInfoSearchTerms { 
    staffMemberLicensureDetails?: StaffMemberLicensureDetailsSearchTerms;
    staffMemberStaffAssignment?: StaffMemberStaffAssignmentSearchTerms;
}
