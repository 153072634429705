/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { School } from './school';

/**
 * Represents a paged response containing a subset of data along with pagination information.
 */
export interface SchoolPagedResponse { 
    /**
     * Gets or sets the subset of data.
     */
    data?: Array<School>;
    /**
     * Gets or sets the current page index.
     */
    pageIndex?: number;
    /**
     * Gets or sets the page size.
     */
    pageSize?: number;
    /**
     * Gets or sets the total number of pages.
     */
    totalPages?: number;
    /**
     * Gets or sets the total number of records.
     */
    totalRecords?: number;
    /**
     * Gets or sets the column used for sorting.
     */
    sortColumn?: string;
    /**
     * Gets or sets the sort direction.
     */
    sortDirection?: string;
}
