/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Represents search criteria for licensure details of staff members in the education system
 */
export interface StaffMemberLicensureDetailsSearchTerms { 
    /**
     * Date when the license was issued
     */
    dateIssued?: string;
    /**
     * Description of the license
     */
    description?: string;
    /**
     * Date when the license expires
     */
    expirationDate?: string;
    /**
     * Number of times the license has been renewed
     */
    numberOfRenewals?: number;
    /**
     * Date when the license was last renewed
     */
    renewedDate?: string;
    /**
     * Type of the license
     */
    type?: string;
}
