import { Component, input, output } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { ErrorItem, Pagination, Sorting } from 'app/dto';
import { PagedResponse } from 'app/models';
import { TdoeButtonDirective } from '@tdoe/design-system';
import { MatSortModule, Sort } from '@angular/material/sort';
import { TableColumn } from 'app/models/table-column';
import { combineLatest, map } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-error-table',
  templateUrl: './error-table.component.html',
  styleUrl: './error-table.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, 
    MatMenuModule, 
    MatPaginatorModule, 
    MatTableModule,
    TdoeButtonDirective,
    MatSortModule
  ]
})
export class ErrorTableComponent  {

  public readonly pagedData = input.required<PagedResponse<ErrorItem[]>>();
  public readonly columns = input.required<TableColumn[]>();

  public readonly pageChanged = output<Pagination>();
  public readonly sortChanged = output<Sorting>();
  public readonly export = output<'xlsx' | 'csv'>();

  public viewModel$ = combineLatest([
    toObservable(this.pagedData),
    toObservable(this.columns)
  ]).pipe(map(([pagedData, columns]) => ({ pagedData, columns })))
    .pipe(map(data => ({
      data: data.pagedData.data ?? [],
      columns: data.columns,
      pagedData: data.pagedData,
      columnNames: data.columns.map(_ => _.name)
    })));

  public onPageChanged(e: PageEvent): void {
    this.pageChanged.emit({
      pageIndex: e.pageIndex,
      pageSize: e.pageSize
    });
  }

  public onSortChanged(e: Sort): void {
    this.sortChanged.emit({
      sortColumn: e.active,
      sortDirection: e.direction
    });
  }

  public onExport(format: 'xlsx' | 'csv'): void {
    this.export.emit(format);
  }
}