<div class="container">
    <div class="container">
        <ng-container *ngIf="viewModel$ | async as vm">
            <div class="header">
                <div class="title">
                    <mat-icon>report_problem</mat-icon>
                    <div>Errors</div>
                </div>
                <div class="description">
                    If an error has generated, it indicates that data submitted did not meet minimum business rule validation requirements. All errors need to be corrected in your Student Information System and resent in order for the errors to clear.
                </div>
                <button class="button" [routerLink]="['/error-log']">View All Errors</button>
            </div>

            <div class="cards">
                <div *ngFor="let card of vm.errorCounts" class="card" >
                    <div class="count">{{card.count}}</div>
                    <div class="name">{{card.name}} Errors</div>
                    <button class="button" [routerLink]="['/error-log', card.name]">View List</button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
