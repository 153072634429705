import { Component, DestroyRef, OnInit } from '@angular/core';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StudentService } from 'app/services/student/student.service';
import { AdditionalInfoModel, AdditionalInfoService } from 'app/services/additional-info/additional-info.service';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { EnrollmentClassificationComponent } from './enrollment-classification/enrollment-classification.component';
import { EnrollmentMembershipComponent } from './enrollment-membership/enrollment-membership.component';
import { EnrollmentHistoryComponent } from './enrollment-history/enrollment-history.component';
import { Student } from 'app/dto';
import { Category } from './student-view.model';

@Component({
  selector: 'app-student-view',
  standalone: true,
  imports: [
    AdditionalInfoComponent,
    MatExpansionModule,
    MatIconModule,
    CommonModule,
    MatTabsModule,
    EnrollmentClassificationComponent,
    EnrollmentMembershipComponent,
    EnrollmentHistoryComponent
],
  templateUrl: './student-view.component.html',
  styleUrl: './student-view.component.scss'
})
export class StudentViewComponent implements OnInit {

  private readonly _categoriesSubject = new BehaviorSubject<AdditionalInfoModel.Category[]>([]);
  private readonly _categories$: Observable<AdditionalInfoModel.Category[]> = this._categoriesSubject.asObservable();

  private readonly _studentId = this.route.snapshot.paramMap.get('id') ?? '0';
  private readonly _student$ = this.studentService.getStudent(this._studentId);

  private readonly _studentCategories$: Observable<Category[]> =
    combineLatest([this._student$, this._categories$])
      .pipe(
        map(([student, categories]) => {
          return categories
            .filter(category => category.fields.some(field => field.selected))
            .map(category => ({
              ...category,
              fields: category.fields
                .filter(field => field.selected)
                .map(field => ({
                  ...field,
                  value: student[field.key as keyof Student]?.toString()
                }))
            }));
        })
      );

  public additionalInfoFields: Category[] = [
    {
      name: 'Enrollment Info',
      expanded: true,
      fields: [
        {
          name: 'Enrollment start date',
          key: 'enrollmentStartDate',
          selected: false,
          filter: {
            key: 'enrollmentStartDate',
            type: 'datepicker',
            props: {
              label: 'Enrollment start date'
            }
          }
        },
        {
          name: 'Enrollment code',
          key: 'enrollmentCode',
          selected: false,
          filter: {
            key: 'enrollmentCode',
            type: 'input',
            props: {
              label: 'Enrollment code'
            }
          }
        },
        {
          name: 'Enrollment classification',
          key: 'enrollmentClassification',
          selected: false,
          filter: {
            key: 'enrollmentClassification',
            type: 'input',
            props: {
              label: 'Enrollment classification'
            }
          }
        },
        {
          name: 'TOS',
          key: 'tos',
          selected: false,
          filter: {
            key: 'tos',
            type: 'input',
            props: {
              label: 'TOS'
            }
          }
        },
        {
          name: 'Funding eligibility',
          key: 'fundingEligibility',
          selected: false,
          filter: {
            key: 'fundingEligibility',
            type: 'input',
            props: {
              label: 'Funding eligibility'
            }
          }
        },
        {
          name: 'Withdraw',
          key: 'withdraw',
          selected: false,
          filter: {
            key: 'withdraw',
            type: 'input',
            props: {
              label: 'Withdraw'
            }
          }
        },
        {
          name: 'Enrollment start date',
          key: 'enrollmentStartDate',
          selected: false,
          filter: {
            key: 'enrollmentStartDate',
            type: 'input',
            props: {
              label: 'Enrollment start date'
            }
          }
        }
      ]
    },
    {
      name: 'Attendance',
      expanded: true,
      fields: [
        {
          name: 'Attendance Date',
          key: 'attendanceDate',
          selected: false,
          filter: {
            key: 'attendanceDate',
            type: 'datepicker',
            props: {
              label: 'Attendance date'
            }
          },
        },
        {
          name: 'Attendance Type',
          key: 'attendanceType',
          selected: false,
          filter: {
            key: 'attendanceType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'A',
                  value: 'A',
                },
                {
                  label: 'H',
                  value: 'H',
                },
                {
                  label: 'I',
                  value: 'I',
                },
                {
                  label: 'Y',
                  value: 'Y',
                },
                {
                  label: 'Z',
                  value: 'Z',
                },
              ],
              multiple: true,
              label: 'Attendance type'
            },
          },
        },
        {
          name: 'Label',
          key: 'label',
          selected: false,
          filter: {
            key: 'label',
            type: 'input',
            props: {
              type: 'text',
              label: 'Label'
            },
          },
        },
      ],
    },
    {
      name: 'Class Assignment',
      expanded: true,
      fields: [
        {
          name: 'Local Class Number',
          key: 'localClassNumber',
          selected: false,
          filter: {
            key: 'localClassNumber',
            type: 'input',
            props: {
              type: 'text',
              label: 'Local class number'
            }
          }
        },
        {
          name: 'Course Code',
          key: 'courseCode',
          selected: false,
          filter: {
            key: 'courseCode',
            type: 'input',
            props: {
              type: 'text',
              label: 'Course code'
            }
          }
        },
        {
          name: 'Class Type',
          key: 'classType',
          selected: false,
          filter: {
            key: 'classType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'C',
                  value: 'C'
                },
                {
                  label: 'P',
                  value: 'P'
                },
                {
                  label: 'T',
                  value: 'T'
                }
              ],
              multiple: true,
              label: 'Class type'
            }
          }
        },
        {
          name: 'Voc. Outside IP',
          key: 'vocOutsideIP',
          selected: false,
          filter: {
            key: 'vocOutsideIP',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'Voc. Outside IP'
            }
          }
        },
        {
          name: 'Enrolment Period Start Date',
          key: 'enrolmentPeriodStartDate',
          selected: false,
          filter: {
            key: 'enrolmentPeriodStartDate',
            type: 'datepicker',
            props: {
              label: 'Enrolment period start date'
            }
          }
        },
        {
          name: 'Enrolment Period End Date',
          key: 'enrolmentPeriodEndDate',
          selected: false,
          filter: {
            key: 'enrolmentPeriodEndDate',
            type: 'datepicker',
            props: {
              label: 'Enrolment period end date'
            }
          }
        },
        {
          name: 'Assignment Start Dates',
          key: 'assignmentStartDates',
          selected: false,
          filter: {
            key: 'assignmentStartDates',
            type: 'datepicker',
            props: {
              label: 'Assignment start dates'
            }
          }
        },
        {
          name: 'Assignment End Dates',
          key: 'assignmentEndDates',
          selected: false,
          filter: {
            key: 'assignmentEndDates',
            type: 'datepicker',
            props: {
              label: 'Assignment end dates'
            }
          }
        },
        {
          name: 'Class Start Dates',
          key: 'classStartDates',
          selected: false,
          filter: {
            key: 'classStartDates',
            type: 'datepicker',
            props: {
              label: 'Class start dates'
            }
          }
        },
      ],
    },
    {
      name: 'Classification',
      expanded: false,
      fields: [
        {
          name: 'Classification',
          key: 'classification',
          selected: false,
          filter: {
            key: 'classification',
            type: 'select',
            props: {
              multiple: true,
              options: [
                {
                  label: 'ID619',
                  value: 'ID619'
                },
                {
                  label: '504',
                  value: '504'
                },
                {
                  label: 'R',
                  value: 'R'
                },
                {
                  label: 'DYS02',
                  value: 'DYS02'
                },
                {
                  label: 'DYS03',
                  value: 'DYS03'
                },
                {
                  label: 'MF',
                  value: 'MF'
                },
                {
                  label: 'MR',
                  value: 'MR'
                },
                {
                  label: 'DYS01',
                  value: 'DYS01'
                },
                {
                  label: 'A',
                  value: 'A'
                }
              ],
              label: 'Classification'
            }
          }
        },
        {
          name: 'Begin Date',
          key: 'beginDate',
          selected: false,
          filter: {
            key: 'beginDate',
            type: 'datepicker',
            props: {
              label: 'Classification begin date'
            }
          }
        },
        {
          name: 'End Date',
          key: 'classificationEndDate',
          selected: false,
          filter: {
            key: 'endDate',
            type: 'datepicker',
            props: {
              label: 'Classification end date'
            }
          }
        }
      ],
    },
    {
      name: 'Final grade',
      expanded: false,
      fields: [
        {
          name: 'Local class number',
          key: 'localClassNumber',
          selected: false,
          filter: {
            key: 'localClassNumber',
            type: 'input',
            props: {
              label: 'Local class number'
            }
          }
        },
        {
          name: 'Course code',
          key: 'courseCode',
          selected: false,
          filter: {
            key: 'courseCode',
            type: 'input',
            props: {
              label: 'Course code'
            }
          }
        },
        {
          name: 'Credits earned',
          key: 'crediteEarned',
          selected: false,
          filter: {
            key: 'crediteEarned',
            type: 'input',
            props: {
              label: 'Credits earned',
              type: 'number'
            }
          }
        },
        {
          name: 'Credits earned',
          key: 'creditsEarned',
          selected: false,
          filter: {
            key: 'creditsEarned',
            type: 'input',
            props: {
              label: 'Credits earned',
              type: 'number'
            }
          }
        },
        {
          name: 'Numeric grade',
          key: 'numericGrade',
          selected: false,
          filter: {
            key: 'numericGrade',
            type: 'input',
            props: {
              label: 'Numeric grade',
              type: 'number'
            }
          }
        }
      ]
    },
    {
      name: 'Special Ed Options',
      expanded: false,
      fields: [
        {
          name: 'Option level',
          key: 'optionLevel',
          selected: false,
          filter: {
            key: 'optionLevel',
            type: 'input',
            props: {
              label: 'Option level'
            }
          }
        },
        {
          name: 'Option number',
          key: 'optionNumber',
          selected: false,
          filter: {
            key: 'optionNumber',
            type: 'input',
            props: {
              label: 'Option number',
              type: 'number'
            }
          }
        },
        {
          name: 'Begin date',
          key: 'specEdBeginDate',
          selected: false,
          filter: {
            key: 'specEdBeginDate',
            type: 'datepicker',
            props: {
              label: 'Begin date'
            }
          }
        },
        {
          name: 'End date',
          key: 'specEdEndDate',
          selected: false,
          filter: {
            key: 'specEdEndDate',
            type: 'datepicker',
            props: {
              label: 'End date'
            }
          }
        }
      ]
    },
    {
      name: 'Special Ed Disabilities',
      expanded: false,
      fields: [
        {
          name: 'Disability level',
          key: 'specEdDisabilityLevel',
          selected: false,
          filter: {
            key: 'specEdDisabilityLevel',
            type: 'input',
            props: {
              label: 'Disability level'
            }
          }
        },
        {
          name: 'Disability type',
          key: 'specEdDisabilityType',
          selected: false,
          filter: {
            key: 'specEdDisabilityType',
            type: 'input',
            props: {
              label: 'Disability type'
            }
          }
        },
        {
          name: 'Begin date',
          key: 'specEdDisabilityBeginDate',
          selected: false,
          filter: {
            key: 'specEdDisabilityBeginDate',
            type: 'datepicker',
            props: {
              label: 'Begin date'
            }
          }
        },
        {
          name: 'End date',
          key: 'specEdDisabilityEndDate',
          selected: false,
          filter: {
            key: 'specEdDisabilityEndDate',
            type: 'datepicker',
            props: {
              label: 'End date'
            }
          }
        }
      ]
    },
    {
      name: 'Standard AMA/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report period',
          key: 'standardReportPeriod',
          selected: false,
          filter: {
            key: 'standardReportPeriod',
            props: {
              label: 'Report period'
            }
          }
        },
        {
          name: 'Student ADM',
          key: 'standardStudentAdm',
          selected: false,
          filter: {
            key: 'standardStudentAdm',
            props: {
              label: 'Student ADM'
            }
          }
        },
        {
          name: 'Student ADA',
          key: 'standardStudentAda',
          selected: false,
          filter: {
            key: 'standardStudentAda',
            props: {
              label: 'Student ADA'
            }
          }
        }
      ]
    },
    {
      name: 'Vocational ADM/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report period',
          key: 'vocationalReportPeriod',
          selected: false,
          filter: {
            key: 'vocationalReportPeriod',
            props: {
              label: 'Report period'
            }
          }
        },
        {
          name: 'Program item',
          key: 'vocationalProgramItem',
          selected: false,
          filter: {
            key: 'vocationalProgramItem',
            props: {
              label: 'Program item'
            }
          }
        },
        {
          name: 'Student ADM',
          key: 'vocationalStudentAdm',
          selected: false,
          filter: {
            key: 'vocationalStudentAdm',
            props: {
              label: 'Student ADM'
            }
          }
        },
        {
          name: 'Student ADA',
          key: 'vocationalStudentAda',
          selected: false,
          filter: {
            key: 'vocationalStudentAda',
            props: {
              label: 'Student ADA'
            }
          }
        }
      ]
    },
    {
      name: 'Club Membership',
      expanded: false,
      fields: [
        {
          name: 'Club ID',
          key: 'clubID',
          selected: false,
          filter: {
            key: 'clubID',
            type: 'input',
            props: {
              type: 'text',
              label: 'Club ID'
            }
          }
        },
        {
          name: 'Begin Date',
          key: 'clubBeginDate',
          selected: false,
          filter: {
            key: 'beginDate',
            type: 'datepicker',
            props: {
              label: 'Club begin date'
            }
          }
        },
        {
          name: 'End Date',
          key: 'clubEndDate',
          selected: false,
          filter: {
            key: 'endDate',
            type: 'datepicker',
            props: {
              label: 'Club end date'
            }
          }
        },
      ],
    },
    {
      name: 'Disciplinary Actions',
      expanded: false,
      fields: [
        {
          name: 'Enrollment Period Start',
          key: 'enrollmentPeriodStart',
          selected: false,
          filter: {
            key: 'enrollmentPeriodStart',
            type: 'datepicker',
            props: {
              label: 'Enrollment period start'
            }
          }
        },
        {
          name: 'Enrollment Period End',
          key: 'enrollmentPeriodEnd',
          selected: false,
          filter: {
            key: 'enrollmentPeriodEnd',
            type: 'datepicker',
            props: {
              label: 'Enrollment period end'
            }
          }
        },
        {
          name: 'Disciplinary Period Begin',
          key: 'disciplinaryPeriodBegin',
          selected: false,
          filter: {
            key: 'disciplinaryPeriodBegin',
            type: 'datepicker',
            props: {
              label: 'Disciplinary period begin'
            }
          }
        },
        {
          name: 'Disciplinary Period End',
          key: 'disciplinaryPeriodEnd',
          selected: false,
          filter: {
            key: 'disciplinaryPeriodEnd',
            type: 'datepicker',
            props: {
              label: 'Disciplinary period end'
            }
          }
        },
        {
          name: 'Disciplinary Type',
          key: 'disciplinaryType',
          selected: false,
          filter: {
            key: 'disciplinaryType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'I',
                  value: 'I'
                },
                {
                  label: 'R',
                  value: 'R'
                }
              ],
              multiple: true,
              label: 'Disciplinary type'
            }
          }
        },
        {
          name: 'Disciplinary Reason',
          key: 'disciplinaryReason',
          selected: false,
          filter: {
            key: 'disciplinaryReason',
            type: 'input',
            props: {
              type: 'text',
              label: 'Disciplinary reason'
            }
          }
        },
        {
          name: 'Sped',
          key: 'sped',
          selected: false,
          filter: {
            key: 'sped',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'SPED'
            }
          }
        },
        {
          name: 'Zero Tolerance',
          key: 'zeroTolerance',
          selected: false,
          filter: {
            key: 'zeroTolerance',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'Zero tolerance'
            }
          }
        },
      ],
    }
  ];
    
  protected viewModel$ = combineLatest([this._student$, this._studentCategories$]).pipe(
    map(([student, studentCategories]) => ({ student, studentCategories })));

  public constructor (
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly studentService: StudentService,
    private readonly additionalInfoService: AdditionalInfoService,
    private readonly destroyReference: DestroyRef,
    private readonly location: Location
  ){}

  public ngOnInit(): void {
    this.titleService.setTitle(`${this.titleService.getTitle()} - ${this.route.snapshot.paramMap.get('name')}`);
    
    //Clark remove subscribe from class file
    this.additionalInfoService.getFieldState()
      .pipe(takeUntilDestroyed(this.destroyReference))
      .subscribe(categories => this._categoriesSubject.next(categories));
  }

  public onFieldSelected(categories: AdditionalInfoModel.Category[]): void {
    this._categoriesSubject.next(categories);
  }

  public onBackToStudentsClick(): void {
    this.location.back();
  }
}
