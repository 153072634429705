/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * School directory contact information
 */
export interface SchoolDirectoryInfo { 
    /**
     * Street address
     */
    address?: string;
    /**
     * Programs offered
     */
    programs?: string;
    /**
     * City in which school resides
     */
    city?: string;
    /**
     * Grades serviced
     */
    grade?: string;
    /**
     * School phone number
     */
    officeNumber?: string;
    /**
     * Email address to principal
     */
    principalEmailAddress?: string;
    /**
     * Name of principal
     */
    principalName?: string;
    /**
     * Name of school
     */
    schoolName?: string;
    /**
     * U.S. State
     */
    state?: string;
    /**
     * Postal code
     */
    zipCode?: string;
    /**
     * Web address (URL)
     */
    webAddress?: string;
}