import { CommonModule } from '@angular/common';
import { Component, inject, } from '@angular/core';
import { LoggingService } from '@tdoe/design-system';
import { MembershipSummary } from 'app/dto';
import { MembershipService } from 'app/services/membership/membership.service';
import { UserService } from 'app/services/user/user.service';
import dayjs from 'dayjs';
import { combineLatest, debounceTime, filter, map, mergeMap, Observable, switchMap, tap } from 'rxjs';

interface viewModel {
  year0MembershipSummary?: MembershipSummary,
  year1MembershipSummary?: MembershipSummary,
  year2MembershipSummary?: MembershipSummary
}

@Component({
  selector: 'app-average-daily-membership',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './average-daily-membership.component.html',
  styleUrl: './average-daily-membership.component.scss'
})
export class AverageDailyMembershipComponent {

  private readonly userService = inject(UserService);
  private readonly membershipService = inject(MembershipService);
  private readonly logger = inject(LoggingService);

  public viewModel$: Observable<viewModel> =
    combineLatest([
      this.userService.userProfile$
    ]).pipe(
      debounceTime(300),
      map(([profile]) => ({ profile })),
      filter(profile => !!profile),
      map(data => ({
        year: data.profile!.getScopeYears() as unknown,
        districts: data.profile!.getScopeIds('district'),
        schools: data.profile!.getScopeIds('school')
      })),
      tap(_ => this.logger.debug('AverageDailyMembershipComponent -> viewModel$', {
        data: {
          _
        }
      })),
      mergeMap(data => this.membershipService.getMembershipSummary(data.year as number, data.districts, data.schools)
      .pipe(
        map(membershipSummaries =>({
          year0MembershipSummary: this.getMostRecentYear(membershipSummaries),
          year1MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === this.getPriorYears(membershipSummaries)[1]),
          year2MembershipSummary: membershipSummaries.find(membershipSummary => membershipSummary.year === this.getPriorYears(membershipSummaries)[0])
        }))
      ))
    );

  private getMostRecentYear(summaries: MembershipSummary[]): MembershipSummary | undefined {
    let mostRecentYear = 0;

    summaries.forEach(_ => {
      if (_.year! > mostRecentYear) {
        mostRecentYear = _.year ?? 0;
      }
    });

    return summaries.find(_ => _.year === mostRecentYear);
  }

  private getPriorYears(summaries: MembershipSummary[]): number[] {
    const startYear = this.getMostRecentYear(summaries)?.year!;
    return [
      startYear,
      startYear - 1,
      startYear - 2
    ];
  }

}
