/**
 * SWORD API
 * SWORD service API to be consumed by SWORD Angular SPA. Interfaces with Entra ID and DataBricks.
 *
 * OpenAPI spec version: v1
 * Contact: donnie.hyatt@tnedu.gov
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ColumnDefinition } from './columnDefinition';
import { StaffSearchTerms } from './staffSearchTerms';

/**
 * Request model for exporting data to Excel.
 */
export interface StaffSearchTermsExcelExportRequest { 
    searchTerms?: StaffSearchTerms;
    columnDefinitions?: Array<ColumnDefinition>;
    format?: string;
}
