import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { HttpParamsUtilities } from '../../utilities/http-params-utilities/http-params-utilities';
import { PagedResponse } from 'app/models/table-data-model';
import { EthnicitySummary, GenderSummary, Pagination, Sorting, Student, StudentSearchTerms, StudentSearchTermsExcelExportRequest } from 'app/dto';
import { ColumnDefinition } from 'app/dto/columnDefinition';
import dayjs from 'dayjs';
import { Category } from '../additional-info/additional-info.model';
import { LoggingService } from '@tdoe/design-system';
import { CategoryConfig } from 'app/dto/categoryConfig';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  private logger = inject(LoggingService);

  public constructor(private http: HttpClient) { }

  public static AvailableYearsUrl = environment.apiBaseUrl + 'Student/AvailableYears';
  public static StudentsUrl = environment.apiBaseUrl + 'Student/Students';
  public static StudentUrl = environment.apiBaseUrl + 'Student/Student';
  public static GenderSummaryUrl = environment.apiBaseUrl + 'Student/GenderSummary';
  public static EthnicitySummaryUrl = environment.apiBaseUrl + 'Student/EthnicitySummary';
  public static StudentsExcelExportUrl = environment.apiBaseUrl + 'Student/export/excel';

  public getStudentsBySearchTerms(studentSearchTerms: StudentSearchTerms, pagination: Pagination, sorting: Sorting): Observable<PagedResponse<Student>> {
    // Using our utility for non-array parameters is acceptable here.
    studentSearchTerms.years = HttpParamsUtilities.ensureNumeric(studentSearchTerms.years!) as number[];
    const httpParams = HttpParamsUtilities.toHttpParams(studentSearchTerms, pagination, sorting);
    return this.http.get<PagedResponse<Student>>(StudentService.StudentsUrl, { params: httpParams });
  }

  public getStudentsExcelExportBySearchTerms(query: StudentSearchTerms, columnDefinitions: ColumnDefinition[], format: 'csv' | 'xlsx'): Observable<File> {

    if (!query.years) {
      query.years = [dayjs().year()];
    }

    query.years = HttpParamsUtilities.ensureNumeric(query.years, true) as number[];

    const requestBody: StudentSearchTermsExcelExportRequest = {
      searchTerms: query,
      columnDefinitions,
      format
    };
    
    return this.http
      .post<Blob>(StudentService.StudentsExcelExportUrl, requestBody, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        map(
          (response) => new File(
              [response.body!],
              `students-export-${new Date().toISOString().slice(0, 10)}.${format}`
            )
        )
      );
  }

  public getStudent(studentId: string): Observable<Student> {
    const httpParams = new HttpParams().set('id', studentId);
    return this.http.get<Student>(StudentService.StudentUrl, { params: httpParams });
  }

  public getSchoolGenderSummary(year: number, schoolIds: string[]): Observable<GenderSummary[]> {
    let params = new HttpParams().set('year', year.toString());
    schoolIds.forEach(id => {
      params = params.append('schoolId', id);
    });
    return this.http.get<GenderSummary[]>(StudentService.GenderSummaryUrl, { params });
  }

  public getDistrictGenderSummary(year: number, districtIds: string[]): Observable<GenderSummary[]> {
    let params = new HttpParams().set('year', year.toString());
    districtIds.forEach(id => {
      params = params.append('districtId', id);
    });
    return this.http.get<GenderSummary[]>(StudentService.GenderSummaryUrl, { params });
  }

  public getStateGenderSummary(year: number): Observable<GenderSummary[]> {
    const params = new HttpParams().set('year', year.toString());
    return this.http.get<GenderSummary[]>(StudentService.GenderSummaryUrl, { params });
  }

  public getSchoolEthnicitySummary(year: number, schoolIds: string[]): Observable<EthnicitySummary[]> {
    let params = new HttpParams().set('year', year.toString());
    schoolIds.forEach(id => {
      params = params.append('schoolId', id);
    });
    return this.http.get<EthnicitySummary[]>(StudentService.EthnicitySummaryUrl, { params });
  }

  public getDistrictEthnicitySummary(year: number, districtIds: string[]): Observable<EthnicitySummary[]> {
    let params = new HttpParams().set('year', year.toString());
    districtIds.forEach(id => {
      params = params.append('districtId', id);
    });
    return this.http.get<EthnicitySummary[]>(StudentService.EthnicitySummaryUrl, { params });
  }

  public getStateEthnicitySummary(year: number): Observable<EthnicitySummary[]> {
    const params = new HttpParams().set('year', year.toString());
    return this.http.get<EthnicitySummary[]>(StudentService.EthnicitySummaryUrl, { params });
  }
  
  public exportPdf(id: string, additionalInfo?: Category[]): Observable<File> {

    const dataCategories: CategoryConfig[] = [
      {
        name: 'Student Personal Info',
        fields: [
          {
            displayName: 'Last Name',
            jsonPath: '$.NameLast'
          },
          {
            displayName: 'First Name',
            jsonPath: '$.NameFirst'
          },
          {
            displayName: 'Middle Name',
            jsonPath: '$.NameMiddle'
          },
          {
            displayName: 'SSID',
            jsonPath: '$.SSID'
          },
          {
            displayName: 'Grade',
            jsonPath: '$.Grade'
          },
          {
            displayName: 'District',
            jsonPath: '$.district'
          },
          {
            displayName: 'Service District',
            jsonPath: '$.serviceDistrict'
          },
          {
            displayName: 'School',
            jsonPath: '$.school'
          },
          {
            displayName: 'Service School',
            jsonPath: '$.serviceSchool'
          },
          {
            displayName: 'Student Standard Day (Minutes)',
            jsonPath: '$.StandardDayMinutes'
          },
          { displayName: 'Service Type', 
            jsonPath: '$.serviceType' 
          },
          {
            displayName: 'Withdrawal End Date',
            jsonPath: '$.withdrawalEndDate'
          },
          {
            displayName: 'Withdrawal Code',
            jsonPath: '$.withdrawalCode'
          },
          {
            displayName: 'Gender',
            jsonPath: '$.gender'
          }
        ]
      },
      {
        name: 'Student Enrollment Details',
        fields: [
          {
            displayName: 'EOS Date',
            jsonPath: '$.Enrollment.EndOfService.endOfServiceDate'
          },
          {
            displayName: 'EOS Action',
            jsonPath: '$.Enrollment.EndOfService.eosAction'
          },
          {
            displayName: 'Comp Doc Date',
            jsonPath: '$.Enrollment.EndOfService.compDocDate'
          },
          {
            displayName: 'CompDocType',
            jsonPath: '$.Enrollment.EndOfService.compDocType'
          },
          {
            displayName: 'Comp Doc Period',
            jsonPath: '$.Enrollment.EndOfService.compDocPeriod'
          }
        ]
      }
    ];

    if (additionalInfo) {
      dataCategories.push(...additionalInfo.map(_ => ({
        name: _.name,
        jsonPath: _.valuePath,
        fields: _.fields.map(f => ({
          displayName: f.name,
          jsonPath: f.exportColumn?.jsonPath
        }))
      })));
    }

    const url = `${environment.apiBaseUrl}Student/export/pdf/${id}`;
    
    this.logger.debug('StudentService -> exportPdf', {
      data: {
        id,
        additionalInfo,
        url,
        dataCategories
      }
    });

    return this.http.post<Blob>(url, dataCategories,
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        observe: 'response'
      }
    ).pipe(
      map(response => new File([response.body!], `student-export-${dayjs().format('YYYY-MM-DD')}.pdf`))
    );
  }
}