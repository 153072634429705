import { DataType } from 'app/dto';
import { Category } from 'app/services/additional-info/additional-info.model';

export const StudentViewConfiguration: Category[] = [
    {
      name: 'Student Demographic',
      expanded: true,
      fields: [
        {
          name: 'Immigrant',
          key: 'immigration',
          valuePath: 'demographics.immigration',
          selected: false,
          exportColumn: {
            jsonPath: '$.Demographics.immigration'
          }
        },
        {
          name: 'Date First Enrolled in US School',
          key: 'dateFirstEnrolledInPublicSchool',
          valuePath: 'demographics.dateFirstEnrolledInUsSchool',
          selected: false,
          exportColumn: {
            jsonPath: '$.Demographics.dateFirstEnrolledInUsSchool'
          }
        },
        {
          name: 'Year Entered 9th Grade',
          key: 'yearEntered9thGrade',
          valuePath: 'demographics.yearEnteredGrade9',
          selected: false,
          exportColumn: {
            jsonPath: '$.Demographics.yearEnteredGrade9'
          }
        },
        {
          name: 'Native Language',
          key: 'nativeLanguage',
          valuePath: 'demographics.nativeLanguage',
          selected: false,
          exportColumn: {
            jsonPath: '$.Demographics.nativeLanguage'
          }
        },
        {
          name: 'EL Service Years',
          key: 'elServiceYears',
          valuePath: 'demographics.elServiceYears',
          selected: false,
          exportColumn: {
            jsonPath: '$.Demographics.elServiceYears'
          }
        },
        {
          name: 'Calculated EL Service Years',
          key: 'calculatedElServiceYears',
          valuePath: 'demographics.calculatedElServiceYears',
          selected: false,
          exportColumn: {
            jsonPath: '$.Demographics.calculatedElServiceYears'
          }
        },
        {
          name: 'English Language Background',
          key: 'englishLanguageBackground',
          valuePath: 'demographics.englishLanguageBackground',
          selected: false,
          exportColumn: {
            jsonPath: '$.Demographics.englishLanguageBackground',
            trueValue: 'Yes',
            falseValue: 'No'
          }
        },
        {
          name: 'Race',
          key: 'race',
          valuePath: 'demographics.race',
          selected: false,
          exportColumn: {
            jsonPath: '$.Demographics.race'
          }
        }
      ]
    },
    {
      name: 'Enrollment Info',
      expanded: true,
      fields: [
        {
          name: 'Enrollment Date',
          key: 'enrollmentStartDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.enrollmentStartDate'
          }
        },
        {
          name: 'Enrollment Code',
          key: 'enrollmentCode',
          valuePath: 'code',
          selected: false,
          exportColumn: {
            jsonPath: '$.code'
          }
        },
        {
          name: 'Classification',
          key: 'enrollmentClassification',
          valuePath: 'classification.classificationCode',
          selected: false,
          exportColumn: {
            jsonPath: '$.classification.ClassificationCode'
          }
        },
        {
          name: 'TOS',
          key: 'tos',
          selected: false,
          exportColumn: {
            jsonPath: '$.tos'
          }
        },
        {
          name: 'Withdrawal Date',
          key: 'withdrawalEndDate',
          valuePath: 'withdrawalEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.withdrawalEndDate'
          }
        },
        {
          name: 'Withdrawal Code',
          key: 'withdrawalCode',
          valuePath: 'withdrawalCode',
          selected: false,
          exportColumn: {
            jsonPath: '$.withdrawalCode'
          }
        }
      ]
    },
    {
      name: 'Attendance',
      expanded: true,
      fields: [
        {
          name: 'Attendance Date',
          key: 'attendanceDate',
          valuePath: 'additionalInfo.attendance.attendanceDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.attendance.attendanceDate'
          }
        },
        {
          name: 'Attendance Type',
          key: 'attendanceType',
          valuePath: 'additionalInfo.attendance.attendanceType',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.attendance.attendanceType'
          }
        },
        {
          name: 'Reporting Begin Date',
          key: 'reportingBeginDate',
          valuePath: 'additionalInfo.attendance.reportingBeginDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.attendance.reportingBeginDate'
          }
        },
        {
          name: 'Reporting End Date',
          key: 'reportingEndDate',
          valuePath: 'additionalInfo.attendance.reportingEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.attendance.reportingEndDate'
          }
        }
      ],
    },
    {
      name: 'Class Assignment',
      expanded: true,
      fields: [
        {
          name: 'Local Class Number',
          key: 'localClassNumber',
          selected: false,
          exportColumn: {
            jsonPath: '$.localClassNumber'
          }
        },
        {
          name: 'Course Code',
          key: 'courseCode',
          selected: false,
          exportColumn: {
            jsonPath: '$.courseCode'
          }
        },
        {
          name: 'Class Type',
          key: 'classType',
          selected: false,
          exportColumn: {
            jsonPath: '$.classType'
          }
        },
        {
          name: 'Enrollment Period Begin Date',
          key: 'enrollmentPeriodStartDate',
          valuePath: 'assignmentEnrollmentPeriodStartDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentEnrollmentPeriodStartDate'
          }
        },
        {
          name: 'Enrollment Period End Date',
          key: 'enrollmentPeriodEndDate',
          valuePath: 'assignmentEnrollmentPeriodEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentEnrollmentPeriodEndDate'
          }
        },
        {
          name: 'Class Begin Date',
          key: 'classStartDate',
          valuePath: 'classStartDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.classStartDate'
          }
        },
        {
          name: 'Class End Date',
          key: 'classEndDate',
          valuePath: 'additionalInfo.classAssignment.classEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.classAssignment.classEndDate'
          }
        },
        {
          name: 'Assignment Begin Date',
          key: 'assignmentStartDate',
          valuePath: 'assignmentStartDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentStartDate'
          }
        },
        {
          name: 'Assignment End Date',
          key: 'assignmentEndDate',
          valuePath: 'assignmentEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.assignmentEndDate'
          }
        },
        {
          name: 'Class Period Name',
          key: 'classPeriodName',
          valuePath: 'additionalInfo.classAssignment.classPeriodName',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.classAssignment.classPeriodName'
          }
        },
        {
          name: 'Class Period Duration',
          key: 'classPeriodDuration',
          valuePath: 'additionalInfo.classAssignment.classPeriodDuration',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.classAssignment.classPeriodDuration'
          }
        },
        {
          name: 'Class Period Meeting Days',
          key: 'classPeriodMeetingDays',
          valuePath: 'additionalInfo.classAssignment.classPeriodMeetingDays',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.classAssignment.classPeriodMeetingDays'
          }
        }
      ],
    },
    {
      name: 'Classification',
      expanded: false,
      fields: [
        {
          name: 'Classification',
          key: 'classificationCode',
          valuePath: 'classification.classificationCode',
          selected: false,
          exportColumn: {
            jsonPath: '$.classification.ClassificationCode'
          }
        },
        {
          name: 'Classification Begin Date',
          key: 'classificationStartDate',
          valuePath: 'classification.classificationDateStart',
          selected: false,
          exportColumn: {
            jsonPath: '$.classification.ClassificationDateStart'
          }
        },
        {
          name: 'Classification End Date',
          key: 'classificationEndDate',
          valuePath: 'classification.classificationDateEnd',
          selected: false,
          exportColumn: {
            jsonPath: '$.classification.ClassificationDateEnd'
          }
        }
      ],
    },
    {
      name: 'Final Grade',
      expanded: false,
      fields: [
        {
          name: 'Credits Earned',
          key: 'creditsEarnedInput',
          valuePath: 'creditsEarned',
          selected: false,
          exportColumn: {
            jsonPath: '$.creditsEarned'
          }
        },
        {
          name: 'Alpha Grade',
          key: 'alphaGradeInput',
          valuePath: 'alphaGrade',
          selected: false,
          exportColumn: {
            jsonPath: '$.alphaGrade'
          }
        },
        {
          name: 'Numeric Grade',
          key: 'numericGradeInput',
          valuePath: 'numericGrade',
          selected: false,
          exportColumn: {
            jsonPath: '$.numericGrade'
          }
        },
        {
          name: 'Year Class was Taken',
          key: 'classSchoolYear',
          valuePath: 'additionalInfo.finalGrade.classSchoolYear',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.finalGrade.classSchoolYear'
          }
        },
        {
          name: 'Term Class was Taken',
          key: 'classTerm',
          valuePath: 'additionalInfo.finalGrade.classTerm',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.finalGrade.classTerm'
          }
        },
        {
          name: 'Credits Attempted',
          key: 'creditsAttempted',
          valuePath: 'additionalInfo.finalGrade.creditsAttempted',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.finalGrade.creditsAttempted'
          }
        },
        {
          name: 'Quality Points',
          key: 'qualityPoints',
          valuePath: 'additionalInfo.finalGrade.qualityPoints',
          selected: false,
          exportColumn: {
            jsonPath: '$.additionalInfo.finalGrade.qualityPoints'
          }
        }
      ]
    },
    {
      name: 'Special Ed Options',
      expanded: false,
      fields: [
        {
          name: 'Option Level',
          key: 'optionLevel',
          selected: false,
          exportColumn: {
            jsonPath: '$.optionLevel'
          }
        },
        {
          name: 'Option Number',
          key: 'optionNumberInput',
          valuePath: 'optionNumber',
          selected: false,
          exportColumn: {
            jsonPath: '$.optionNumber'
          }
        },
        {
          name: 'Begin Date',
          key: 'specEdBeginDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.specEdBeginDate'
          }
        },
        {
          name: 'End Date',
          key: 'specEdEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.specEdEndDate'
          }
        }
      ]
    },
    {
      name: 'Special Ed Disabilities',
      expanded: false,
      fields: [
        {
          name: 'Disability Level',
          key: 'specEdDisabilityLevel',
          selected: false,
          exportColumn: {
            jsonPath: '$.specEdDisabilityLevel'
          }
        },
        {
          name: 'Disability Type',
          key: 'specEdDisabilityType',
          selected: false,
          exportColumn: {
            jsonPath: '$.specEdDisabilityType'
          }
        },
        {
          name: 'Begin Date',
          key: 'specEdDisabilityBeginDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.specEdDisabilityBeginDate'
          }
        },
        {
          name: 'End Date',
          key: 'specEdDisabilityEndDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.specEdDisabilityEndDate'
          }
        }
      ]
    },
    {
      name: 'Standard AMA/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report Period',
          key: 'standardReportPeriod',
          selected: false,
          exportColumn: {
            jsonPath: '$.standardReportPeriod'
          }
        },
        {
          name: 'Student Standard ADM',
          key: 'standardStudentAdm',
          selected: false,
          exportColumn: {
            jsonPath: '$.standardStudentAdm'
          }
        },
        {
          name: 'Student ADA',
          key: 'standardStudentAda',
          selected: false,
          exportColumn: {
            jsonPath: '$.standardStudentAda'
          }
        }
      ]
    },
    {
      name: 'Vocational ADM/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report Period',
          key: 'vocationalReportPeriod',
          valuePath: 'vocationalInfo.reportingPeriod.periodNumber',
          selected: false,
          exportColumn: {
            jsonPath: '$.vocationalInfo.reportingPeriod.periodNumber'
          }
        },
        {
          name: 'Program Item',
          key: 'vocationalProgramItem',
          valuePath: 'vocationalInfo.programItem',
          selected: false,
          exportColumn: {
            jsonPath: '$.vocationalInfo.programItem'
          }
        },
        {
          name: 'Student ADM',
          key: 'vocationalStudentAdm',
          valuePath: 'vocationalInfo.studentAdm',
          selected: false,
          exportColumn: {
            jsonPath: '$.vocationalInfo.studentAdm'
          }
        },
        {
          name: 'Student ADA',
          key: 'vocationalStudentAda',
          valuePath: 'vocationalInfo.studentAda',
          selected: false,
          exportColumn: {
            jsonPath: '$.vocationalInfo.studentAda'
          }
        }
      ]
    },
    {
      name: 'Club Membership',
      expanded: false,
      valuePath: '$.Enrollment.ClubMembership',
      fields: [
        {
          name: 'Club ID',
          key: 'clubId',
          selected: false,
          exportColumn: {
            jsonPath: '$.clubId'
          }
        },
        {
          name: 'Club Membership Begin Date',
          key: 'beginDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.beginDate'
          }
        },
        {
          name: 'Club Membership End Date',
          key: 'endDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.endDate'
          }
        },
        {
          name: 'CTSO Chapter ID',
          key: 'ctsoChapterId',
          selected: false,
          exportColumn: {
            jsonPath: '$.ctsoChapterId'
          }
        },
        {
          name: 'CTSO Membership ID',
          key: 'ctsoMembershipId',
          selected: false,
          exportColumn: {
            jsonPath: '$.ctsoMembershipId'
          }
        }
      ],
    },
    {
      name: 'Disciplinary Actions',
      expanded: false,
      fields: [
        {
          name: 'Enrollment Period Start',
          key: 'disciplinaryEnrollmentPeriodStart',
          selected: false,
          exportColumn: {
            jsonPath: '$.disciplinaryEnrollmentPeriodStart'
          }
        },
        {
          name: 'Enrollment Period End',
          key: 'disciplinaryEnrollmentPeriodEnd',
          selected: false,
          exportColumn: {
            jsonPath: '$.disciplinaryEnrollmentPeriodEnd'
          }
        },
        {
          name: 'Disciplinary Begin Date',
          key: 'disciplinaryPeriodBegin',
          selected: false,
          exportColumn: {
            jsonPath: '$.disciplinaryPeriodBegin'
          }
        },
        {
          name: 'Disciplinary End Date',
          key: 'disciplinaryPeriodEnd',
          selected: false,
          exportColumn: {
            jsonPath: '$.disciplinaryPeriodEnd'
          }
        },
        {
          name: 'Disciplinary Type',
          key: 'disciplinaryType',
          selected: false,
          exportColumn: {
            jsonPath: '$.disciplinaryType'
          }
        },
        {
          name: 'Disciplinary Reason',
          key: 'disciplinaryReason',
          selected: false,
          exportColumn: {
            jsonPath: '$.disciplinaryReason'
          }
        },
        {
          name: 'Zero Tolerance',
          key: 'zeroTolerance',
          selected: false,
          exportColumn: {
            jsonPath: '$.zeroTolerance'
          }
        },
      ],
    },
    {
      name: 'End of Service',
      expanded: false,
      fields: [
        {
          name: 'End of Service Action Date',
          key: 'endOfServiceDate',
          valuePath: 'endOfService.endOfServiceDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.endOfService.endOfServiceDate'
          }
        },
        {
          name: 'End of Service Action',
          key: 'eosAction',
          valuePath: 'endOfService.eosAction',
          selected: false,
          exportColumn: {
            jsonPath: '$.endOfService.eosAction'
          }
        },
        {
          name: 'Completion Document Date',
          key: 'compDocDate',
          valuePath: 'endOfService.compDocDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.endOfService.compDocDate'
          }
        },
        {
          name: 'Completion Document Type',
          key: 'compDocType',
          valuePath: 'endOfService.compDocType',
          selected: false,
          exportColumn: {
            jsonPath: '$.endOfService.compDocType'
          }
        },
        {
          name: 'Completion Document Period',
          key: 'compDocPeriod',
          valuePath: 'endOfService.compDocPeriod',
          selected: false,
          exportColumn: {
            jsonPath: '$.endOfService.compDocPeriod'
          }
        }
      ]
    },
    {
      name: 'Ineligibility Funding Status',
      expanded: false,
      fields: [
        {
          name: 'Funding Ineligibility Status',
          key: 'fundingEligibility',
          valuePath: 'ineligibilityFundingStatus.status',
          selected: false,
          exportColumn: {
            jsonPath: '$.ineligibilityFundingStatus.status'
          }
        },
        {
          name: 'Funding Ineligibility Begin Date',
          key: 'ineligibilityFundingBeginDate',
          valuePath: 'ineligibilityFundingStatus.beginDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.ineligibilityFundingStatus.beginDate'
          }
        },
        {
          name: 'Funding Ineligibility End Date',
          key: 'ineligibilityFundingEndDate',
          valuePath: 'ineligibilityFundingStatus.endDate',
          selected: false,
          exportColumn: {
            jsonPath: '$.ineligibilityFundingStatus.endDate'
          }
        }
      ]
    }
  ];