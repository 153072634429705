import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { ColumnDefinition, District, Pagination, School, SchoolCalendar, SchoolQuery, SchoolQueryExcelExportRequest, Sorting } from 'app/dto';
import { HttpParamsUtilities } from 'app/utilities/http-params-utilities/http-params-utilities';
import { PagedResponse } from 'app/models';
import dayjs from 'dayjs';
import { LoggingService } from '@tdoe/design-system';
import { Category } from '../additional-info/additional-info.model';
import { CategoryConfig } from 'app/dto/categoryConfig';
import { SchoolExportRequest } from 'app/dto/schoolExportRequest';

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  private logger = inject(LoggingService);
  
  public constructor(private http: HttpClient) {}

  public static SchoolUrl = environment.apiBaseUrl + 'School/School';
  public static SchoolsUrl = environment.apiBaseUrl + 'School/Schools';
  public static DistrictUrl = environment.apiBaseUrl + 'School/District';
  public static DistrictsUrl = environment.apiBaseUrl + 'School/Districts';
  public static SchoolsExcelExportUrl = environment.apiBaseUrl + 'School/export/excel';

  public getSchools(districtIds: string[]): Observable<School[]> {
    if ((districtIds?.length ?? 0) === 0)
      throw new Error('districtId is required.');

    const httpParams = HttpParamsUtilities.toHttpParams({
      districtId: districtIds,
    });

    return this.http.get<School[]>(SchoolService.SchoolsUrl, {
      params: httpParams,
    });
  }

  public getDistricts(districtIds?: string[]): Observable<District[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({
      districtId: districtIds ?? [],
    });

    return this.http.get<District[]>(SchoolService.DistrictsUrl, {
      params: httpParams,
    });
  }

  public getSchool(schoolId: string): Observable<School> {
    return this.http.get<School>(SchoolService.SchoolUrl, {
      params: { schoolId },
    });
  }

  public getDistrict(districtId: string): Observable<District> {
    return this.http.get<District>(SchoolService.DistrictUrl, {
      params: { districtId },
    });
  }

  public searchSchools(
    schoolQuery: SchoolQuery,
    pagination: Pagination,
    sorting: Sorting
  ): Observable<PagedResponse<School>> {
    const httpParams = HttpParamsUtilities.toHttpParams(
      schoolQuery,
      pagination,
      sorting
    );
    return this.http.get<PagedResponse<School>>(
      `${environment.apiBaseUrl}School/search`,
      { params: httpParams }
    );
  }

  public getSchoolsExcelExportBySearchTerms(
    query: SchoolQuery,
    columnDefinitions: ColumnDefinition[], format: 'csv' | 'xlsx'
  ): Observable<File> {

    const dateFormat = 'YYYY-MM-DD';

    if (!query.years) {
      query.years = [dayjs().year()];
    }

    query.years = HttpParamsUtilities.ensureNumeric(query.years!, true) as number[];

    const requestBody: SchoolQueryExcelExportRequest = {
      searchTerms: query,
      columnDefinitions,
      format
    };
    return this.http.post<Blob>(
      SchoolService.SchoolsExcelExportUrl,
      requestBody,
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        map(
          (response) =>
            new File(
              [response.body!],
                `schools-export-${new Date().toISOString().slice(0, 10)}.${format}`
            )
        )
      );
  }

  public getCalendar(schoolId: string, schoolYear: number, calendarNumber: string): Observable<SchoolCalendar> {
    return this.http.get<SchoolCalendar>(`${environment.apiBaseUrl}School/${schoolId}/${schoolYear}/calendars/${calendarNumber}`);
  }

  public exportPdf(id: string, additionalInfo?: Category[], calendar?: string, period?: number): Observable<File> {

    const dataCategories: CategoryConfig[] = [
      {
        name: 'School Directory Info',
        fields: [
          {
            displayName: 'School Name and Number',
            jsonPath: '$.display'
          },
          {
            displayName: 'Principal Name',
            jsonPath: '$.additionalInformation.directoryInfo.principalName'
          },
          {
            displayName: 'Principal Email Address',
            jsonPath: '$.additionalInformation.directoryInfo.principalEmailAddress'
          },
          {
            displayName: 'Office Number',
            jsonPath: '$.additionalInformation.directoryInfo.officeNumber'
          },
          {
            displayName: 'Address',
            jsonPath: '$.additionalInformation.directoryInfo.address'
          },
          {
            displayName: 'City',
            jsonPath: '$.additionalInformation.directoryInfo.city'
          },
          {
            displayName: 'State / Zip Code',
            jsonPath: '$.stateZip'
          },
          {
            displayName: 'Grade',
            jsonPath: '$.additionalInformation.directoryInfo.grade'
          },
          {
            displayName: 'Programs',
            jsonPath: '$.additionalInformation.directoryInfo.programs'
          },
          {
            displayName: 'Web Address',
            jsonPath: '$.additionalInformation.directoryInfo.webAddress'
          }
        ]
      },
      {
        name: 'School Calendar Info',
        jsonPath: '$.calendars',
        isCalendar: true
      }
    ];

    if (additionalInfo) {
      dataCategories.push(...additionalInfo.map(_ => ({
        name: _.name,
        jsonPath: _.valuePath,
        fields: _.fields.map(f => ({
          displayName: f.name,
          jsonPath: f.exportColumn?.jsonPath
        }))
      })));
    }

    const body: SchoolExportRequest = {
      categories: dataCategories,
      selectedCalendar: calendar,
      selectedReportingPeriod: period
    };

    const url = `${environment.apiBaseUrl}School/export/pdf/${id}`;
    
    this.logger.debug('SchoolService -> exportPdf', {
      data: {
        id,
        additionalInfo,
        url,
        dataCategories
      }
    });

    return this.http.post<Blob>(url, body,
      {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        observe: 'response'
      }
    ).pipe(
      map(response => new File([response.body!], `school-export-${dayjs().format('YYYY-MM-DD')}.pdf`))
    );
  }

}
