import { FormlyFieldConfig } from '@ngx-formly/core';
import { ColumnDefinition } from 'app/dto';

export * as AdditionalInfoModel from './additional-info.model';

export interface Category {
    name: string,
    expanded: boolean,
    fields: Field[],
    /** Add a key if the categories data is a one to many relationship.*/
    key?: string,
    valuePath?: string
}

export interface Field {
    filter?: FormlyFieldConfig;
    valuePath?: string;
    name: string;
    key: string;
    selected: boolean;
    isStatic?: boolean;
    exportColumn?: ColumnDefinition;
}