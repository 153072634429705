import { ColumnDefinition } from '../../../../dto/columnDefinition';
import { DataType } from 'app/dto/dataType';

export const StaffTableExcelExportConfig: Record<string, Pick<ColumnDefinition, 'dataType'>> = {
  assignmentBeginDate: {
    dataType: DataType.Date,
  },
  assignmentEndDate: {
    dataType: DataType.Date,
  },
  'staffAssignment.assignmentStart': {
    dataType: DataType.Date,
  },
  'staffAssignment.assignmentEnd': {
    dataType: DataType.Date,
  },
  'staffAssignment.classStart': {
    dataType: DataType.Date,
  },
  'staffAssignment.classEnd': {
    dataType: DataType.Date,
  },
  'staffAssignment.periodDuration': {
    dataType: DataType.Number,
  },
  experienceInYears: {
    dataType: DataType.Number,
  },
  conferralDate: {
    dataType: DataType.Date,
  },
  addedToFile: {
    dataType: DataType.Date,
  },
  'additionalInfo.staffMemberLicensureDetails.dateIssued': {
    dataType: DataType.Date,
  },
  'additionalInfo.staffMemberLicensureDetails.expirationDate': {
    dataType: DataType.Date,
  },
  'additionalInfo.staffMemberLicensureDetails.renewedDate': {
    dataType: DataType.Date,
  },
  'additionalInfo.staffMemberLicensureDetails.numberOfRenewals': {
    dataType: DataType.Number,
  },
};
