/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { provideMSAL, TdoeButtonDirective, TdoeFooterComponent, TdoeFormlyTypes, TdoeHeaderComponent, TdoeLayoutComponent, TdoeToastComponent } from '@tdoe/design-system';
import { ToastrModule } from 'ngx-toastr';
import { TimeagoModule } from 'ngx-timeago';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module'; import { LandingComponent } from './components/home/landing/landing.component';
import { HeroAboutComponent } from './components/home/landing/hero-about/hero-about.component';
import { HeroWelcomeComponent } from './components/home/landing/hero-welcome/hero-welcome.component';
import { AboutComponent } from './components/home/about/about.component';

import { ContentCardComponent } from './components/shared/content-card/content-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { HeroComponent } from './components/shared/hero/hero.component';
import { ProgramParticipationOneComponent } from './components/home/program-participation/program-participation.component';
import { GradeLevelComponent } from './components/home/grade-level/grade-level.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from 'environments/environment';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularSvgIconModule, provideAngularSvgIcon } from 'angular-svg-icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideFeatureFlag } from './services/feature-flag/feature-flag.provider';

const designSystemComponents = [
    TdoeLayoutComponent,
    TdoeHeaderComponent,
    TdoeFooterComponent,
    TdoeButtonDirective
];

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        HeroAboutComponent,
        HeroWelcomeComponent,
        AboutComponent,
        ContentCardComponent,
        HeroComponent,
        ProgramParticipationOneComponent,
        GradeLevelComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        TimeagoModule.forRoot(),
        ToastrModule.forRoot({
            toastComponent: TdoeToastComponent
        }),
        MsalModule,
        RouterModule.forRoot([]),
        MatCardModule,
        MatExpansionModule,
        MatIconModule,
        MatSelectModule,
        MatTooltipModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        NgxSpinnerModule,
        AngularSvgIconModule.forRoot(),
        MatSidenavModule,
        MatButtonModule,
        MatInputModule,
        ReactiveFormsModule,
        FormlyModule.forRoot({
            types: TdoeFormlyTypes
        }),
        designSystemComponents
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        provideHttpClient(
            withInterceptorsFromDi()
        ),
        provideAngularSvgIcon(),
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { 
                subscriptSizing: 'dynamic',
                appearance: 'outline'
            }
        },
        provideMSAL(environment.authConfig),
        provideAnimations(),
        provideFeatureFlag()
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})

export class AppModule { }
