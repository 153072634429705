import { Component, computed, input, output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {
  TdoeAccordionDirective,
  TdoeButtonDirective,
} from '@tdoe/design-system';
import { MatExpansionModule } from '@angular/material/expansion';
import { StudentSearchTerms } from 'app/dto';
import { DATE_FORMAT_PROVIDERS } from 'app/services/config/date-format.service';

@Component({
  selector: 'app-student-filter',
  templateUrl: './student-filter.component.html',
  styleUrl: './student-filter.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    NgxSkeletonLoaderModule,
    MatDatepickerModule,
    FormlyMatDatepickerModule,
    MatNativeDateModule,
    TdoeButtonDirective,
    TdoeAccordionDirective,
    MatExpansionModule,
  ],
  host: {
    class: 'student-filter',
  },
  providers: [...DATE_FORMAT_PROVIDERS],
})
export class StudentFilterComponent {
  public searchClicked = output<StudentSearchTerms>();

  public studentSearchTerms = input<StudentSearchTerms>({});

  public combinedSearchTerms = computed(() => ({
    ...this.studentSearchTerms(),
  }));

  protected formlyFormGroup = new FormGroup({});

  protected formlyFieldConfigs: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'nameLast',
          type: 'tdoe-input',
          props: {
            label: 'Last Name',
            type: 'text',
            appearance: 'outline',
          },
          className: 'text-field',
        },
        {
          key: 'nameFirst',
          type: 'tdoe-input',
          props: {
            label: 'First Name',
            type: 'text',
            appearance: 'outline',
          },
          className: 'text-field',
        },
        {
          key: 'nameMiddle',
          type: 'tdoe-input',
          props: {
            label: 'Middle Name',
            type: 'text',
            appearance: 'outline',
          },
          className: 'text-field',
        },
        {
          key: 'ssid',
          type: 'tdoe-input',
          props: {
            label: 'SSID',
            type: 'text',
            appearance: 'outline',
          },
          className: 'ssid-field',
        },
        {
          key: 'grades',
          type: 'tdoe-select',
          props: {
            multiple: true,
            label: 'Grade',
            options: [
              { label: 'K', value: 'K' },
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
              { label: '8', value: '8' },
              { label: '9', value: '9' },
              { label: '10', value: '10' },
              { label: '11', value: '11' },
              { label: '12', value: '12' },
            ],
            appearance: 'outline',
          },
          className: 'grade-field',
        },
        {
          key: 'dateOfBirth',
          type: 'tdoe-date',
          props: {
            label: 'Date of Birth',
            appearance: 'outline',
          },
          className: 'date-field',
        },
      ],
    },
  ];

  protected onSearchClick(): void {
    this.searchClicked.emit(
      ObjectUtilities.removeFalsyStringProperties(this.formlyFormGroup.value)
    );
  }
}
