import { ColumnDefinition } from '../../../../dto/columnDefinition';
import { DataType } from 'app/dto/dataType';
export const ClassTableExcelExportConfig: Record<string, Pick<ColumnDefinition, 'dataType'>> = {
  classStartDate: {
    dataType: DataType.Date
  },
  classEndDate: {
    dataType: DataType.Date
  },
};
