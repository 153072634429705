import {
  Component,
  computed,
  effect,
  inject,
  Input,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import {
  ChipItem,
  LoggingService,
  TdoeButtonDirective,
  TdoeChipsComponent,
} from '@tdoe/design-system';
import { ColumnDefinition, Pagination, SortDirection, Sorting } from 'app/dto';
import { Dictionary, PagedResponse } from 'app/models';
import {
  AdditionalInfoModel,
  Field,
} from 'app/services/additional-info/additional-info.model';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { DatePipe } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  Observable,
  tap,
} from 'rxjs';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { TdoeBoolDisplayPipe } from 'app/pipes/bool-display/tdoe-bool-display.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import dayjs from 'dayjs';

@Component({
  selector: 'app-data-lookup-table',
  standalone: true,
  imports: [
    MatSortModule,
    NgxSkeletonLoaderModule,
    MatTableModule,
    ReactiveFormsModule,
    FormlyModule,
    RouterModule,
    MatMenuModule,
    AdditionalInfoComponent,
    TdoeChipsComponent,
    TdoeButtonDirective,
    MatPaginatorModule,
    MatProgressBarModule,
  ],
  templateUrl: './data-lookup-table.component.html',
  styleUrl: '../styles/table.component.scss',
})
export class DataLookupTableComponent<TData, TQuery> {
  private readonly logger = inject(LoggingService);
  private readonly router = inject(Router);

  public readonly additionalInfoContextKey = input.required<string>();
  public readonly additionalInfoFields = input<AdditionalInfoModel.Category[]>(
    []
  );
  public readonly chipIgnorables = input<string[]>([]);
  public readonly clickRoute = input.required<string>();
  public readonly enableColumnFilters = input<boolean>(false);
  public readonly exportConfiguration =
    input<Record<string, Pick<ColumnDefinition, 'dataType'>>>();
  public readonly identifierPath = input.required<string>();
  public readonly isExporting = input<boolean>(false);
  public readonly pagedData = input.required<PagedResponse<TData>>();
  public readonly staticColumns =
    input.required<{ key: string; label: string }[]>();
  public readonly tableConfig = input.required<{
    [key: string]: FormlyFieldConfig;
  }>();
  public readonly tableHeading = input<string>();

  @Input({ required: true }) public displayNameFormatter!: (
    item: TData
  ) => string;

  public exportClicked = output<{
    query: TQuery;
    columns: ColumnDefinition[];
    format: 'csv' | 'xlsx';
  }>();
  public pageChanged = output<Pagination>();
  public sortClicked = output<Sorting>();
  public resetQuery = output<TQuery>();

  public readonly query = model.required<TQuery>();

  public readonly dynamicColumns = signal<AdditionalInfoModel.Field[]>([]);

  public readonly formlyFormGroup = new FormGroup({});

  public state: { [k: string]: any } | undefined;

  public constructor() {
    const state = this.router.getCurrentNavigation()?.extras?.state;

    this.logger.debug('DataLookupTableComponent -> constructor', {
      data: {
        state,
      },
    });

    effect(() => {
      this.state = {
        query: this.query(),
      };
      this.logger.debug('DataLookupTableComponent -> constructor -> effect', {
        data: {
          state: this.state,
        },
      });
    });
  }

  public displayedColumns = computed(() => [
    ...this.staticColumns().map((col) => col.key),
    ...this.dynamicColumns().map((field) => field.key),
  ]);

  public mappedColumns = computed(() => [
    ...this.staticColumns().map((col) => col.key),
    ...this.dynamicColumns().map((field) => field.valuePath ?? field.key),
  ]);

  public filterChipItems = computed(() =>
    Object.entries(this.query()!)
      .map(([key, value]) => ({ key, value }))
      .filter((kvp) => !!kvp.value && !this.chipIgnorables().includes(kvp.key))
      .map((entry) => {
        let formattedValue = entry.value;
        const config = this.tableConfig()[entry.key];
        if (config?.type === 'tdoe-date') {
          formattedValue = dayjs().format('YYYY-MM-DD');
        }
        return {
          text: `${this.getChipText(entry.key)}: '${formattedValue}'`,
          persistent: false,
          key: entry.key,
          id: entry.key,
        } as ChipItem;
      })
  );

  public filterChipItemRemoved(filterChipItem: ChipItem): void {
    this.removeSearchTerm(filterChipItem.id);
  }

  public getChipText(columnName: string): string {
    this.logger.debug('getChipText', {
      data: {
        columnName,
        _: this.tableConfig(),
      },
    });
    return this.tableConfig()[columnName]?.props?.label ?? columnName;
  }

  public getColumnFilterConfig(key: string): FormlyFieldConfig[] {
    const config = this.columnFilterConfigs()[key];
    if (!config) {
      // Do not delete: this will warn us if there are any missing or misspelled keys.
      console.warn('Could not find formly config for key:', key);
      return [];
    }
    return config;
  }

  public getRouteState(item: TData): { [k: string]: any } | undefined {
    return {
      ...this.state,
      item,
    };
  }

  public onAdditionalInfoSelectionChanged(
    categories: AdditionalInfoModel.Category[]
  ): void {
    const selectedAdditionalInfoFields = categories
      .flatMap((additionalInfoCategory) => additionalInfoCategory.fields)
      .filter((field) => field.selected)
      .map((field) => field);

    this.dynamicColumns.set(selectedAdditionalInfoFields);
  }

  public onExportToExcelClicked(format: 'csv' | 'xlsx'): void {
    this.logger.debug('onExportToExcelClicked', {
      data: {
        _: this,
      },
    });
    this.exportClicked.emit({
      query: this.query(),
      columns: this.getExcelExportColumnDefinitions(),
      format
    });
  }

  public onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit({
      pageSize: pageEvent.pageSize,
      pageIndex: pageEvent.pageIndex,
    } as Pagination);
  }

  public onResetFiltersClick(): void {
    this.resetQuery.emit({} as TQuery);
  }

  public onSortChanged(sort: Sort): void {
    this.currentSort = {
      sortColumn: sort.active,
      sortDirection: sort.direction,
    };
    this.logger.debug('DataLookupTableComponent -> onSortChanged', {
      data: { sort, d: this.dynamicColumns() },
    });
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction,
    } as Sorting);
  }

  public resolveNestedProperty(item: TData, path: string): string {
    return ObjectUtilities.getNestedProperty(item, path) as string;
  }

  public resolveNestedValue(item: unknown, col: Field): unknown {
    let result = ObjectUtilities.getNestedProperty(
      item,
      col.valuePath ?? col.key
    );

    if (typeof result == 'boolean') {
      result = this.boolPipe.transform(result, 'Yes', 'No');
    }

    return result;
  }

  private addFilterItemToSearchTerms(
    field: FormlyFieldConfig,
    value: unknown
  ): void {
    this.query.update((searchTerms) => ({
      ...searchTerms,
      [field.key as string]: value,
    }));
  }

  private boolPipe = new TdoeBoolDisplayPipe();

  private readonly columnFilterConfigs = computed(() => {
    const configs: { [key: string]: FormlyFieldConfig[] } = {};

    // Initialize all configs once
    Object.entries(this.tableConfig()).forEach(([key, field]) => {
      const configField: FormlyFieldConfig = { ...field };

      if (configField && !configField.hooks?.onInit) {
        configField.hooks = {
          ...configField.hooks,
          onInit: (field: FormlyFieldConfig): void =>
            this.onFormlyFieldInit(field),
        };
      }

      configs[key] = [configField];
    });

    return configs;
  });

  private currentSort?: Sorting;

  private getColumnDisplay(key: string): string {
    const staticColumn = this.staticColumns().find((_) => _.key === key);
    if (staticColumn) {
      return staticColumn.label;
    }

    const dynamicColumn = this.dynamicColumns().find(
      (_) => _.valuePath === key || _.key === key
    );
    return dynamicColumn?.name ?? key;
  }

  private getExcelExportColumnDefinitions(): ColumnDefinition[] {
    return this.mappedColumns().map((columnKey) => {
      const hasSort = this.currentSort?.sortColumn === columnKey;
      return {
        propertyName: columnKey,
        displayName: this.getColumnDisplay(columnKey),
        ...(hasSort &&
          !!this.currentSort?.sortDirection && {
            sortDirection: this.getSortDirection(
              this.currentSort!.sortDirection!
            ),
          }),
      } as ColumnDefinition;
    });
  }

  private getSortDirection(sortDirection: string): SortDirection {
    switch (sortDirection) {
      case 'asc':
        return 'Ascending';
      case 'desc':
      default:
        return 'Descending';
    }
  }

  private onFormlyFieldInit(field: FormlyFieldConfig): void {
    if (!field.formControl || (field as any)._initialized) {
      return;
    }

    (field as any)._initialized = true;

    field.formControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((val) => {
          if (val) {
            if (field.type === 'tdoe-date') {
              const datePipe = new DatePipe('en-US');
              const transformedDate = datePipe.transform(val, 'yyyy-MM-dd');
              if (transformedDate) {
                val = transformedDate;
              }
            }
            this.addFilterItemToSearchTerms(field, val);
          } else {
            this.removeSearchTerm(field.key as string);
          }
        }),
        catchError((err) => {
          console.error('Error in form field processing', err);
          return new Observable();
        })
      )
      .subscribe();
  }

  private removeSearchTerm(filterItemKey: string): void {
    const updatedSearchTerms: Dictionary = {
      ...ObjectUtilities.removeFalsyStringProperties(this.query() as object),
    };
    delete updatedSearchTerms[filterItemKey];
    this.resetQuery.emit(updatedSearchTerms as TQuery);
  }
}
