import { Injectable } from '@angular/core';
import { AdditionalInfoModel, Category } from './additional-info.model';
import { Observable, of } from 'rxjs';
export { AdditionalInfoModel } from './additional-info.model';

interface ExtendedCategory extends AdditionalInfoModel.Category {
  isArray?: boolean;
  dataPath?: string;
  alwaysShow?: boolean;
}

interface ExtendedField extends AdditionalInfoModel.Field {
  isStatic?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AdditionalInfoService {
  public static readonly SelectedFieldState = 'selectedFieldState';

  public saveFieldStateToLocalStorage(categories: AdditionalInfoModel.Category[], contextKey: string): void {
    try {
      // const sanitizedCategories = this.sanitizeCategories(categories);
      localStorage.setItem(this.getStorageKey(contextKey), JSON.stringify(categories));
    } catch (error) {
      console.error('Error saving field state to local storage:', error);
    }
  }

  public saveFieldStateToSessionStorage(categories: AdditionalInfoModel.Category[], contextKey: string): void {
    try {
      // const sanitizedCategories = this.sanitizeCategories(categories);
      sessionStorage.setItem(this.getStorageKey(contextKey), JSON.stringify(categories));
    } catch (error) {
      console.error('Error saving field state to session storage:', error);
    }
  }

  public async resetFieldState(contextKey: string, categories: Category[]): Promise<void> {
    sessionStorage.removeItem(this.getStorageKey(contextKey));
    this.saveFieldStateToSessionStorage(categories, contextKey);
    Promise.resolve();
  }

  /** @deprecated  use getFieldStateNew instead*/
  public getFieldState(contextKey: string): Observable<AdditionalInfoModel.Category[] | undefined> {
    const sessionStorageData = this.getSessionFieldState(contextKey);
    if (sessionStorageData) {
      return sessionStorageData;
    }

    const localStorageData = this.getSavedFieldState(contextKey);
    if (localStorageData) {
      return localStorageData;
    }

    return of(undefined);
  }

  public getFieldStateNew(contextKey: string): AdditionalInfoModel.Category[] | undefined {
    const sessionStorageData = this.getSessionFieldStateNew(contextKey);
    if (sessionStorageData) {
      return sessionStorageData;
    }

    const localStorageData = this.getSavedFieldStateNew(contextKey);
    if (localStorageData) {
      return localStorageData;
    }

    return undefined;
  }

  /** @deprecated use getSessionFieldStateNew instead */
  public getSessionFieldState(contextKey: string): Observable<AdditionalInfoModel.Category[]> | undefined {
    const data = sessionStorage.getItem(this.getStorageKey(contextKey));
    if (data) {
      return of(JSON.parse(data) as AdditionalInfoModel.Category[]);
    }
    return undefined;
  }

  public getSessionFieldStateNew(contextKey: string): AdditionalInfoModel.Category[] | undefined {
    const data = sessionStorage.getItem(this.getStorageKey(contextKey));
    if (data) {
      return JSON.parse(data) as AdditionalInfoModel.Category[];
    }
    return undefined;
  }

  /** @deprecated  use getSavedFieldStateNew instead */
  public getSavedFieldState(contextKey: string): Observable<AdditionalInfoModel.Category[]>  | undefined {
    const data = localStorage.getItem(this.getStorageKey(contextKey));
    if (data) {
      return of(JSON.parse(data) as AdditionalInfoModel.Category[]);
    }
    return undefined;
  }

  public getSavedFieldStateNew(contextKey: string): AdditionalInfoModel.Category[]  | undefined {
    const data = localStorage.getItem(this.getStorageKey(contextKey));
    if (data) {
      return JSON.parse(data) as AdditionalInfoModel.Category[];
    }
    return undefined;
  }

  private getStorageKey(contextKey: string): string {
    return `${contextKey}:${AdditionalInfoService.SelectedFieldState}`;
  }

}
