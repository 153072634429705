/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { EthnicitySummary } from 'app/dto';
import { StudentService } from 'app/services/student/student.service';
import { UserModel, UserProfile } from 'app/services/user/user.model';
import { UserService } from 'app/services/user/user.service';
import { EChartsOption, PieSeriesOption } from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { BehaviorSubject, Observable, combineLatest, filter, debounceTime, map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-race',
  standalone: true,
  imports: [CommonModule, NgxEchartsModule],
  templateUrl: './race.component.html',
  styleUrl: './race.component.scss'
})
export class RaceComponent {
  private readonly studentService = inject(StudentService);
  private readonly userService = inject(UserService);

  @Input({required: true})
  public set year(value: number) {
    this.yearSubject$.next(value);
  }

  private yearSubject$ = new BehaviorSubject<number | undefined>(undefined);

  private colorPalette = ['#FF0000','#002d72','#cecf25','#4594ce','#00FFFF','#ff8b0f']; 

  private chartOptions: EChartsOption = {
    title: {
      text: 'Race',
      left: 'left',
      top: 5,
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      bottom: 10,
      itemGap: 15,
    },     
    grid: {
      top: 60,
      bottom: 60,
      left: '10%',
      right: '10%'
    }
  };

  private pieSeriesOption: PieSeriesOption =  {
    name: 'Race',
    type: 'pie',
    radius: ['35%', '50%'],
    center: ['50%','40%'],
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: 'center'
    },
    labelLine: {
      show: false
    }
  };

  protected viewModel$: Observable<any>  =
    combineLatest([
      this.userService.userProfile$,
      this.yearSubject$.pipe(filter(pageScope => pageScope !== undefined))
    ]).pipe(
      debounceTime(300),
      map(([userProfile, year]) => ({userProfile, year})),
      mergeMap(data => this.getStudentEthnicitySummary(data.year, data.userProfile).pipe(
        map(ethnicitySummaries => ({
          chartOptions: {
            ...this.chartOptions,
            legend: {
              ... this.chartOptions.legend,
              formatter: (name: string): string => {
                return this.getLegendLabel(name, ethnicitySummaries);
              },
              data: ethnicitySummaries                
                // sorts the legend by count ascending 
                .sort((a, b) => (b.count as number) - (a.count as number) )
                .map(ethnicitySummary => ethnicitySummary.ethnicity)
            },
            series: this.getPieSeries(ethnicitySummaries)
          }
        }))
      ))
    );

    public getStudentEthnicitySummary(year: number, userProfile?: UserProfile) : Observable<EthnicitySummary[]> {
      if (userProfile?.isStateUser) {
        return this.studentService.getStateEthnicitySummary(year);
      } else if ((userProfile?.getScopeIds('district')?.length ?? 0) > 0) {
        return this.studentService.getDistrictEthnicitySummary(year, userProfile?.getScopeIds('district')!);
      } else {
        return this.studentService.getSchoolEthnicitySummary(year, userProfile?.getScopeIds('school')!);
      }
    }

    private getPieSeries(ethnicitySummaries: EthnicitySummary[]): PieSeriesOption {
      this.pieSeriesOption.data = ethnicitySummaries
        .map((ethnicitySummary, index)=> ({
          value: ethnicitySummary.count,
          name: ethnicitySummary.ethnicity,
          itemStyle: (index < this.colorPalette.length) ? {
            color: this.colorPalette[index]
          } : undefined
        }));

      return this.pieSeriesOption;
    }

    private getLegendLabel(name: string, ethnicitySummaries: EthnicitySummary[]): string {
      const data = ethnicitySummaries.find((item) => item.ethnicity === name);
      const total = ethnicitySummaries.reduce((acc, item) => acc + (item.count as number), 0);
      const percentage = ((data?.count ?? 0) / total) * 100;
      return `${name}: ${percentage.toFixed(2)}%`;
    }
}
