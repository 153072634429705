/* eslint-disable @typescript-eslint/no-explicit-any */

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SsidFormatPipe } from 'app/pipes/ssid-format/ssid-format.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { Router } from '@angular/router';
import { TableDataModel } from 'app/models/table-data-model';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, map, tap } from 'rxjs';
import { ChipItem, TdoeDsModule } from '@tdoe/design-system';
import { AdditionalInfoModel, Category } from 'app/services/additional-info/additional-info.model';
import { PageScope } from 'app/enums/page-scope';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FilterModalComponent } from './filter-modal/filter-modal/filter-modal.component';
import dayjs from 'dayjs';
import { Student } from 'app/dto';

@Component({
  selector: 'app-student-table',
  templateUrl: './student-table.component.html',
  styleUrl: './student-table.component.scss',
  standalone: true,
  imports: [
    AdditionalInfoComponent, 
    CommonModule, 
    DatePipe, 
    FormsModule, 
    MatMenuModule, 
    MatPaginatorModule, 
    MatTableModule, 
    SsidFormatPipe,
    TdoeDsModule,
    MatSortModule,
    FormlyModule,
    FormlySelectModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    MatDialogModule,
    TdoeDsModule
  ],
  host: { class: 'sword-table' } 
})
export class StudentTableComponent implements AfterViewInit {

  @Input({required: true})
  public set totalRecords( totalRecords: number) {
    this._totalRecordsSubject$.next(totalRecords);
    this._changeDetector.markForCheck();
  }

  @Input({required: true})
  public set students(students: Student[]) {
    this._studentSubject$.next(students);
    this._changeDetector.markForCheck();
  }

  @Input()
  public set pageScope(pageScope: PageScope){
    this._pageScopeSubject$.next(pageScope);
    this._changeDetector.markForCheck();
  }

  @Input()
  public set selectedDistrictIds(value: string[] | undefined) {
    this._selectedDistrictIds$.next(value);
  }

  @Input()
  public set selectedSchoolIds(value: string[] | undefined) {
    this._selectedSchoolIds$.next(value);
  }

  @Output()
  public pageChanged = new EventEmitter<TableDataModel.Pagination>();

  @Output()
  public sortClicked = new EventEmitter<TableDataModel.Sorting>();

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort)
  public sort!: MatSort;

  @ViewChild(AdditionalInfoComponent)
  protected additionalInfoComponent!: AdditionalInfoComponent;

  private readonly _selectedDistrictIds$ = new BehaviorSubject<string[] | undefined>(undefined);
  private readonly _selectedSchoolIds$ = new BehaviorSubject<string[] | undefined>(undefined);
  private readonly _totalRecordsSubject$ = new BehaviorSubject<number>(0);
  private readonly _studentSubject$ = new BehaviorSubject<Student[]>([]);
  private readonly _dynamicColumnsSubject$ = new BehaviorSubject<AdditionalInfoModel.Field[]>([]);
  private readonly _pageScopeSubject$ = new BehaviorSubject<PageScope>(PageScope.State);
  private readonly _dataSource = new MatTableDataSource<Student>();
  private readonly _columns = [ 'name', 'ssid', 'dateOfBirth', 'grade', 'enrollmentStartDate', 'enrollmentEndDate', 'code'];

  protected filterChips?: ChipItem[];
  protected showFilters = false;

  public additionalInfoFields: Category[] = [
    {
      name: 'Enrollment Info',
      expanded: true,
      fields: [
        {
          name: 'Enrollment start date',
          key: 'enrollmentStartDate',
          selected: false,
          filter: {
            key: 'enrollmentStartDate',
            type: 'datepicker',
            props: {
              label: 'Enrollment start date'
            }
          }
        },
        {
          name: 'Enrollment code',
          key: 'enrollmentCode',
          selected: false,
          filter: {
            key: 'enrollmentCode',
            type: 'input',
            props: {
              label: 'Enrollment code'
            }
          }
        },
        {
          name: 'Enrollment classification',
          key: 'enrollmentClassification',
          selected: false,
          filter: {
            key: 'enrollmentClassification',
            type: 'input',
            props: {
              label: 'Enrollment classification'
            }
          }
        },
        {
          name: 'TOS',
          key: 'tos',
          selected: false,
          filter: {
            key: 'tos',
            type: 'input',
            props: {
              label: 'TOS'
            }
          }
        },
        {
          name: 'Funding eligibility',
          key: 'fundingEligibility',
          selected: false,
          filter: {
            key: 'fundingEligibility',
            type: 'input',
            props: {
              label: 'Funding eligibility'
            }
          }
        },
        {
          name: 'Withdraw',
          key: 'withdraw',
          selected: false,
          filter: {
            key: 'withdraw',
            type: 'input',
            props: {
              label: 'Withdraw'
            }
          }
        },
        {
          name: 'Enrollment start date',
          key: 'enrollmentStartDate',
          selected: false,
          filter: {
            key: 'enrollmentStartDate',
            type: 'input',
            props: {
              label: 'Enrollment start date'
            }
          }
        }
      ]
    },
    {
      name: 'Attendance',
      expanded: true,
      fields: [
        {
          name: 'Attendance Date',
          key: 'attendanceDate',
          selected: false,
          filter: {
            key: 'attendanceDate',
            type: 'datepicker',
            props: {
              label: 'Attendance date'
            }
          },
        },
        {
          name: 'Attendance Type',
          key: 'attendanceType',
          selected: false,
          filter: {
            key: 'attendanceType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'A',
                  value: 'A',
                },
                {
                  label: 'H',
                  value: 'H',
                },
                {
                  label: 'I',
                  value: 'I',
                },
                {
                  label: 'Y',
                  value: 'Y',
                },
                {
                  label: 'Z',
                  value: 'Z',
                },
              ],
              multiple: true,
              label: 'Attendance type'
            },
          },
        },
        {
          name: 'Label',
          key: 'label',
          selected: false,
          filter: {
            key: 'label',
            type: 'input',
            props: {
              type: 'text',
              label: 'Label'
            },
          },
        },
      ],
    },
    {
      name: 'Class Assignment',
      expanded: true,
      fields: [
        {
          name: 'Local Class Number',
          key: 'localClassNumber',
          selected: false,
          filter: {
            key: 'localClassNumber',
            type: 'input',
            props: {
              type: 'text',
              label: 'Local class number'
            }
          }
        },
        {
          name: 'Course Code',
          key: 'courseCode',
          selected: false,
          filter: {
            key: 'courseCode',
            type: 'input',
            props: {
              type: 'text',
              label: 'Course code'
            }
          }
        },
        {
          name: 'Class Type',
          key: 'classType',
          selected: false,
          filter: {
            key: 'classType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'C',
                  value: 'C'
                },
                {
                  label: 'P',
                  value: 'P'
                },
                {
                  label: 'T',
                  value: 'T'
                }
              ],
              multiple: true,
              label: 'Class type'
            }
          }
        },
        {
          name: 'Voc. Outside IP',
          key: 'vocOutsideIP',
          selected: false,
          filter: {
            key: 'vocOutsideIP',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'Voc. Outside IP'
            }
          }
        },
        {
          name: 'Enrolment Period Start Date',
          key: 'enrolmentPeriodStartDate',
          selected: false,
          filter: {
            key: 'enrolmentPeriodStartDate',
            type: 'datepicker',
            props: {
              label: 'Enrolment period start date'
            }
          }
        },
        {
          name: 'Enrolment Period End Date',
          key: 'enrolmentPeriodEndDate',
          selected: false,
          filter: {
            key: 'enrolmentPeriodEndDate',
            type: 'datepicker',
            props: {
              label: 'Enrolment period end date'
            }
          }
        },
        {
          name: 'Assignment Start Dates',
          key: 'assignmentStartDates',
          selected: false,
          filter: {
            key: 'assignmentStartDates',
            type: 'datepicker',
            props: {
              label: 'Assignment start dates'
            }
          }
        },
        {
          name: 'Assignment End Dates',
          key: 'assignmentEndDates',
          selected: false,
          filter: {
            key: 'assignmentEndDates',
            type: 'datepicker',
            props: {
              label: 'Assignment end dates'
            }
          }
        },
        {
          name: 'Class Start Dates',
          key: 'classStartDates',
          selected: false,
          filter: {
            key: 'classStartDates',
            type: 'datepicker',
            props: {
              label: 'Class start dates'
            }
          }
        },
      ],
    },
    {
      name: 'Classification',
      expanded: false,
      fields: [
        {
          name: 'Classification',
          key: 'classification',
          selected: false,
          filter: {
            key: 'classification',
            type: 'select',
            props: {
              multiple: true,
              options: [
                {
                  label: 'ID619',
                  value: 'ID619'
                },
                {
                  label: '504',
                  value: '504'
                },
                {
                  label: 'R',
                  value: 'R'
                },
                {
                  label: 'DYS02',
                  value: 'DYS02'
                },
                {
                  label: 'DYS03',
                  value: 'DYS03'
                },
                {
                  label: 'MF',
                  value: 'MF'
                },
                {
                  label: 'MR',
                  value: 'MR'
                },
                {
                  label: 'DYS01',
                  value: 'DYS01'
                },
                {
                  label: 'A',
                  value: 'A'
                }
              ],
              label: 'Classification'
            }
          }
        },
        {
          name: 'Begin Date',
          key: 'beginDate',
          selected: false,
          filter: {
            key: 'beginDate',
            type: 'datepicker',
            props: {
              label: 'Classification begin date'
            }
          }
        },
        {
          name: 'End Date',
          key: 'classificationEndDate',
          selected: false,
          filter: {
            key: 'endDate',
            type: 'datepicker',
            props: {
              label: 'Classification end date'
            }
          }
        }
      ],
    },
    {
      name: 'Final grade',
      expanded: false,
      fields: [
        {
          name: 'Local class number',
          key: 'localClassNumber',
          selected: false,
          filter: {
            key: 'localClassNumber',
            type: 'input',
            props: {
              label: 'Local class number'
            }
          }
        },
        {
          name: 'Course code',
          key: 'courseCode',
          selected: false,
          filter: {
            key: 'courseCode',
            type: 'input',
            props: {
              label: 'Course code'
            }
          }
        },
        {
          name: 'Credits earned',
          key: 'crediteEarned',
          selected: false,
          filter: {
            key: 'crediteEarned',
            type: 'input',
            props: {
              label: 'Credits earned',
              type: 'number'
            }
          }
        },
        {
          name: 'Credits earned',
          key: 'creditsEarned',
          selected: false,
          filter: {
            key: 'creditsEarned',
            type: 'input',
            props: {
              label: 'Credits earned',
              type: 'number'
            }
          }
        },
        {
          name: 'Numeric grade',
          key: 'numericGrade',
          selected: false,
          filter: {
            key: 'numericGrade',
            type: 'input',
            props: {
              label: 'Numeric grade',
              type: 'number'
            }
          }
        }
      ]
    },
    {
      name: 'Special Ed Options',
      expanded: false,
      fields: [
        {
          name: 'Option level',
          key: 'optionLevel',
          selected: false,
          filter: {
            key: 'optionLevel',
            type: 'input',
            props: {
              label: 'Option level'
            }
          }
        },
        {
          name: 'Option number',
          key: 'optionNumber',
          selected: false,
          filter: {
            key: 'optionNumber',
            type: 'input',
            props: {
              label: 'Option number',
              type: 'number'
            }
          }
        },
        {
          name: 'Begin date',
          key: 'specEdBeginDate',
          selected: false,
          filter: {
            key: 'specEdBeginDate',
            type: 'datepicker',
            props: {
              label: 'Begin date'
            }
          }
        },
        {
          name: 'End date',
          key: 'specEdEndDate',
          selected: false,
          filter: {
            key: 'specEdEndDate',
            type: 'datepicker',
            props: {
              label: 'End date'
            }
          }
        }
      ]
    },
    {
      name: 'Special Ed Disabilities',
      expanded: false,
      fields: [
        {
          name: 'Disability level',
          key: 'specEdDisabilityLevel',
          selected: false,
          filter: {
            key: 'specEdDisabilityLevel',
            type: 'input',
            props: {
              label: 'Disability level'
            }
          }
        },
        {
          name: 'Disability type',
          key: 'specEdDisabilityType',
          selected: false,
          filter: {
            key: 'specEdDisabilityType',
            type: 'input',
            props: {
              label: 'Disability type'
            }
          }
        },
        {
          name: 'Begin date',
          key: 'specEdDisabilityBeginDate',
          selected: false,
          filter: {
            key: 'specEdDisabilityBeginDate',
            type: 'datepicker',
            props: {
              label: 'Begin date'
            }
          }
        },
        {
          name: 'End date',
          key: 'specEdDisabilityEndDate',
          selected: false,
          filter: {
            key: 'specEdDisabilityEndDate',
            type: 'datepicker',
            props: {
              label: 'End date'
            }
          }
        }
      ]
    },
    {
      name: 'Standard AMA/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report period',
          key: 'standardReportPeriod',
          selected: false,
          filter: {
            key: 'standardReportPeriod',
            props: {
              label: 'Report period'
            }
          }
        },
        {
          name: 'Student ADM',
          key: 'standardStudentAdm',
          selected: false,
          filter: {
            key: 'standardStudentAdm',
            props: {
              label: 'Student ADM'
            }
          }
        },
        {
          name: 'Student ADA',
          key: 'standardStudentAda',
          selected: false,
          filter: {
            key: 'standardStudentAda',
            props: {
              label: 'Student ADA'
            }
          }
        }
      ]
    },
    {
      name: 'Vocational ADM/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report period',
          key: 'vocationalReportPeriod',
          selected: false,
          filter: {
            key: 'vocationalReportPeriod',
            props: {
              label: 'Report period'
            }
          }
        },
        {
          name: 'Program item',
          key: 'vocationalProgramItem',
          selected: false,
          filter: {
            key: 'vocationalProgramItem',
            props: {
              label: 'Program item'
            }
          }
        },
        {
          name: 'Student ADM',
          key: 'vocationalStudentAdm',
          selected: false,
          filter: {
            key: 'vocationalStudentAdm',
            props: {
              label: 'Student ADM'
            }
          }
        },
        {
          name: 'Student ADA',
          key: 'vocationalStudentAda',
          selected: false,
          filter: {
            key: 'vocationalStudentAda',
            props: {
              label: 'Student ADA'
            }
          }
        }
      ]
    },
    {
      name: 'Club Membership',
      expanded: false,
      fields: [
        {
          name: 'Club ID',
          key: 'clubID',
          selected: false,
          filter: {
            key: 'clubID',
            type: 'input',
            props: {
              type: 'text',
              label: 'Club ID'
            }
          }
        },
        {
          name: 'Begin Date',
          key: 'clubBeginDate',
          selected: false,
          filter: {
            key: 'beginDate',
            type: 'datepicker',
            props: {
              label: 'Club begin date'
            }
          }
        },
        {
          name: 'End Date',
          key: 'clubEndDate',
          selected: false,
          filter: {
            key: 'endDate',
            type: 'datepicker',
            props: {
              label: 'Club end date'
            }
          }
        },
      ],
    },
    {
      name: 'Disciplinary Actions',
      expanded: false,
      fields: [
        {
          name: 'Enrollment Period Start',
          key: 'enrollmentPeriodStart',
          selected: false,
          filter: {
            key: 'enrollmentPeriodStart',
            type: 'datepicker',
            props: {
              label: 'Enrollment period start'
            }
          }
        },
        {
          name: 'Enrollment Period End',
          key: 'enrollmentPeriodEnd',
          selected: false,
          filter: {
            key: 'enrollmentPeriodEnd',
            type: 'datepicker',
            props: {
              label: 'Enrollment period end'
            }
          }
        },
        {
          name: 'Disciplinary Period Begin',
          key: 'disciplinaryPeriodBegin',
          selected: false,
          filter: {
            key: 'disciplinaryPeriodBegin',
            type: 'datepicker',
            props: {
              label: 'Disciplinary period begin'
            }
          }
        },
        {
          name: 'Disciplinary Period End',
          key: 'disciplinaryPeriodEnd',
          selected: false,
          filter: {
            key: 'disciplinaryPeriodEnd',
            type: 'datepicker',
            props: {
              label: 'Disciplinary period end'
            }
          }
        },
        {
          name: 'Disciplinary Type',
          key: 'disciplinaryType',
          selected: false,
          filter: {
            key: 'disciplinaryType',
            type: 'select',
            props: {
              options: [
                {
                  label: 'I',
                  value: 'I'
                },
                {
                  label: 'R',
                  value: 'R'
                }
              ],
              multiple: true,
              label: 'Disciplinary type'
            }
          }
        },
        {
          name: 'Disciplinary Reason',
          key: 'disciplinaryReason',
          selected: false,
          filter: {
            key: 'disciplinaryReason',
            type: 'input',
            props: {
              type: 'text',
              label: 'Disciplinary reason'
            }
          }
        },
        {
          name: 'Sped',
          key: 'sped',
          selected: false,
          filter: {
            key: 'sped',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'SPED'
            }
          }
        },
        {
          name: 'Zero Tolerance',
          key: 'zeroTolerance',
          selected: false,
          filter: {
            key: 'zeroTolerance',
            type: 'select',
            props: {
              options: [
                {
                  label: 'Y',
                  value: 'Y'
                },
                {
                  label: 'N',
                  value: 'N'
                }
              ],
              label: 'Zero tolerance'
            }
          }
        },
      ],
    }
  ];
    
  protected viewModel$ = combineLatest([
      this._studentSubject$, 
      this._totalRecordsSubject$, 
      this._dynamicColumnsSubject$, 
      this._pageScopeSubject$,
      this._selectedDistrictIds$,
      this._selectedSchoolIds$]).pipe(
    map(([students, totalRecords, dynamicColumns, pageScope, selectedDistrictIds, selectedSchoolIds]) => ({students, totalRecords, dynamicColumns, pageScope, selectedDistrictIds, selectedSchoolIds})),
    tap(data => {
      console.debug('data:', data);
      data.dynamicColumns.forEach(col => {
        if (col.filter) {
          col.filter.hooks = {
            ...col.filter.hooks,
            onInit: (field: FormlyFieldConfig): void => {
              field.formControl?.valueChanges.subscribe({
                next: val => this.hookFilter(field, val),
                error: (err) => console.error(err)
              });
            }
          };
        }
      });
    }),
    map(data => {
      this._dataSource.data = data.students;
      return ({
        dataSource: this._dataSource,
        displayedColumns: [
          ...this._columns,
          ...this.getContextColumns(data.pageScope, data.selectedDistrictIds, data.selectedSchoolIds),
          ...data.dynamicColumns.map(field => field.key),
        ],
        totalRecords: data.totalRecords,
        dynamicColumns: data.dynamicColumns,
        selectedDistrictIds: data.selectedDistrictIds,
        selectedSchoolIds: data.selectedSchoolIds
      });
    }),
    tap(viewModel => console.debug('viewModel:', viewModel))
  );

  public constructor(
    private readonly _router: Router,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly dialogs: MatDialog
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    this._dataSource.filterPredicate = (student, filter): boolean => {
      
      const filterContext = JSON.parse(filter);
      
      let matchCount = 0;
      let filterCount = 0;

      Object.keys(filterContext).forEach((value) => {
        filterCount++;
        const filterValue = filterContext[value];
        const studentValue: any = (student as Record<string, any>)[value];

        if (context.compareValues(filterValue, studentValue)) {
          matchCount++;
        }
      });

      return matchCount === filterCount;
    };
  }

  protected formly = new FormGroup({});
  protected filterModel: any = {};

  protected contextFilters: { [key: string]: FormlyFieldConfig } = {
    'grade': {
      key: 'grade',
      type: 'select',
      props: {
        options: [
          {
            label: 'K',
            value: 'K'
          },
          {
            label: '1',
            value: '1'
          },
          {
            label: '2',
            value: '2'
          },
          {
            label: '3',
            value: '3'
          },
          {
            label: '4',
            value: '4'
          },
          {
            label: '5',
            value: '5'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '7',
            value: '7'
          },
          {
            label: '8',
            value: '8'
          },
          {
            label: '9',
            value: '9'
          },
          {
            label: '10',
            value: '10'
          },
          {
            label: '11',
            value: '11'
          },
          {
            label: '12',
            value: '12'
          }
        ],
        label: 'Grade',
        multiple: true
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'ssid': {
      key: 'ssid',
      type: 'input',
      props: {
        type: 'text',
        label: 'SSID'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'name': {
      key: 'name',
      type: 'input',
      props: {
        type: 'text',
        label: 'Name'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'dateOfBirth': {
      key: 'dateOfBirth',
      type: 'datepicker',
      props: {
        label: 'Birth date'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'enrollmentStartDate': {
      key: 'enrollmentStartDate',
      type: 'datepicker',
      props: {
        label: 'Enrollment start date'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'enrollmentEndDate': {
      key: 'enrollmentEndDate',
      type: 'datepicker',
      props: {
        label: 'Enrollment end date'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'district': {
      key: 'district',
      type: 'input',
      props: {
        label: 'District',
        type: 'text'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'code': {
      key: 'code',
      type: 'input',
      props: {
        label: 'Code',
        type: 'text'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    },
    'school': {
      key: 'school',
      type: 'input',
      props: {
        label: 'School',
        type: 'text'
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          field.formControl?.valueChanges.subscribe({
            next: val => this.hookFilter(field, val),
            error: (err) => console.error(err)
          });
        }
      }
    }
  };

  protected getInlineFilter(name: string): FormlyFieldConfig[] {
    const field = this.contextFilters[name];
    delete field.props?.label;
    return [field];
  }

  private compareValues(sourceValue: any, targetValue: any): boolean {
    if (Array.isArray(sourceValue)) {
      if ((sourceValue as string[]).includes(targetValue)) {
        return true;
      }
    } else if (dayjs(sourceValue.toString()).isValid()) {
      if (dayjs(sourceValue).isSame(targetValue, 'day')) {
        return true;
      }
    } else if (typeof sourceValue === 'string') {
      if ((targetValue as string).toLowerCase().includes(sourceValue.toLowerCase())) {
        return true;
      }
    }

    return false;
  }

  protected filterChipRemoved(filter: ChipItem): void {
    this.filterInPlace({ name: 'RESET', key: filter.text.split(':')[0] });
    this.filterChips = this.filterChips?.filter(_ => !_.text.startsWith(filter.text));
  }

  private filterInPlace(field: FormlyFieldConfig): void {
    const key = field.key as string;
    if (field.name !== 'RESET') {
      Object.assign(this.filterModel, field.model);
    } else {
      delete this.filterModel[key];
    }
    this._dataSource.filter = JSON.stringify(this.filterModel);

    if (this.filterModel) {
      this.filterChips = Object.keys(this.filterModel).map(key => ({
        text: `${key}: '${this.filterModel[key]}'`,
        persistent: false
      } as ChipItem));
    } else {
      delete this.filterChips;
    }

  }

  private hookFilter(field: FormlyFieldConfig, val: any): void {
    Object.assign(this.filterModel, { [field.key as string]: val });
    this.filterInPlace(field);
  }

  protected inlineFilter(field: FormlyFieldConfig): FormlyFieldConfig[] {
    if (field.props) {
      delete field.props['appearance'];
      delete field.props.label;
    }
    return [field];
  }

  public ngAfterViewInit(): void {
    this._changeDetector.detectChanges();
  }

  public onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit({ 
      pageSize: pageEvent.pageSize, 
      pageIndex: pageEvent.pageIndex } as TableDataModel.Pagination);
  }

  protected onAdditionalInfoFieldSelected(categories: AdditionalInfoModel.Category[]): void {
    this._dynamicColumnsSubject$.next(
      categories.flatMap(_ => _.fields)
        .filter(field => field.selected));
  }

  protected onFilterClick(e: Event, field?: FormlyFieldConfig): void {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    console.debug({
      e,
      field,
      _this: this
    });

    const dialogRef = this.dialogs.open<FilterModalComponent, FormlyFieldConfig, FormlyFieldConfig>(FilterModalComponent, {
      data: field
    });

    dialogRef.afterClosed().subscribe({
      next: (filter) => {
        if (filter) {
          this.filterInPlace(filter);
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  protected onPageSizeChange($event: Event) : void {
    const pageSizeSelect = $event.target as HTMLSelectElement;
    this.paginator._changePageSize(Number.parseInt(pageSizeSelect.value));
  }
  
  protected onSortChanged(sort: Sort) : void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    } as TableDataModel.Sorting);
  }

  protected onRowClicked(student: Student): void {
    this._router.navigate(['data-lookup','student-view', student.id, StringUtilities.FormatStringForUrl(student.displayName ?? '')]);
  }

  private getContextColumns(pageScope: PageScope, selectedDistrictIds: string[] | undefined, selectedSchoolIds: string[] | undefined): string[] {
    switch(pageScope){
      case PageScope.State: {
        return ['school', 'district'];
      }
      case PageScope.District: {
        if ((selectedDistrictIds?.length ?? 0) > 1) return ['school', 'district'];
        return ['school'];
      }
      default: {
        if ((selectedSchoolIds?.length ?? 0) > 1) return ['school'];
        return [];
      }
    }
  }
}
