import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.model';

export const additionalInfoFields: AdditionalInfoModel.Category[] = [
  {
    name: 'Staff List',
    key: 'additionalInformation.staffList',
    expanded: true,
    valuePath: '$.additionalInformation.staffList',
    fields: [
      {
        name: 'Last Name',
        key: 'additionalInformation.staffList[0].nameLast',
        selected: false,
        exportColumn: {
          jsonPath: '$.NameLast'
        }
      },
      {
        name: 'First Name',
        key: 'additionalInformation.staffList[0].nameFirst',
        selected: false,
        exportColumn: {
          jsonPath: '$.NameFirst'
        }
      },
      {
        name: 'Middle Name',
        key: 'additionalInformation.staffList[0].nameMiddle',
        selected: false,
        exportColumn: {
          jsonPath: '$.NameMiddle'
        }
      },
      {
        name: 'Teacher License Number',
        key: 'additionalInformation.staffList[0].teacherLicenseNumber',
        selected: false,
        exportColumn: {
          jsonPath: '$.TeacherLicenseNumber'
        }
      },
      {
        name: 'Teacher of Record',
        key: 'additionalInformation.staffList[0].teacherOfRecord',
        selected: false,
        exportColumn: {
          jsonPath: '$.teacherOfRecord'
        }
      },
      {
        name: 'Staff Assignment Begin Date',
        key: 'additionalInformation.staffList[0].assignmentBeginDate',
        selected: false,
        exportColumn: {
          jsonPath: '$.assignmentBeginDate'
        }
      },
      {
        name: 'Staff Assignment End Date',
        key: 'additionalInformation.staffList[0].assignmentEndDate',
        selected: false,
        exportColumn: {
          jsonPath: '$.assignmentEndDate'
        }
      },
      {
        name: 'Staff Organization Email',
        key: 'additionalInformation.staffList[0].email',
        selected: false,
        exportColumn: {
          jsonPath: '$.email'
        }
      }
    ]
  },
  {
    name: 'Student List',
    key: 'additionalInformation.studentList',
    expanded: true,
    valuePath: '$.additionalInformation.studentList',
    fields: [
      {
        name: 'Last Name',
        key: 'additionalInformation.studentList[0].nameLast',
        selected: false,
        exportColumn: {
          jsonPath: '$.NameLast'
        }
      },
      {
        name: 'First Name',
        key: 'additionalInformation.studentList[0].nameFirst',
        selected: false,
        exportColumn: {
          jsonPath: '$.NameFirst'
        }
      },
      {
        name: 'Middle Name',
        key: 'additionalInformation.studentList[0].nameMiddle',
        selected: false,
        exportColumn: {
          jsonPath: '$.NameMiddle'
        }
      },
      {
        name: 'Student State Id',
        key: 'additionalInformation.studentList[0].ssid',
        selected: false,
        exportColumn: {
          jsonPath: '$.SSID'
        }
      },
      {
        name: 'Grade',
        key: 'additionalInformation.studentList[0].grade',
        selected: false,
        exportColumn: {
          jsonPath: '$.Grade'
        }
      },
      {
        name: 'Gender',
        key: 'additionalInformation.studentList[0].gender',
        selected: false,
        exportColumn: {
          jsonPath: '$.gender'
        }
      },
      {
        name: 'Type of Service',
        key: 'additionalInformation.studentList[0].serviceType',
        selected: false,
        exportColumn: {
          jsonPath: '$.serviceType'
        }
      },
      {
        name: 'Enrollment Begin Date',
        key: 'additionalInformation.studentList[0].enrollmentStartDate',
        selected: false,
        exportColumn: {
          jsonPath: '$.enrollmentStartDate'
        }
      },
      {
        name: 'Enrollment Code',
        key: 'additionalInformation.studentList[0].code',
        selected: false,
        exportColumn: {
          jsonPath: '$.code'
        }
      },
      {
        name: 'Withdrawal End Date',
        key: 'additionalInformation.studentList[0].withdrawalEndDate',
        selected: false,
        exportColumn: {
          jsonPath: '$.withdrawalEndDate'
        }
      },
      {
        name: 'Withdrawal Code',
        key: 'additionalInformation.studentList[0].withdrawalCode',
        selected: false,
        exportColumn: {
          jsonPath: '$.withdrawalCode'
        }
      }
    ]
  },
  {
    name: 'Class List',
    key: 'additionalInformation.classList',
    expanded: true,
    valuePath: '$.additionalInformation.classList',
    fields: [
      {
        name: 'Course Type',
        key: 'additionalInformation.classList[0].courseType',
        selected: false,
        exportColumn: {
          jsonPath: '$.courseType'
        }
      },
      {
        name: 'Course Code',
        key: 'additionalInformation.classList[0].courseCode',
        selected: false,
        exportColumn: {
          jsonPath: '$.courseCode'
        }
      },
      {
        name: 'Local Class Number',
        key: 'additionalInformation.classList[0].localClassNumber',
        selected: false,
        exportColumn: {
          jsonPath: '$.localClassNumber'
        }
      },
      {
        name: 'Section Identifier',
        key: 'additionalInformation.classList[0].sectionIdentifier',
        selected: false,
        exportColumn: {
          jsonPath: '$.sectionIdentifier'
        }
      },
      {
        name: 'Class Type',
        key: 'additionalInformation.classList[0].classType',
        selected: false,
        exportColumn: {
          jsonPath: '$.classType'
        }
      },
      {
        name: 'Class Begin Date',
        key: 'additionalInformation.classList[0].classStartDate',
        selected: false,
        exportColumn: {
          jsonPath: '$.classStartDate'
        }
      },
      {
        name: 'Class End Date',
        key: 'additionalInformation.classList[0].classEndDate',
        selected: false,
        exportColumn: {
          jsonPath: '$.classEndDate'
        }
      },
      {
        name: 'CTE Program Service',
        key: 'additionalInformation.classList[0].cteProgramService',
        selected: false,
        exportColumn: {
          jsonPath: '$.cteProgramService'
        }
      },
      {
        name: 'Classification of Course',
        key: 'additionalInformation.classList[0].courseClassification',
        selected: false,
        exportColumn: {
          jsonPath: '$.courseClassification'
        }
      },
      {
        name: 'Teaching Method',
        key: 'additionalInformation.classList[0].teachingMethod',
        selected: false,
        exportColumn: {
          jsonPath: '$.teachingMethod'
        }
      },
      {
        name: 'Test Window',
        key: 'additionalInformation.classList[0].testWindow',
        selected: false,
        exportColumn: {
          jsonPath: '$.testWindow'
        }
      }
    ]
  }
];